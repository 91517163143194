import { useLocation } from 'react-router-dom';
import { history } from '+utils';
import Secure from '+assets/img/secure.svg';
import Modal from './Modal';
import './SecureAccountModal.scss';

interface ISecureAccountModalProps {
  visible: boolean;
  close: () => void;
  closeAction?: () => void;
}

const SecureAccountModal = ({
  visible,
  close,
  closeAction
}: ISecureAccountModalProps) => {
  const { pathname } = useLocation();
  const modalContent = () => (
    <>
      <div className="secure-modal">
        <img className="banner-image" src={Secure} alt="" />
        <h5>Secure your account</h5>
        <p>Secure your account with ease! Enable 2FA on your account and add an extra layer of protection to your dashboard.</p>
      </div>

      <section className="dismiss-section px-4 pb-3">
        <div className="modal-footer">
          <button
            className="btn border-0 btn-full btn-primary"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              close();
              history.push('/dashboard/settings/security/twofactor');
            }}
          >
            Enable 2FA
          </button>
        </div>
      </section>
    </>
  );

  if (pathname.match('/dashboard/settings/security/twofactor')) {
    return null;
  }

  return (
    <section className="add-funds-modal__comp">
      {visible && (
        <Modal close={close} size="sm" content={modalContent()} showButtons={false} secondButtonAction={null} closeAction={closeAction} />
      )}
    </section>
  );
};


export default SecureAccountModal;
