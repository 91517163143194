const helmetApp = {
  title: 'bvnkng',
  description: 'bvnkng',
  head: {
    title: 'Welcome',
    titleTemplate: 'bvnkng: %s',
    meta: [
      {
        name: 'description',
        content: 'bvnkng allows you to send Money to Businesses Anywhere in Africa with Ease.'
      },
      {
        name: 'keywords',
        content:
          'Send money to africa, Send money to nigeria, Send money to ghana, Send money to kenya, Send money to south africa, Transfer money to africa, Transfer money to nigeria, Transfer money to ghana, Transfer money to kenya, Transfer money to south africa, Send money from US to Nigeria, Transfer money from US to Nigeria, Transfer money from UK to Nigeria, Send Money from UK to Nigeria, Send money to Ghana, Cheapest way to send or transfer money to Nigeria, Cheapest way to send or transfer money from US to Nigeria, Cheapest way to send or transfer money from UK to Nigeria, Cheapest way to send or transfer money from US to Africa, Fastest way to send or transfer to Ghana, Best way to send or transfer money to Nigeria, Ghana, Kenya, Receive money from Europe in Ghana, Best way to receive money from Abroad in Nigeria, ghana, Kenya'
      },
      {
        name: 'twitter:description',
        content:
          'Faster, Cheaper, Reliable & No Hidden Fees. bvnkng allows you to send Money to Your Friends and Family Anywhere in Africa with Ease.'
      },
      {
        charset: 'utf-8'
      },
      {
        property: 'og:site_name',
        content: 'bvnkng'
      },
      {
        property: 'og:image',
        content: 'https://bvnkng.com/assets/images/social-img.jpg'
      },
      {
        property: 'og:title',
        content: 'bvnkng'
      },
      {
        name: 'twitter:title',
        content: 'bvnkng'
      },
      {
        name: 'twitter:site',
        content: '@bvnkng'
      },
      {
        name: 'twitter:creator',
        content: '@bvnkng'
      },
      {
        name: 'twitter:url',
        content: 'https://twitter.com/bvnkng'
      },
      {
        name: 'twitter:image',
        content: 'https://bvnkng.com/assets/images/social-img.jpg'
      },
      {
        property: 'url',
        content: 'https://bvnkng.com'
      },
      {
        property: 'og:image',
        content: 'https://bvnkng.com'
      }
    ]
  }
};

export default helmetApp;
