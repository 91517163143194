import React, { useState } from 'react';
import useStore from '+store';

import { getIssuingPermissions } from '../utils';
import AccessRequestModal from './AccessRequestModal';
import Send from '+assets/img/dashboard/send.svg';
import CardRequestImage from '+assets/img/dashboard/vcp-access.svg';

import './index.scss';

const AccessRequestOnboarding: React.FC = () => {
  const { cardIssuanceCardAccess } = useStore.getState();
  const { canRequestCardAccess } = getIssuingPermissions();
  const { customer, reserved } = cardIssuanceCardAccess.access || {};
  const [showModal, setShowModal] = useState(false);

  const cta = (
    <div className="content-btn-wrapper">
      <button type="button" className="btn btn-primary" onClick={() => setShowModal(true)}>
        <img src={Send} alt="send icon" />
        Request Access
      </button>
    </div>
  );

  const message = <p style={{ width: 'unset' }}>Your request has been received. Our sales team will contact you.</p>;

  const content = [reserved?.status, customer?.status].includes('pending') ? message : cta;

  return (
    <div className="vcp_request_page">
      <div className="vcp_request_main">
        <img src={CardRequestImage} alt="virtual card icon" />
        <h1>Create, issue and manage USD virtual cards</h1>

        <p className="mb-5">
          Use bvnkng’s powerful issuing engine to seamlessly create, issue and manage USD virtual cards for you and your customers - for
          online purchases, subscriptions, or other digital transactions.
        </p>

        {canRequestCardAccess ? content : null}
      </div>

      {showModal ? <AccessRequestModal onClose={() => setShowModal(false)} /> : null}
    </div>
  );
};

export default AccessRequestOnboarding;
