/* eslint-disable react/forbid-prop-types */
import React from 'react';
import './index.scss';

interface ISecondaryDetailsProps {
  title: string;
  data?: any[]; 
}

const SecondaryDetails = ({
  title,
  data = [],
}: ISecondaryDetailsProps) => {
  return (
    <>
      <p className="sec-details-title">{title}</p>
      <section className="secondary-details__comp">
        {data.map(group => (
          <React.Fragment key={Math.floor(1000 + Math.random() * 9000)}>
            <ul className={` ${data.length === 1 ? 'no-border' : ''}`}>
              {Object.entries(group).map(([key, val]) => (
                <li className="sec-details-list" key={Math.floor(1000 + Math.random() * 9000)}>
                  <span className="sec-details-key">{key}</span>{' '}
                  <span className="sec-details-val" style={val === 'Not Available' ? { fontStyle: 'italic', color: '#DDE2EC' } : {}}>
                    {val}
                  </span>
                </li>
              ))}
            </ul>
          </React.Fragment>
        ))}
      </section>
    </>
  );
};


export default SecondaryDetails;
