import React from 'react';
import './RadioButton.scss';

export interface RadioButtonProps {
  checked: boolean;
  onChange: () => void;
  label: string;
  disabled?: boolean;
}

const RadioButton: React.FC<RadioButtonProps> = ({ checked, onChange, label, disabled = false }) => {
  return (
    <label className="radio-container" onClick={!disabled ? onChange : undefined}>
      <input checked={checked} type="radio" onChange={onChange} disabled={disabled} data-testid="refund-type" />
      {label}
    </label>
  );
};

export default RadioButton;
