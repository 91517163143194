import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMutation, useQueryClient } from 'react-query';
import { Link, withRouter } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';

import { breadCrumbEvents } from '+/utils/bugsnag-events';
import useMerchantAuth from '+hooks/useMerchantAuth';
import APIRequest from '+services/api-services';
import Feedback from '+shared/Feedback';
import useStore from '+store';
import { EmailValidation, history, logBreadCrumb, logError, Logger } from '+utils';

import ErrorSvg from '+assets/img/auth/error.svg';

import './index.scss';

const api = new APIRequest();

const SignInForm = ({ initIfTwoFactorRequired }) => {
  const recaptchaRef = useRef({});
  const formRef = useRef({});
  const queryClient = useQueryClient();
  const savedLocation = queryClient.getQueryData('SAVED_LOCATION');
  const savedRole = queryClient.getQueryData('SAVED_ROLE');
  const [state, setState] = useState({
    isLoading: false,
    feedback: {
      message: '',
      visible: false,
      type: 'danger'
    }
  });
  const [isLoginAttempt, setIsLoginAttempt] = useState(false);
  const [attemptNumber, setAttemptNumber] = useState(0);
  const { bootstrap } = useMerchantAuth();

  const handleSubmit = ({ email, password }) => {
    // logBreadCrumb({ event: breadCrumbEvents.signin.loginButtonClicked, data: { email } });

    if (recaptchaRef.current.execute) {
      setState({ ...state, isLoading: true });
      formRef.current = { email, password };
      recaptchaRef.current.execute();
      loginAct();
    } else {
      setState({
        ...state,
        feedback: {
          message: 'Please wait while recatpcha loads and try again',
          visible: true,
          type: 'warning'
        }
      });
      formRef.current = { email, password };
    }
  };

  const SignInMutation = useMutation(data => api.signIn(data), {
    retry: false,
    onSuccess: async res => {
      const { authDetails } = useStore.getState();

      if (res === 'Unverified') {
        return setState({
          ...state,
          isLoading: false,
          feedback: { ...state.feedback, message: 'A verification email has been sent to your email address', visible: true }
        });
      }
      if (authDetails?.account?.merchants[0]?.meta_data?.show_kyc_page_after_login) {
        await bootstrap();
        return history.push('/auth/kyc');
      }
      if (res === null) {
        await bootstrap();
        queryClient.setQueryData('SAVED_LOCATION', '');
        return history.push(savedLocation ?? '/dashboard/home', savedRole ?? '');
      }
      return initIfTwoFactorRequired(res, formRef?.current?.email);
    },
    onError: e => {
      const error = e.response?.data;
      logError(error?.message);
      const errorMessage = () => {
        if (e?.response?.status?.toString().charAt(0) === '4' && error?.message) {
          if (error?.data?.attempts_left) {
            setAttemptNumber(error.data.attempts_left);
            setIsLoginAttempt(true);
            return error.message;
          }
          if (error?.message.includes('Maximum login attempts')) {
            setAttemptNumber(0);
            setIsLoginAttempt(false);
          }
          if (error?.data?.account_status === 'locked') {
            return 'locked';
          }

          if (error?.data?.account_status) {
            return error.message;
          }
          if (error?.message === 'We just sent you an email') {
            return 'We just sent you an email';
          }
          if (error?.message.includes('Access revoked, please contact support')) {
            return 'Account suspended, please contact support@bvnkng.com for more info';
          }
          if (error?.message.includes('Your account has been locked due to too many login attempts')) {
            return 'Your account has been locked due to too many login attempts, please contact support@bvnkng.com for more info';
          }
          if (error?.message.includes('Account suspended, please contact support')) {
            return 'Account suspended, please contact support@bvnkng.com for more info';
          }
          if (error?.message.includes('Account deactivated, please contact support')) {
            return 'Account deactivated, please contact support@bvnkng.com for more info';
          }
          if (error?.message.includes('Your country is not supported, please contact support')) {
            return 'We currently do not support businesses from your country, please contact support@bvnkng.com for more info.';
          }
        }

        return 'There seems to be an issue signing you in. Please contact support@bvnkng.com for more info.';
      };
      setState({
        ...state,
        isLoading: false,
        feedback: { ...state.feedback, message: `${errorMessage()}`, visible: true }
      });
    }
  });

  const loginAct = async () => {
    setState({ ...state, feedback: { ...state.feedback, visible: false, message: '' }, isLoading: true });
    SignInMutation.mutate(formRef.current);
  };

  const showFeedback = () => {
    const { feedback } = state;
    return feedback.visible ? (
      <Feedback type={feedback.type} message={feedback.message === 'locked' ? <LockedMessage /> : feedback.message} />
    ) : (
      ''
    );
  };

  const configErrorMessage = (errors, values) => {
    let msg = null;

    if (values.email && !values.password) {
      if (errors.email) msg = errors.email;
    }

    if (values.password && !values.email) {
      if (errors.password) msg = errors.password;
    }

    if (values.email && values.password) {
      if (errors.email && !errors.password) msg = errors.email;
      if (!errors.email && errors.password) msg = errors.password;
      if (errors.email && errors.password) msg = `${errors.email} and ${errors.password}`;
    }

    return msg === null ? null : <Feedback type="danger" message={msg} />;
  };

  const recaptchaError = errorType => {
    recaptchaRef.current.reset();
    setState({
      ...state,
      isLoading: false,
      feedback: {
        message: `Recaptcha verification ${errorType}. Please refresh this page and retry.`,
        visible: true,
        type: 'warning'
      }
    });
  };

  return (
    <>
      {showFeedback()}
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={handleSubmit}
        validate={values => {
          const errors = {};
          if (!values.password) errors.password = 'Your password is required';
          return errors;
        }}
      >
        {({ errors, values, isValid, dirty }) => {
          return (
            <Form autoComplete="off" className="login__form">
              {configErrorMessage(errors, values, isValid)}
              <Field name="email" validate={EmailValidation}>
                {({ field }) => (
                  <div className="input__wrap">
                    <label htmlFor={field.name} className="screen-reader-only">
                      Email Address
                    </label>
                    <input autoComplete="off" {...field} placeholder="Email" type="text" id="email" required />
                  </div>
                )}
              </Field>
              <Field name="password">
                {({ field }) => (
                  <div className="input__wrap" style={{ position: 'relative' }}>
                    <label htmlFor={field.name} className="screen-reader-only">
                      Password
                    </label>
                    <input
                      autoComplete="off"
                      {...field}
                      placeholder="Password"
                      type="password"
                      maxLength="50"
                      id="password"
                      data-testid="password-input"
                      required
                    />
                  </div>
                )}
              </Field>
              <div
                className="forgot-text"
                aria-hidden="true"
                onClick={() => {
                  logBreadCrumb({ event: breadCrumbEvents.signin.resetButtonClicked });
                  history.push('/auth/forgot-password');
                }}
              >
                Forgot Password?
              </div>

              {isLoginAttempt && (
                <div className="login-attempts">
                  <img src={ErrorSvg} alt="Error Icon" />
                  <p>
                    You have <span>{attemptNumber} login attempts</span> left, after which your account would be
                    <br />
                    locked for security reasons.
                  </p>
                </div>
              )}

              <div className="btn-wrapper mt-5">
                <button className="btn-kpy --full-blue" type="submit" disabled={!(isValid && dirty) || state.isLoading}>
                  {state.isLoading && (
                    <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
                  )}
                  Log in
                </button>

                <ReCAPTCHA
                  ref={recaptchaRef as React.MutableRefObject<ReCAPTCHA>}
                  size="invisible"
                  sitekey={process.env.REACT_APP_POSEIDON_BOLT || ''}
                  onChange={loginAct}
                  onExpired={() => recaptchaError('expired')}
                  onError={() => recaptchaError('error')}
                />
                <div className="grey-text mt-3">
                  If you don’t have an account{' '}
                  <Link className="btn-kpy btn--link" to="/auth/signup">
                    <span className="link-text">Sign Up</span>
                  </Link>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default withRouter(SignInForm);

const LockedMessage = () => {
  return (
    <p className="locked-message">
      Your account has been locked.{' '}
      <Link className="bot_launcher_button" to={`${process.env.REACT_APP_SITE_HOSTNAME}/auth/login/locked-account`}>
        Learn how to unlock your account here
      </Link>
    </p>
  );
};
