import React from 'react';
import { Link } from 'react-router-dom';

import useStore from '+store';

import ApiKeySvg from '+assets/img/dashboard/api-doc.svg';
import BookSvg from '+assets/img/dashboard/book.svg';
import PaymentLinkSvg from '+assets/img/dashboard/payment-link.svg';

const Greetings = () => {
  const { profile } = useStore();

  const getTime = () => {
    const hour = new Date().getHours();
    if (hour < 12)
      return [
        <span role="img" aria-label="star-emoji">
          🌟
        </span>,
        'Morning'
      ];
    if (hour > 11 && hour < 17)
      return [
        <span role="img" aria-label="sun-emoji">
          🌟
        </span>,
        'Afternoon'
      ];
    return [
      <span role="img" aria-label="moon-emoji">
        🌟
      </span>,
      'Evening'
    ];
  };

  return (
    <div className="row">
      <div className="col-10 col-lg-4">
        <div className="onboarding-done">
          <h4 className="form-header">
            {`${getTime()[1]}, ${profile?.firstName || ''}!`}
            {getTime()[0]}
          </h4>
          <div className="form-desc">Welcome to your dashboard. See a quick summary of your transactions below.</div>
        </div>
      </div>
      {/* <div className="col-sm-8 recommendation-container">
        <div className="row recommendation">
          <div className="col-sm-4 divider">
            <img src={PaymentLinkSvg} alt="payment-link" />

            <p className="recommendation-subtitle">
              <span>Share a payment link</span> to <br />
              start accepting payments.
            </p>

            <Link to="payment-links">
              Start <i className="os-icon os-icon-chevron-right" style={{ marginLeft: '10px' }} />
            </Link>
          </div>
          <div className="col-sm-4 divider">
            <img src={ApiKeySvg} alt="api-key" />
            <p className="recommendation-subtitle">
              <span>Get API keys</span> to integrate
              <br />
              Checkouts with your apps.
            </p>

            <Link to="settings/api-integrations">
              Start <i className="os-icon os-icon-chevron-right" style={{ marginLeft: '10px' }} />
            </Link>
          </div>
          <div className="col-sm-4 divider">
            <img src={BookSvg} alt="book" />
            <p className="recommendation-subtitle">
              <span>See documentation</span> to <br />
              explore more products
            </p>

            <a href="https://developers.bvnkngpay.com/" target="_blank" rel="noreferrer">
              Start <i className="os-icon os-icon-chevron-right" style={{ marginLeft: '10px' }} />
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Greetings;
