import { useQuery } from 'react-query';

import APIRequest from '+services/api-services';
import useStore from '+store';
import { logError } from '+utils';

import useFeedbackHandler from './feedbackHandler';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const useCardAccessRequest = () => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();

  useQuery(['VIRTUAL_CARD_ACCESS_STATUS'], () => api.fetchAccessRequestStatus(), {
    onSuccess: data => useStore.setState({ cardIssuanceCardAccess: { ...data } }),
    onError: e => {
      logError(e);
      const message = e.response?.data?.message || 'There has been an error fetching your virtual card access request status';
      feedbackInit({
        message,
        type: 'danger',
        componentLevel: true
      });
      setTimeout(() => closeFeedback(), 5000);
    },
    enabled: false
  });
};

export default useCardAccessRequest;
