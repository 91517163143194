import { Redirect, Route, Switch } from 'react-router-dom';

import AccessDenial from '+containers/Dashboard/AccessDenial';
import TransactionDetails from '+containers/Dashboard/TransactionDetails';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '+containers/Shared/Tabs';
import { useSearchQuery } from '+hooks';
import { checkProductAccess } from '+utils';
import useStore from '+store';

import CurrencyPicker from '../Shared/CurrencyPicker';
import Balance from './Balance';
import CardTransactions from './CardTransactions';
import AccessRequestOnboarding from './components/AccessRequestOnboarding';
import CardTransactionDetails from './components/CardTransactionDetails';
import ChargebackTransactionDetails from './components/ChargebackTransactionDetails';
import CustomerCards from './CustomerCards';
import Disputes from './Disputes';
import IssuedCardDetails from './IssuedCardDetails';
import ReservedCards from './ReservedCards';
import { getIssuingPermissions } from './utils';

import './index.scss';

const tabsList = [
  {
    label: 'Balance',
    key: 'balance',
    element: <Balance />
  },
  {
    label: 'Customer Cards',
    key: 'customer_cards',
    element: <CustomerCards />
  },
  {
    label: 'Reserved Cards',
    key: 'reserved_cards',
    element: <ReservedCards />
  },
  {
    label: 'Card Transactions',
    key: 'card_transactions',
    element: <CardTransactions />
  },
  {
    label: 'Disputes',
    key: 'disputes',
    element: <Disputes />
  }
];

const IssuingIndex = () => {
  const { setQuery, value: searchQueryValue } = useSearchQuery();
  const tab = searchQueryValue?.tab ?? tabsList[0].key;
  const currency = searchQueryValue?.currency ?? 'USD';

  return (
    <div className="issuing-module__comp">
      <Tabs defaultValue={tab} onChange={tabValue => setQuery({ tab: tabValue }, true)}>
        <div>
          <div className="w-100 d-flex flex-wrap justify-content-between align-items-center" style={{ gap: '1em' }}>
            <TabList>
              {tabsList.map(({ label, key }) => (
                <Tab value={key} key={key}>
                  {label}
                </Tab>
              ))}
            </TabList>

            <CurrencyPicker
              className="mb-2"
              aria-label="Currency Picker"
              options={['USD']}
              onChange={value => setQuery({ currency: value })}
              activeCurrency={currency}
            />
          </div>
          <div className="divide-y" />
        </div>

        <TabPanels>
          {tabsList.map(({ element, key }) => (
            <TabPanel value={key} key={key}>
              {element}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

const IssuingModule = () => {
  const defaultMerchant = useStore(state => state.defaultMerchant);
  const { canViewCardList, canViewTrxnDetails } = getIssuingPermissions();
  const accessGranted = checkProductAccess(defaultMerchant?.productAccess?.card_issuance);

  return (
    <div>
      <Switch>
        <Route exact path="/dashboard/issuing">
          {accessGranted ? <IssuingIndex /> : <AccessRequestOnboarding />}
        </Route>

        {accessGranted ? (
          <>
            <Route exact path="/dashboard/issuing/customer_cards/:ref">
              {canViewCardList ? <IssuedCardDetails /> : <AccessDenial />}
            </Route>

            <Route exact path="/dashboard/issuing/reserved_cards/:ref">
              {canViewCardList ? <IssuedCardDetails /> : <AccessDenial />}
            </Route>

            <Route path="/dashboard/issuing/card-transactions/:id">
              {canViewTrxnDetails ? <CardTransactionDetails /> : <AccessDenial />}
            </Route>

            <Route path="/dashboard/issuing/issued-card-chargebacks/:id">
              {canViewTrxnDetails ? <ChargebackTransactionDetails /> : <AccessDenial />}
            </Route>

            <Route path="/dashboard/issuing/funding-deposit/:id">
              <TransactionDetails />
            </Route>
          </>
        ) : (
          <Route path="/dashboard/issuing/">
            <Redirect to="/dashboard/issuing" />
          </Route>
        )}
      </Switch>
    </div>
  );
};

export default IssuingModule;
