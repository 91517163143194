import { useRef, useState } from 'react';

import { IAccordionItem } from '+types';

import roleDescriptions from './rolesDescription';

import upArrow from '+assets/img/dashboard/accordion-close.svg';
import downArrow from '+assets/img/dashboard/accordion-open.svg';

import './index.scss';

const AccordionItem = ({ description, active, onToggle }: IAccordionItem) => {
  const { role, info, permissions } = description;

  const contentEl = useRef<HTMLDivElement | null>(null);

  return (
    <div role="presentation" className={`accordion__item ${active ? 'active' : ''}`} onClick={onToggle}>
      <div className="accordion__heading">
        <div className="accordion__header">{role}</div>
        <p className="accordion__text">{info}</p>
        <div className="accordion__control">{active ? <img src={upArrow} alt="close" /> : <img src={downArrow} alt="open" />} </div>
      </div>
      <div
        ref={contentEl}
        className="accordion__answer-wrapper"
        style={active ? { height: contentEl.current?.scrollHeight } : { height: '0px' }}
      >
        <div className="accordion__answer">
          {permissions.map(({ page, action }) => {
            return (
              <div className="accordion__more" key={page}>
                <span>{page}</span>
                <span>{action}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const DetailsAccordion = () => {
  const [clicked, setClicked] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    if (clicked === index) {
      return setClicked(null);
    }
    return setClicked(index);
  };

  return (
    <div className="accordion">
      {roleDescriptions.map((description, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <AccordionItem onToggle={() => handleToggle(index)} active={clicked === index} key={index} description={description} />
      ))}
    </div>
  );
};

export default DetailsAccordion;
