import React, { useState } from 'react';
import { useMutation } from 'react-query';

import InitiateTwoFaModal from '+containers/Dashboard/Shared/InitiateTwoFAModal';
import useFeedbackHandler from '+hooks/feedbackHandler';
import use2FAHistory from '+hooks/use2FAHistory';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { history, logError } from '+utils';

import ExistingTwoFactorComponent from './components/ExistingTwoFactorAuth';
import NewTwoFactorComponent from './components/NewTwoFactorAuth';

import './index.scss';

const authBaseURL = process.env.REACT_APP_AUTH_API_BASE || 'http://localhost:3000';
const api = new APIRequest(process.env.REACT_APP_MERCHANT_MIDDLEWARE_API_BASE);
const authAPIRequest = new APIRequest(authBaseURL);

function TwoFactorAuthComponent() {
  const [twoFaModal, setTwoFaModal] = useState(null);
  const [identifier, setIdentifier] = useState(null);
  const [twoFASetup, setTwoFASetup] = useState(null);
  const isTwoFAEnabled = useStore(state => (state as { is2FactorEnabled: boolean }).is2FactorEnabled);
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [canCopyRecoveryCode, setCanCopyRecoveryCode] = useState(false);

  const get2FAHistory = use2FAHistory();

  const initiateTwoFaRegistration = useMutation(data => api.initiateTwoFaRegistration(data), {
    onSuccess: data => {
      setIdentifier(data?.data);
    },
    onError: error => {
      logError(error.response?.data);
      feedbackInit({
        message: error.response?.data?.message || 'We are sorry, There was an issue inititating 2FA.',
        type: 'danger'
      });
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
  });

  const onInitiate = async () => {
    const requestData = {
      action: 'initiate_two_fa'
    };
    await initiateTwoFaRegistration.mutateAsync(requestData);
    setTwoFaModal(true);
  };

  const initiate2fa = useMutation(data => authAPIRequest.getinitTwoFASetup(data), {
    onSuccess: data => {
      setTwoFASetup(data);
    },
    onError: error => {
      logError(error.response?.data);
      const message = error.response?.data?.message || 'We are sorry, There was a problem while authenticating';
      feedbackInit({
        message,
        type: 'danger',
        componentLevel: true
      });
      setTimeout(() => {
        closeFeedback();
      });
    }
  });

  return (
    <>
      <button type="button" className="btn btn-link goback-btn p-0" onClick={() => history.goBack()}>
        <i className="os-icon os-icon-arrow-left7" />
        <span>Back to Security Settings</span>
      </button>
      <div className="row mt-4">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <div className="element-box p-0">
              <div>
                {isTwoFAEnabled && !canCopyRecoveryCode ? (
                  <ExistingTwoFactorComponent data={get2FAHistory?.data} />
                ) : (
                  <NewTwoFactorComponent data={twoFASetup} onInitiate={onInitiate} setCanCopyRecoveryCode={setCanCopyRecoveryCode} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <InitiateTwoFaModal
        visible={twoFaModal}
        close={() => setTwoFaModal(false)}
        resend={onInitiate}
        identifier={identifier}
        initiate2fa={initiate2fa}
      />
    </>
  );
}

export default TwoFactorAuthComponent;
