import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    fontSize: '16px',
    fontFamily: 'Averta PE'
  },
  bvnkngLogo: {
    width: '80px',
    marginBottom: 10
  },
  body: {
    marginTop: 30,
    padding: 10,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  narration: {
    fontWeight: 500,
    fontSize: '18px',
    color: '#3E4B5B'
  },
  summaryInfo: {
    color: '#94A7B7',
    fontSize: '14px',
    margin: '10px'
  },
  rowItem: {
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: '16px'
  },
  rowLabel: {
    color: '#3E4B5B'
  }
});

export default function RecoveryCodeComponent({ data }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.body}>
          <Text style={styles.narration}>Two-Factor Authentication Recovery Codes</Text>
          <Text style={styles.summaryInfo}>
            If you lose access to your authentication device, you can use one of these recovery codes to login to your account. Each code
            may be used only once. Make a copy of these codes, and store it somewhere safe.
          </Text>

          {data?.map(item => (
            <View style={styles.rowItem} key={item}>
              <Text style={styles.rowLabel}>{item}</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
}
