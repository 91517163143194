import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { history } from '+utils';
import Slider from './Slider';
import Shield from '+assets/img/shield-brown.svg';
import CancelIcon from '+assets/img/dashboard/cancel-kyc.svg';
import LinkIcon from '+assets/img/dashboard/kyc-link-icon.svg';
import './Banner.scss';

const TwoFactorBanner = ({ twoFaHistory }) => {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const status = queryClient.getQueryData('MERCHANT_KYC');
  const complianceStatus = status?.compliance?.status;
  const [bannerOpen, setBannerOpen] = useState(twoFaHistory);

  useEffect(() => {
    setBannerOpen(twoFaHistory);
  }, [twoFaHistory]);

  const handleClose = () => {
    setBannerOpen(true);
  };

  if (pathname.match('/dashboard/settings')) {
    return null;
  }

  return ['ready', 'kyc', 'feedback', 'unverified'].includes(complianceStatus)
    ? null
    : !bannerOpen && (
        <div className="banner">
          <img className="banner-sparkle" src={Shield} alt="" />
          <div className="slider-wrapper">
            <Slider>
              <p>Your account may be at risk. Set up 2FA now for extra security!</p>{' '}
              <p className="styled-text" onClick={() => history.push('/dashboard/settings/security/twofactor')} aria-hidden="true">
                <span>Enable 2FA</span>
                <img className="link-icon" src={LinkIcon} alt="proceed" />
              </p>
            </Slider>
          </div>

          <button type="button" className="banner-btn" onClick={() => handleClose()}>
            <p>Dismiss</p>
            <img className="banner-btn-icon" src={CancelIcon} alt="cancel" />
          </button>
        </div>
      );
};

export default TwoFactorBanner;
