/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { usePermissions } from '+hooks';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { TeamMemberType, UserRoleType } from '+types';
import { capitalizeRemovedash, getDate, getTime, logError } from '+utils';

import Table from '../../Shared/Table';
import ToolTip from '../../Shared/Tooltip';
import DetailsModal from './components/DetailsModal';
import NavModal from './components/NavModal';
import TeamModals from './components/TeamModals';

import './index.scss';

const api = new APIRequest();

type StateType = {
  modalType: string | null;
};

const Team = () => {
  const inviteMember = usePermissions('team');

  const { feedbackInit } = useFeedbackHandler();

  const { profile } = useStore();

  const [state, setState] = useState<StateType>({
    modalType: null
  });
  const [detailsModal, setDetailsModal] = useState(false);
  const [teamMembers, setTeamMembers] = useState<Array<TeamMemberType>>([]);
  const [teamMember, setTeamMember] = useState<TeamMemberType | {}>({});
  const [userRole, setUserRole] = useState<UserRoleType | null>(null);
  const [userRoles, setUserRoles] = useState<UserRoleType[]>([]);
  const [activeTab, setActiveTab] = useState('Team Members');

  const presentUser = teamMembers?.find(member => profile?.email === member?.email);

  const { isFetching: loadingTeamMembers } = useQuery(['TEAM_MEMBERS'], () => api.fetchTeamMembers(), {
    keepPreviousData: true,
    onSuccess: data => {
      const owners = data?.data?.filter((member: TeamMemberType) => member?.userRole?.name === 'Owner');
      const others = data?.data?.filter((member: TeamMemberType) => member?.userRole?.name !== 'Owner');
      setTeamMembers([...owners, ...others]);
    },
    onError: (e: AxiosError<{ message: string }>) => {
      const error = e.response?.data;
      logError(error?.message);
      feedbackInit({
        message: error?.message ?? 'There has been an error getting team member(s) details',
        type: 'danger'
      });
    }
  });

  const { isFetching: loadingPermissions, data: permissions } = useQuery(['PERMISSIONS'], () => api.fetchPermissions(), {
    keepPreviousData: true,

    onError: (e: AxiosError<{ message: string }>) => {
      const error = e.response?.data;
      logError(error?.message);
      feedbackInit({
        message: error?.message ?? 'There has been an error getting permissions',
        type: 'danger'
      });
    }
  });

  const { isFetching: loadingRoles, data } = useQuery(['TEAMS_AND_ROLES'], () => api.fetchRoles(1, 1000), {
    keepPreviousData: true,
    onSuccess: data => setUserRoles(data?.data?.data?.data),
    onError: (e: AxiosError<{ message: string }>) => {
      const error = e.response?.data;
      logError(error?.message);
      feedbackInit({
        message: error?.message ?? 'There has been an error getting teams and roles details',
        type: 'danger'
      });
    }
  });

  const isAdminOrOwner = ['Administrator', 'Owner'].includes(presentUser?.userRole?.name ?? '');

  const canUpdateTeam = (row: TeamMemberType) => {
    if (!isAdminOrOwner || row.email === profile?.email || (row.userRole.name === 'Owner' && presentUser?.userRole?.name !== 'Owner'))
      return false;
    return true;
  };

  const canUpdateRole = (row: UserRoleType) => {
    if (!isAdminOrOwner || row.category === 'system') return false;
    return true;
  };

  function getStatus(status: string) {
    if (['invite_accepted', 'active'].includes(status)) {
      return 'Active';
    }
    if (!['invite_accepted', 'active'].includes(status)) {
      return capitalizeRemovedash(status);
    }
    return 'N/A';
  }

  const teamTable = () => {
    return teamMembers?.map(each => {
      return (
        <div key={`team_${each.email}`} className="div-table --teams-table --row">
          <div>
            <span className="body-row-header">Team Member:</span>
            <ToolTip
              type="team-tooltip-content"
              classname="w-100"
              message={
                <span className="" style={{ textTransform: 'capitalize' }}>
                  {each?.user?.first_name && each?.user?.last_name ? `${each?.user?.first_name} ${each?.user?.last_name}` : '--'}
                </span>
              }
              wrapperComponent="span"
            >
              <span className="d-flex">
                <span className="team-table-column team-table-member" style={{ textTransform: 'capitalize' }}>
                  {each?.user?.first_name && each?.user?.last_name ? `${each?.user?.first_name} ${each?.user?.last_name}` : '--'}
                </span>
                {profile?.email === each?.email && <span className="pills">You</span>}
              </span>
            </ToolTip>
          </div>
          <div>
            <span className="body-row-header">Email:</span>

            <ToolTip
              type="team-tooltip-content"
              classname="team-tooltip-w w-100"
              message={<p>{each?.email || 'N/A'}</p>}
              wrapperComponent="span"
            >
              <span className="team-table-column" style={{ color: 'rgba(90, 99, 126, 0.49)' }}>
                {each?.email || 'N/A'}
              </span>
            </ToolTip>
          </div>
          <div>
            <span className="body-row-header">Role:</span>
            <span style={{ textTransform: 'capitalize' }}>{each?.userRole?.name || 'N/A'}</span>
          </div>
          <div>
            <span className="body-row-header">Status:</span>
            {(each?.status === 'active' || each?.status === 'invite_accepted') && <span className="status-pill smaller green" />}
            <span
              className="status-text"
              style={{ fontStyle: each?.status !== 'active' && each?.status !== 'invite_accepted' ? 'italic' : '' }}
            >
              {getStatus(each?.status)}
            </span>
          </div>
          <div>
            <span className="body-row-header">2FA Enabled:</span>
            <span className="status-text">{each?.user?.two_factor_enabled ? 'Yes' : 'No'}</span>
          </div>
          <div>
            <span className="body-row-header">Last Login:</span>
            <span>{each?.user?.last_login ? getDate(each?.user.last_login) : '--'}</span>
            <span className="annotation" style={{ marginLeft: '5px' }}>
              {each?.user?.last_login ? getTime(each?.user?.last_login) : ''}
            </span>
          </div>
          <div className="ellipsis">
            {canUpdateTeam(each) && (
              <NavModal each={each} setState={setState} setItem={setTeamMember} activeTab={activeTab} presentUser={presentUser} />
            )}
          </div>
        </div>
      );
    });
  };

  const rolesTable = () => {
    return userRoles?.map(each => {
      return (
        <div key={`team_${each.id}`} className="div-table --roles-table --row">
          <div>
            <span className="body-row-header">Role:</span>
            <span style={{ textTransform: 'capitalize' }}>{each?.name}</span>
            {each?.category === 'system' && (
              <span className="italic" style={{ color: 'rgba(90, 99, 126, 0.49)', fontStyle: 'italic' }}>
                {' '}
                (Default)
              </span>
            )}
          </div>
          <div>
            <span className="body-row-header">Member Count:</span>
            <span>{each?.usersCount} Member(s) </span>{' '}
            <span style={{ color: 'rgba(90, 99, 126, 0.49)' }}>({each?.invitesCount}) Invited </span>
          </div>
          <div>
            <span className="body-row-header">Creation Date:</span>
            <span>{each?.createdAt ? getDate(each?.createdAt) : '--'}</span>
            <span className="annotation" style={{ marginLeft: '5px' }}>
              {each?.createdAt ? getTime(each?.createdAt) : ''}
            </span>
          </div>
          <div>
            <span className="body-row-header">Last Edited:</span>
            <span>{each?.updatedAt ? getDate(each?.updatedAt) : '--'}</span>
            <span className="annotation" style={{ marginLeft: '5px' }}>
              {each?.updatedAt ? getTime(each?.updatedAt) : ''}
            </span>
          </div>
          <div className="ellipsis">
            {canUpdateRole(each) && (
              <NavModal
                each={each}
                setState={setState}
                setItem={setUserRole}
                activeTab={activeTab}
                hideRemove={each?.usersCount > 0 || each?.invitesCount > 0}
              />
            )}
          </div>
        </div>
      );
    });
  };

  const getTableProps = () => {
    switch (activeTab) {
      case 'Team Members':
        return {
          tableClassName: '--teams-table',
          headings: [
            {
              value: 'Team Member'
            },
            {
              value: 'Email'
            },
            {
              value: 'Role'
            },
            {
              value: 'Status'
            },
            {
              value: '2FA Enabled'
            },
            {
              value: 'Last Login'
            },
            {
              value: ''
            }
          ],
          loading: loadingTeamMembers,
          EmptyStateMessage: 'No Team members available.',
          children: teamTable()
        };
      case 'Roles':
        return {
          tableClassName: '--roles-table',
          headings: [
            {
              value: 'Role'
            },
            {
              value: 'Member Count'
            },
            {
              value: 'Creation Date'
            },
            {
              value: 'Last Edited'
            },
            {
              value: ''
            }
          ],
          loading: loadingRoles,
          EmptyStateMessage: 'No Roles available.',
          children: rolesTable()
        };

      default:
        break;
    }
  };

  return (
    <section className="">
      <div className="element-box teams">
        {activeTab === 'Team Members' && <h4 className="form-header">Team</h4>}
        {activeTab === 'Roles' && <h4 className="form-header">Manage your team</h4>}
        <div className="form-desc mb-0">
          Here’s your team. Account owners and admins can manage your team, assign roles and invite new team members with email.{' '}
          <button type="button" className="teams__details" onClick={() => setDetailsModal(true)}>
            Learn more about different roles.
          </button>
        </div>
        <br />

        <div className="tab-section">
          <div className="os-tabs-w mb-3 ">
            <div className="os-tabs-controls os-tabs-complex">
              <ul className="nav nav-tabs team-roles-tabs">
                {['Team Members', 'Roles'].map(tab => (
                  <li className="nav-item" key={tab}>
                    <button
                      type="button"
                      aria-expanded="false"
                      className={`${tab === activeTab ? 'nav-link active' : 'nav-link'} pt-0`}
                      data-toggle="tab"
                      onClick={() => setActiveTab(tab)}
                    >
                      <span className="tab-label ">{tab}</span>{' '}
                      {teamMembers?.length > 0 && tab === 'Team Members' && (
                        <span className="team-member-length">{teamMembers.length}</span>
                      )}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="teams__cta">
          <span></span>
          {inviteMember === 'manage' && activeTab === 'Team Members' && (
            <button type="button" className="btn btn-primary" onClick={() => setState({ modalType: 'invite-team-member' })}>
              + Invite member
            </button>
          )}
          {activeTab === 'Roles' && isAdminOrOwner && (
            <button type="button" className="btn btn-primary" onClick={() => setState({ modalType: 'add-role-permission' })}>
              + Add New Role
            </button>
          )}
        </div>
        <div className="team-members">
          <div className="team-member-heading" />
          <Table background="#f5f6f6" {...getTableProps()} />
        </div>
      </div>

      {state.modalType && (
        <TeamModals
          close={() => setState({ ...state, modalType: null })}
          type={state?.modalType}
          roles={userRoles}
          role={userRole}
          permissions={permissions?.data?.data ?? {}}
          memberDetails={teamMember}
          updateType={(type: string) => {
            setState({ ...state, modalType: type });
          }}
          presentUser={presentUser}
        />
      )}

      <DetailsModal showModal={detailsModal} close={() => setDetailsModal(false)} />
    </section>
  );
};

export default Team;
