/* eslint-disable no-nested-ternary */
import { useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';

import { breadCrumbEvents } from '+/utils/bugsnag-events';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { capitalize, history, logBreadCrumb, switchStatus } from '+utils';

import Copyable from '../Shared/Copyable';
import SearchComponent from '../Shared/Search';
import Table from '../Shared/Table';
import VirtualAccountDetails from './VirtualAccountDetails';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const VirtualAccounts = () => {
  const { feedbackInit } = useFeedbackHandler();
  const [state, setState] = useState({
    search: '',
    paginationPage: 1,
    limit: 10,
    tabs: { All: null, Active: 'active', Inactive: 'inactive', Expired: 'expired' },
    activeTab: 'All',
    activeCurrency: 'NGN',
    data: []
  });

  const MERCHANT_ENV = useStore(store => store.merchantEnv);
  const availableCurrencies = useStore(state => state.availableCurrencies);

  const { data, isFetching, refetch } = useQuery(
    ['VIRTUAL_ACCOUNTS', state.paginationPage, state.limit, state.search, MERCHANT_ENV],
    () => api.getVirtualBankAccounts(state.paginationPage, state.limit, state.search),
    {
      keepPreviousData: true,
      retry: false,
      onError: () => {
        feedbackInit({
          message: 'There has been an issue while fetching your virtual bank accounts',
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      }
    }
  );
  const accounts = data?.data || [];
  const paging = data?.paging;

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="payment-link-summary">
            <div className="heading-box-mmd">
              <h4 className="form-header payment-link-header">Personalize payments with Virtual Accounts</h4>
              <p className="form-desc payment-desc">
                Virtual Bank Accounts give your customers a more personalized payment experience. Create and assign a virtual account to a
                customer; you receieve credited any time they pay into the virtual account.
                <a
                  href="https://developers.bvnkng.com/docs/virtual-accounts"
                  target="_blank"
                  style={{ marginLeft: '0.3rem' }}
                  rel="noreferrer"
                >
                  Learn more.
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="content-i">
        <div className="row">
          <div className="col-sm-12">
            <div className="element-wrapper">
              <div
                className="os-tabs-controls os-tabs-complex settlement-tabs"
                style={{ alignItems: 'center', borderBottom: '1px solid #dee2e6', marginRight: '0px' }}
              >
                <ul className="nav nav-tabs" style={{ borderBottom: 'none' }}>
                  {(availableCurrencies || ['NGN']).map(currency => {
                    return (
                      <li className="nav-item" key={currency}>
                        <button
                          type="button"
                          data-toggle="tab"
                          onClick={() => {
                            setState({ ...state, activeCurrency: currency, paginationPage: 1 });
                            logBreadCrumb({ event: breadCrumbEvents.virtualAccounts.tabClicked(currency) });
                          }}
                          className={`nav-link  ${state.activeCurrency === currency && 'active'}`}
                        >
                          <span className="tab-label">{currency}</span>
                        </button>
                      </li>
                    );
                  })}
                </ul>
                <div>
                  <div className="element-actions d-sm-block">
                    <div className="search-filter-box">
                      <SearchComponent handleSearchQuery={value => setState({ ...state, search: value })} />
                    </div>
                  </div>
                </div>
              </div>
              <Table
                tableClassName="--vba-history"
                headings={[
                  {
                    value: 'Status'
                  },
                  {
                    value: 'Account'
                  },
                  {
                    value: 'Account Name'
                  },
                  {
                    value: 'Account Reference'
                  }
                ]}
                doNotShowIf={state.activeCurrency === 'USD'}
                hasPagination
                loading={isFetching}
                current={paging?.current}
                totalItems={paging?.total_items}
                limitAction={limit => setState({ ...state, limit })}
                pageSize={paging?.page_size}
                actionFn={p => setState({ ...state, paginationPage: p })}
                annotation="accounts"
                emptyStateHeading="No virtual bank accounts yet"
                emptyStateMessage="You currently have not created any virtual bank accounts"
                tableWrapperClassName="vba-container sub"
              >
                <div>
                  {accounts?.map(each => {
                    return (
                      <div
                        className="div-table --vba-history --row"
                        key={each?.bvnkng_reference}
                        style={{ background: each?.status === 'suspended' ? '#FFFAF0' : '' }}
                      >
                        <div>
                          <span className="body-row-header">Status:</span>
                          <span className={`status-pill smaller ${switchStatus(each?.status)}`} />
                          <span>{capitalize(each?.status)}</span>
                        </div>
                        <div
                          onClick={() => {
                            history.push(`/dashboard/virtual-accounts/${each?.bvnkng_reference}`);
                            logBreadCrumb({
                              event: breadCrumbEvents.virtualAccounts.tableClicked(state.activeCurrency),
                              data: { tab: state.activeCurrency, reference: each?.bvnkng_reference }
                            });
                          }}
                          onKeyDown={() => {
                            history.push(`/dashboard/virtual-accounts/${each?.bvnkng_reference}`);
                            logBreadCrumb({
                              event: breadCrumbEvents.virtualAccounts.tableClicked(state.activeCurrency),
                              data: { tab: state.activeCurrency, reference: each?.bvnkng_reference }
                            });
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <span className="body-row-header">Account:</span>
                          <span>
                            <span style={{ fontWeight: 600, color: '#7447fd' }}>{each?.account_number}</span> -{' '}
                            {each?.bank_name || 'Not Available'}
                          </span>
                        </div>
                        <div>
                          <span className="body-row-header">Account Name:</span>
                          <span>{each?.account_name}</span>
                        </div>
                        <div>
                          <span className="body-row-header">Account Reference:</span>
                          <span style={{ color: 'rgba(0, 10, 26, 0.4)' }}>
                            <Copyable text={each?.account_reference} showOnHover />
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function VirtualBankAccounts() {
  return (
    <div className="virtual-accounts__container">
      <Switch>
        <Route exact path="/dashboard/virtual-accounts" component={VirtualAccounts} />
        <Route path="/dashboard/virtual-accounts/:id">
          <VirtualAccountDetails />
        </Route>
      </Switch>
    </div>
  );
}
