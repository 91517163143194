import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { history } from '+utils';
import use2FAHistory from '+hooks/use2FAHistory';
import useStore from '+store';
import PasswordImage from '+assets/img/dashboard/password.png';
import TwoFAImage from '+assets/img/dashboard/2fa.png';
import IPWhitelistIcon from '+assets/img/dashboard/ipwhitelisticon.svg';
import ChangePasswordComponent from './ChangePassword';
import TwoFactorAuthComponent from './TwoFactorAuth';
import IPWhiteList from './IPWhiteList';

function SecurityHome() {
  const authDetails = useStore(state => state.authDetails);
  const userToken = authDetails?.access_token;
  const [ipWhitelistPermission, setIpWhitelistPermission] = useState('');
  const { data: get2FAHistory } = use2FAHistory({ key: 'GET_2FA_DETAILS' });

  useEffect(() => {
    if (userToken) {
      const jwt = JSON.parse(atob(userToken.split('.')[1]));
      setIpWhitelistPermission(jwt?.permissions?.permissions?.ip_address_configuration || '');
    }
  }, [userToken]);

  return (
    <>
      <div className="nav-content" id="securityTab">
        <div className="element-box" style={{ padding: '1.5rem 0rem' }}>
          <div className="element-box-heading">
            <div className="heading-box-mmd">
              <h4 className="form-header">Account Security</h4>
              <div className="form-desc no-underline">
                bvnkng maintains high standards for account security. That&apos;s why we&apos;ve provided a couple of ways for you to protect
                your account.
              </div>
            </div>
          </div>
          <div className="settings-box">
            <div className="settings-group">
              <legend>
                <span />
              </legend>
              <div className="settings-item  with-image">
                <div className="settings-item-info">
                  <div className="info-img">
                    <img alt="password" src={PasswordImage} />
                  </div>
                  <div className="info-text">
                    <div className="info-title">Password</div>
                    <div className="info-desc">Keep your password safe. We recommend you change your password periodically.</div>
                  </div>
                </div>
                <span className="settings-item-control">
                  {/* <span class="control-annotation">Last updated: 2 Jun 2020</span> */}
                  <button className="btn btn-primary" type="button" onClick={() => history.push('/dashboard/settings/security/password')}>
                    Change Password
                  </button>
                </span>
              </div>
              <legend>
                <span />
              </legend>
              <div className="settings-item  with-image">
                <div className="settings-item-info">
                  <div className="info-img">
                    <img alt="2FA" src={TwoFAImage} />
                  </div>
                  <div className="info-text">
                    <div className="info-title">Two-factor Authentication</div>
                    <div className="info-desc">Add extra security on your account to authenticate your login and transactions.</div>
                  </div>
                </div>
                <span className="settings-item-control">
                  {/* <span class="control-annotation">Last updated: 2 Jun 2020</span> */}
                  <button className="btn btn-primary" type="button" onClick={() => history.push('/dashboard/settings/security/twofactor')}>
                    {get2FAHistory?.data?.is_activated ? 'Manage' : 'Get Started'}
                  </button>
                </span>
              </div>
              {ipWhitelistPermission?.toLowerCase() === 'manage' ? (
                <>
                  <legend>
                    <span />
                  </legend>
                  <div className="settings-item  with-image">
                    <div className="settings-item-info">
                      <div className="info-img">
                        <img alt="IP Whitelist" src={IPWhitelistIcon} />
                      </div>
                      <div className="info-text">
                        <div className="info-title">IP Whitelisting</div>
                        <div className="info-desc">
                          Allow only IP addresses on your authorised list to process payout from your bvnkng account.
                        </div>
                      </div>
                    </div>
                    <span className="settings-item-control">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => history.push('/dashboard/settings/security/ip-whitelist')}
                      >
                        Get Started
                      </button>
                    </span>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default function SecurityComponent() {
  const authDetails = useStore(state => state.authDetails);
  const userToken = authDetails?.access_token;
  const [ipWhitelistPermission, setIpWhitelistPermission] = useState('');

  useEffect(() => {
    if (userToken) {
      const jwt = JSON.parse(atob(userToken.split('.')[1]));
      setIpWhitelistPermission(jwt?.permissions?.permissions?.ip_address_configuration || '');
    }
  }, [userToken]);

  return (
    <Switch>
      <Route exact path="/dashboard/settings/security" component={SecurityHome} />
      <Route path="/dashboard/settings/security/password" render={() => <ChangePasswordComponent />} />
      <Route path="/dashboard/settings/security/twofactor" render={() => <TwoFactorAuthComponent />} />
      {ipWhitelistPermission === 'manage' ? (
        <Route path="/dashboard/settings/security/ip-whitelist" render={() => <IPWhiteList />} />
      ) : null}
      <Route path="*" component={SecurityHome} />
    </Switch>
  );
}
