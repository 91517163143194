import { PermissionColumnType } from "+types";

export const serializeSelectedPermission = ( permission?: string ): string[] => {
  const { view, export: exportPermission, manage } = PermissionColumnType; 
  switch (permission) {
    case PermissionColumnType.export:
      return [view, exportPermission];
    
    case PermissionColumnType.manage:
        return [view, exportPermission, manage];
    
    case PermissionColumnType.view:
      return [view];
  
    default:
      return [];
  }
};

export const twoFactor = {
  TOTP_RECOVERY_CODE: 'totp_recovery_code',
  OTP: 'otp',
  TOTP: 'totp'
}
