import { useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { bankNameEnquiry } from '+hooks';
import { logError } from '+utils';

export function useAccountValidation({ formRef, currency, canEditName }) {
  const lastVerificatiedAt = useRef(null);
  const verificationDebounceTime = 700;

  const [bankDetails, setBankDetails] = useState({
    amount: null,
    description: null,
    pin: '',
    bankName: '',
    bankNumber: '',
    username: '',
    canEditName: false,
    bankEnquiry: {
      visible: false,
      message: 'Verifying bank account...',
      type: 'secondary'
    },
    showNameInput: false,
    isValidated: false
  });

  const resetValidationDetails = () => {
    setBankDetails(prevDetails => ({
      ...prevDetails,
      showNameInput: false,
      isValidated: false,
      bankEnquiry: { ...prevDetails.bankEnquiry, visible: false },
      username: ''
    }));
    formRef?.current?.setFieldValue?.('account_name', '');
  };

  const isAcctNumLengthValid = (selectedCurrency, acctNum = 0) => {
    const acctNumLength = `${acctNum}`?.length;
    switch (selectedCurrency) {
      case 'NGN':
        return acctNumLength > 9 && acctNumLength < 12;
      case 'KES':
        return acctNumLength >= 8;
      case 'ZAR':
        return acctNumLength >= 7 && acctNumLength < 12;
      default:
        return acctNumLength === 10;
    }
  };

  const validateBankAccount = async (values, setFieldValue) => {
    let name;
    const data = { bank: values?.bank_code, account: `${values?.account_number}`, currency };
    if (values?.bank_code?.length && isAcctNumLengthValid(currency, values?.account_number)) {
      setFieldValue('account_name', '');
      try {
        if (!['ZAR'].includes(currency)) {
          setBankDetails(details => ({ ...details, bankEnquiry: { visible: true, message: 'Verifying bank account...' } }));
          const { data: bankDetailsData } = await bankNameEnquiry(data, true);
          name = bankDetailsData?.account_name;
          if (name) {
            setFieldValue('account_name', name);
          }
        }
        setBankDetails(details => ({
          ...details,
          isValidated: true,
          showNameInput: !!name || ['ZAR'].includes(currency),
          bankEnquiry: { visible: false },
          username: name || '',
          ...(canEditName && !name && { canEditName: true }),
          bankName: values?.bank_code,
          bankNumber: values?.account_number
        }));
      } catch (error) {
        logError(error);
        setBankDetails(details => ({
          ...details,
          bankEnquiry: {
            // Hide error from previous validation
            visible: new Date() - lastVerificatiedAt.current > verificationDebounceTime,
            type: 'danger',
            message: 'Please check bank details and try again'
          }
        }));
      }
    }
  };

  const debounceValidateBankAccount = useDebouncedCallback((...params) => {
    lastVerificatiedAt.current = new Date();
    validateBankAccount(...params);
  }, verificationDebounceTime);

  const showValidationStatus = () => {
    const { visible: show, message, type: enquiryType } = bankDetails.bankEnquiry;
    return (
      show && (
        <div style={{ margin: '-5px 0 10px' }}>
          {!['danger', 'info'].includes(enquiryType) && (
            <span
              className="spinner-border spinner-border-sm"
              style={{ opacity: '0.4', marginLeft: '10px' }}
              role="status"
              aria-hidden="true"
            />
          )}
          <em className={`text-${enquiryType}`}>{message}</em>
        </div>
      )
    );
  };

  return {
    validateBankAccount,
    debounceValidateBankAccount,
    resetValidationDetails,
    isAcctNumLengthValid,
    bankDetails,
    setBankDetails,
    showValidationStatus,
    accountNumMaxLength: currency === 'KES' ? '20' : '11',
  };
}

export default useAccountValidation;
