import { useEffect, useRef, useState } from 'react';

interface ICountdownTimer {
  targetTime: number;
  onCountdownEnd: () => void;
}

const CountdownTimer = ({ targetTime, onCountdownEnd }: ICountdownTimer) => {
  const [timeRemaining, setTimeRemaining] = useState(Math.abs(targetTime));
  const startTime = useRef<number>(performance.now());

  useEffect(() => {
    let animationFrameId: number = 0;
    const updateCountdown = () => {
      const currentTime = performance.now();
      const elapsedSeconds = Math.floor((currentTime - startTime.current) / 1000);
      const updatedTimeRemaining = Math.max(0, targetTime - elapsedSeconds);

      setTimeRemaining(updatedTimeRemaining);

      if (updatedTimeRemaining === 0) {
        onCountdownEnd();
      } else {
        animationFrameId = requestAnimationFrame(updateCountdown);
      }
    };

    updateCountdown();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [targetTime, onCountdownEnd]);

  return (
    <span role="alert" aria-live="polite">
      Resend code in {timeRemaining} secs.
    </span>
  );
};

export default CountdownTimer;
