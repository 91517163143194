import React, { useState } from 'react';
import { useMutation } from 'react-query';
import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';

import useFeedbackHandler from '+hooks/feedbackHandler';
import useMerchantAuth from '+hooks/useMerchantAuth';
import APIRequest from '+services/api-services';
import { history, logError } from '+utils';

import Modal from '../../../../Shared/Modal';

import GaLogo from '+assets/img/dashboard/ga-logo.png';

const authBaseURL = process.env.REACT_APP_AUTH_API_BASE || 'http://localhost:3000';
const authAPIRequest = new APIRequest(authBaseURL);

interface IDataShape {
  verified_at: string;
  identifier: string;
}

interface IExistingTwoFactorComponentProps {
  data: {
    data: IDataShape;
  };
}

const ExistingTwoFactorComponent = ({ data }: IExistingTwoFactorComponentProps) => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [code, setCode] = useState('');
  const [attempted, setAttempted] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const authenticationCodeErrors = ['authorization code is invalid'];
  const { bootstrap, handleLogout } = useMerchantAuth();

  const handleInput = value => {
    if (!value || value.length < 6) {
      setCode(value.replace(/[A-Z<>%$!.+]/gi, ''));
      setDisabled(true);
    } else {
      setCode(value);
      setDisabled(false);
    }
  };

  const handleCloseModal = () => {
    setCode('');
    setModalVisible(false);
  };

  const deactivateTwoFAMutation = useMutation(() => authAPIRequest.deactivateTwoFA({ code, identifier: data?.data?.identifier }), {
    onSuccess: async () => {
      if (attempted === 0) setAttempted(data?.data?.attempts);
      setDisabled(true);
      setTimeout(async () => {
        closeFeedback();
        // setModalVisible(false);
        await bootstrap();
        history.push('/dashboard/settings/security');
      }, 3000);
    },
    onError: err => {
      const error = err?.response?.data;
      logError(error?.message);
      setCode('');
      if (attempted < 2) {
        setModalVisible(false);
        setDisabled(false);
        setAttempted(attempted + 1);
        feedbackInit({
          message: authenticationCodeErrors?.includes(error?.message)
            ? 'There has been an error deactivating two-factor authentication. Please try again'
            : 'Please enter a valid authentication code and try again',
          type: 'danger'
        });
        throw error;
      }
      handleLogout();
    }
  });

  return (
    <>
      <h5 className="form-header">Two-Factor Authentication</h5>
      <div className="form-desc">
        Two-factor authentication (2FA) provides an extra layer of security that reconfirms your identity <br /> on bvnkng. Every time you
        sign in or perform critical actions on your dashboard, you will be <br /> required to enter a unique single-use authentication code
        to confirm your identity.
      </div>
      <div>
        <div className="todo-list large">
          <div className="todo-item complete">
            <div className="ti-img">
              <img alt="Google Authenticator" src={GaLogo} style={{ width: '3rem', marginRight: '1rem' }} />
            </div>
            <div className="ti-info">
              <div className="ti-header">Authentication Enabled</div>
              <div className="ti-sub-header mb-3">
                Added: {data && data?.data?.verified_at ? `${dayjs(data?.data?.verified_at).format('D MMMM YYYY')}` : 'N/A'}
              </div>
              <div className="ti-sub-header" style={{ fontWeight: '500' }}>
                {data && data?.data?.identifier && `Identifier: ${data?.data?.identifier.toUpperCase()}`}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-buttons-w button-row">
        <button
          className="btn-disable"
          data-target="#disableAuthModal"
          data-toggle="modal"
          type="button"
          onClick={() => setModalVisible(true)}
        >
          Disable Authorization
        </button>
        <button className="btn btn-white" type="button" onClick={() => history.push('/dashboard/settings/security')}>
          Close
        </button>
      </div>
      {modalVisible && (
        <Modal
          close={handleCloseModal}
          visible={modalVisible}
          heading="Disable Two-Factor Authentication?"
          description="Disabling authentication will limit the protection of your account to just your login credentials. Enter a security code from your authentication app or use a recovery code to complete this action."
          content={
            <div className="form-group">
              <label htmlFor="twofa-code" style={{ fontSize: '0.9rem' }}>
                Enter security code
              </label>
              <input
                name="twofa-code"
                className="form-control"
                placeholder="000000"
                type="text"
                pattern="\d*"
                maxLength="10"
                value={code || ''}
                onChange={e => handleInput(e.target.value)}
              />
              {attempted > 0 && <span style={{ color: '#e65252', fontSize: '0.8rem' }}>You have {3 - attempted} attempts remaining</span>}
            </div>
          }
          secondButtonAction={() => deactivateTwoFAMutation.mutateAsync()}
          secondButtonText="Disable"
          secondButtonColor="#F32345"
          secondButtonDisable={disabled}
          completedHeading="Two-factor Authentication disabled"
          completedDescription="You have successfully disabled two-factor authentication for your account."
          completedAction={() => history.push('/dashboard/settings/security')}
        />
      )}
    </>
  );
};

export default React.memo(ExistingTwoFactorComponent);
