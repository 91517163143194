/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';

import AlertBubble from '+containers/Shared/AlertBubble';
import CountdownTimer from '+dashboard/Shared/CountdownTimer';
import Modal, { IModalProps } from '+dashboard/Shared/Modal';
import { useFeedbackHandler, useOTPAuth } from '+hooks';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { backwardAmountInput, cleanInput, filterUserInput, formatAmount, logError, maskEmail, stripNonNumeric } from '+utils';

import { CardCreationStageType, ICardCreationModal, IError } from '../types';
import { getOtpDescriptors } from '../utils';
import { cardColorOptions, cardSchemeOptions, currencyOptions, limitPeriodOptions, stageValidationOptions } from './cardCreationModalData';
import CardThumbnail from './index';

const publicApi = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);
const MAX_CHAR_LENGTH = 20;
const PRE_FUND_AMOUNT = Number(process.env.REACT_APP_MIN_RVC_PREFUND_AMOUNT || '10000');
const mutationConfig: Partial<Record<CardCreationStageType, Function>> = {
  CREATE: publicApi.initiateReservedCardCreation,
  CONFIRM: publicApi.authorizeReservedCardCreation
};

const stages: Record<CardCreationStageType, CardCreationStageType> = {
  CREATE: 'CREATE',
  MANAGE: 'MANAGE',
  CONFIRM: 'CONFIRM',
  FUND: 'FUND'
};

const CardCreationModal = ({ gotoStage, onClose, merchantInfo, onFundBalance }: ICardCreationModal) => {
  const [stage = gotoStage, setStage] = useState<CardCreationStageType | undefined>();
  const [currentCardColor, setCurrentCardColor] = useState(cardColorOptions[2].label);
  const [maxAttemptsReached, setMaxAttemptsReached] = useState(false);
  const { closeFeedback, feedbackInit } = useFeedbackHandler();
  const queryClient = useQueryClient();
  const { defaultMerchant, issuingFees } = useStore(store => store);
  const cardCreationFee = issuingFees?.reserved?.issuance;
  const merchantEmail = defaultMerchant?.email;
  const { updateCountdownStatus, countdownIsCompleted, authState, resendOTP, updateAuthState } = useOTPAuth();
  const [creationRef, setCreationRef] = useState('');
  const [canGoToNextStage, setCanGoToNextStage] = useState(false);
  const { otpLabel, otpPlaceholder } = getOtpDescriptors(authState.two_factor_type);

  const { mutateAsync } = useMutation(mutationConfig[stage], {
    onSuccess: data => {
      if (stage === stages.CONFIRM) {
        queryClient.invalidateQueries('ISSUANCE_RESERVED_CARDS');
        queryClient.invalidateQueries('ISSUING_BALANCE');
        cleanUpForm();
      }

      if (stage === stages.CREATE) {
        const { reference, auth } = data.data;
        updateAuthState({ ...auth });
        setCreationRef(reference);
      }
    },
    onError: (e: IError) => {
      logError(e);
      let message;
      if (e.response?.data?.message?.includes('0 attempt left')) {
        message = 'You have exceeded the maximum number of attempts. Please restart this transaction.';
        setMaxAttemptsReached(true);
      } else {
        message = e.response?.data.message || 'There has been an error';
      }
      feedbackInit({
        message,
        type: 'danger',
        componentLevel: true
      });
      setTimeout(() => {
        if (e.response?.data?.message?.includes('0 attempt left')) {
          cleanUpForm();
          onClose();
        }
        closeFeedback();
      }, 5000);
    }
  });

  const { first_name: firstName, last_name: lastName } = merchantInfo || {};

  const initialValues = {
    currency: 'USD',
    cardScheme: '',
    cardLabel: '',
    limitPeriod: '',
    maxSpend: '',
    otp: ''
  };

  const goToPrevStageOrClose = () => {
    if ([stages.MANAGE, stages.CONFIRM].includes(stage)) {
      if (authState.two_factor_type === 'totp_recovery_code') {
        updateAuthState({ two_factor_type: 'totp' });
        return;
      }
      setStage(stages.CREATE);
      updateCountdownStatus(true);
      return;
    }
    cleanUpForm();
    onClose();
  };

  const handleCardColorChange = (index: number) => {
    setCurrentCardColor(cardColorOptions[index].label);
  };

  const cleanUpForm = () => {
    resetForm({ values: initialValues });
  };

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    dirty,
    isValid,
    getFieldProps,
    submitForm,
    setFieldValue,
    resetForm,
    validateForm
  } = useFormik({
    initialValues,
    validate: formValues => stageValidationOptions[stage]?.(formValues, authState.two_factor_type, otpLabel),
    validateOnMount: true,
    onSubmit: async () => {
      switch (stage) {
        case stages.CONFIRM:
          await authorizeWithOtp();
          break;
        case stages.CREATE:
        default:
          await sendOtpToMail();
          updateCountdownStatus(false);
          break;
      }
    }
  });

  useEffect(() => {
    if (values.otp === '' && stage === 'CONFIRM') {
      setCanGoToNextStage(false);
    } else {
      setCanGoToNextStage(dirty && isValid);
    }
  }, [values, stage, isValid, dirty]);

  const handleOtpResend = async () => {
    updateCountdownStatus(false);
    await resendOTP();
  };

  const authorizeWithOtp = async () => {
    await mutateAsync({
      reference: creationRef,
      auth: {
        ...authState,
        code: values.otp
      }
    });
  };

  const sendOtpToMail = async () => {
    await mutateAsync({
      currency: values.currency,
      brand: values.cardScheme,
      label: values.cardLabel,
      color: currentCardColor,
      card_type: 'virtual'
    });
    setStage(stages.CONFIRM);
  };

  const renderCardCreation = () => {
    return (
      <div className="reset-margin reserved-vcard-container stack-lg">
        <div className="stack-sm flex">
          <CardThumbnail
            cardDetails={{
              lastFourDigits: <span className="hidden-char">••••</span>,
              scheme: values.cardScheme,
              expiresAt: '**/**',
              holderName: `${firstName} ${firstName !== lastName ? lastName : ' '}`,
              label: values.cardLabel
            }}
            variant="withLabel"
            color={currentCardColor}
          />
        </div>

        <div className="flex">
          <p className="sr-only">Choose your preferred card color</p>
          <ul style={{ width: '1rem' }} className="flex">
            {cardColorOptions.map(({ label, value }, index) => (
              <li key={label}>
                <button
                  style={{ backgroundImage: `linear-gradient(${value})` }}
                  className="rounded-full card-color-option"
                  data-active-card={`${currentCardColor === value}`}
                  aria-label={`${label} card`}
                  type="button"
                  onClick={() => handleCardColorChange(index)}
                />
              </li>
            ))}
          </ul>
        </div>

        <fieldset className=" stack-sm">
          <legend className="semibold">Name on card</legend>

          <div className="flex" data-justify="between">
            <label htmlFor="first-name" className="sr-only">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              id="first-name"
              value={firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-control"
              placeholder="First Name"
              aria-describedby="firstNameHelperText"
              readOnly
            />
            <span id="firstNameHelperText" className="sr-only">
              This first name field is currently not interactive
            </span>

            <label htmlFor="last-name" className="sr-only">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              id="last-name"
              value={lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-control"
              placeholder="Last Name"
              aria-describedby="lastNameHelperText"
              readOnly
            />
            <span id="lastNameHelperText" className="sr-only">
              This last name field is not interactive
            </span>
          </div>
        </fieldset>

        <div className="stack-sm">
          <label htmlFor="currency" className="semibold">
            Currency
          </label>
          <select className="form-control" id="currency" {...getFieldProps('currency')} style={{ pointerEvents: 'none' }} tabIndex={-1}>
            {currencyOptions.map(({ value, label }) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
        </div>

        <div className="stack-sm">
          <label htmlFor="card-scheme" className="semibold">
            Card scheme
          </label>
          <select className="form-control" id="card-scheme" {...getFieldProps('cardScheme')}>
            {cardSchemeOptions.map(({ value, label }) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>

          {touched.cardScheme && errors.cardScheme && <p style={{ color: 'red', fontSize: '.875rem' }}>{errors.cardScheme}</p>}
        </div>

        <div className="stack-sm">
          <label htmlFor="card-label" className="semibold">
            Card label
          </label>
          <input
            type="text"
            name="cardLabel"
            id="card-label"
            value={values.cardLabel}
            maxLength={MAX_CHAR_LENGTH}
            onChange={e => setFieldValue('cardLabel', filterUserInput(e.target.value))}
            onBlur={handleBlur}
            className="form-control"
            placeholder="E.g Travel card"
            aria-describedby="card-label-word-count"
          />

          <span className="flex width-full" data-justify="between">
            {touched.cardLabel && errors.cardLabel && <p style={{ color: 'red', fontSize: '.875rem' }}>{errors.cardLabel}</p>}
            <span
              id="card-label-word-count"
              className="ml-auto text-sm"
              aria-live="polite"
            >{`${values.cardLabel.length}/${MAX_CHAR_LENGTH}`}</span>
          </span>
        </div>
      </div>
    );
  };

  const renderCardManagement = () => {
    return (
      <div className="reset-margin reserved-vcard-container stack-lg">
        <div className="stack-sm">
          <label htmlFor="limitPeriod" className="semibold">
            Select limit period
          </label>
          <select className="form-control" id="card-scheme" {...getFieldProps('limitPeriod')}>
            {limitPeriodOptions.map(({ value, label }) => (
              <option value={value} key={value}>
                {label}
              </option>
            ))}
          </select>
        </div>

        <div className="stack-sm">
          <label htmlFor="max-spend" className="semibold">
            Maximum spend for this period ({values.currency.toUpperCase()})
          </label>
          <input
            type="text"
            name="maxSpend"
            id="max-spend"
            value={values.maxSpend}
            onChange={e => setFieldValue('maxSpend', backwardAmountInput(cleanInput(e.target.value)))}
            className="form-control"
            placeholder="Enter amount"
            aria-describedby="max-spend-range"
          />

          <span id="max-spend-range" className="flex width-full text-sm" data-justify="between" role="status">
            <span>
              Minimum amount: <strong>{values.currency.toUpperCase()}100</strong>
            </span>
            <span style={{ textAlign: 'end' }}>
              Maximum amount: <strong>{values.currency.toUpperCase()}100</strong>
            </span>
          </span>
        </div>
      </div>
    );
  };

  const renderConfirmation = () => {
    const MIN_LENGTH = 6;
    const MAX_LENGTH = 11;
    return (
      <div className="reserved-vcard-container stack-xl">
        <p>
          To proceed, enter{' '}
          {authState.two_factor_type === 'otp' ? (
            <>
              the OTP (one-time PIN) that was sent to your email (<strong>{maskEmail(merchantEmail)}</strong>).
            </>
          ) : null}
          {authState.two_factor_type === 'totp' ? 'the authentication code from your authenticator app' : null}
          {authState.two_factor_type === 'totp_recovery_code' ? 'a recovery code' : null}
        </p>

        <div className="stack-md">
          <label htmlFor="otp" className="rvc-label" style={{ fontSize: '0.8rem' }}>
            {otpLabel}
          </label>
          <input
            type="text"
            name="otp"
            id="otp"
            value={values.otp}
            onChange={e => setFieldValue('otp', stripNonNumeric(cleanInput(e.target.value)))}
            onBlur={handleBlur}
            className="form-control"
            placeholder={otpPlaceholder}
            aria-describedby="max-spend-range"
            inputMode="numeric"
            autoComplete="one-time-code"
            minLength={MIN_LENGTH}
            maxLength={MAX_LENGTH}
            disabled={maxAttemptsReached}
          />

          {errors.otp && touched.otp ? (
            <p className="error-msg mt-1" style={{ fontSize: '13px', color: '#F32345' }}>
              {errors.otp}
            </p>
          ) : null}

          {authState.two_factor_type === 'totp' ? (
            <div className="otp-cta">
              <span>Can&apos;t access authenticator app?</span>
              <button
                type="button"
                className="semibold btn btn-link"
                onClick={() => updateAuthState({ two_factor_type: 'totp_recovery_code' })}
              >
                Confirm using recovery codes
              </button>
            </div>
          ) : null}

          {authState.two_factor_type === 'otp' ? (
            <div className="otp-cta with-countdown">
              <span>You didn&apos;t receive a code?</span>
              {countdownIsCompleted ? (
                <button disabled={maxAttemptsReached} type="button" className="semibold btn btn-link" onClick={handleOtpResend}>
                  Resend code.
                </button>
              ) : (
                <CountdownTimer targetTime={30} onCountdownEnd={() => updateCountdownStatus(true)} />
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const modalPropsOptions: Record<CardCreationStageType | 'SHARED', Partial<IModalProps>> = {
    SHARED: {
      size: 'md',
      close: () => {
        setStage(stages.FUND);
        onClose();
      },
      maxHeight: '720px',
      firstButtonAction: goToPrevStageOrClose,
      secondButtonAction: submitForm,
      secondButtonDisable: !canGoToNextStage
    },
    [stages.FUND]: {
      size: 'sm',
      heading: 'Insufficient funds in balance',
      description: (
        <div>You do not have sufficient funds in your balance to complete this action. Please fund your issuing balance to continue</div>
      ),
      content: (
        <div className="fade-in">
          <AlertBubble variant="grey">
            <strong>The minimum amount required to create a virtual card is ${formatAmount(cardCreationFee + PRE_FUND_AMOUNT)}.</strong>{' '}
            bvnkng charges a ${formatAmount(cardCreationFee)} card creation fee and your card is automatically pre-funded with $
            {formatAmount(PRE_FUND_AMOUNT)}.
          </AlertBubble>
        </div>
      ),
      secondButtonDisable: false,
      secondButtonText: 'Fund Balance',
      secondButtonAction: () => {
        onFundBalance();
        onClose();
      }
    },
    [stages.CREATE]: {
      heading: 'Create a new virtual card',
      description: <div className="text-md">Provide the details below to create a reserved virtual card</div>,
      content: renderCardCreation(),
      secondButtonText: 'Next',
      secondButtonActionIsTerminal: false
    },
    [stages.MANAGE]: {
      heading: 'Manage virtual card spending limit',
      description: (
        <div className="text-md">This limit defines the amount of money that is accessible on this virtual card per period selected.</div>
      ),
      content: renderCardManagement(),
      secondButtonText: 'Continue',
      firstButtonText: 'Back',
      secondButtonActionIsTerminal: false,
      renderFooter: ({ getFirstButtonProps, getSecondButtonProps, getFooterProps, firstButtonText, secondButtonText }) => {
        return (
          <div {...getFooterProps('flex')} data-justify="between" data-modal-stage="manage-limits" className="footer">
            <button type="button" data-modal-stage="manage-limits" className="skip-stage-cta" onClick={() => setStage(stages.CONFIRM)}>
              I&apos;ll do this later
            </button>
            <div className="flex button-wrapper" data-modal-stage="manage-limits">
              <button type="button" {...getFirstButtonProps()} data-modal-stage="manage-limits" className="first-button">
                {firstButtonText}
              </button>
              <button type="button" {...getSecondButtonProps()} data-modal-stage="manage-limits" className="second-button">
                {secondButtonText}
              </button>
            </div>
          </div>
        );
      }
    },
    [stages.CONFIRM]: {
      size: 'sm',
      secondButtonDisable: !canGoToNextStage || maxAttemptsReached,
      heading: 'Confirm card creation',
      description: (
        <div className="text-md">
          Please confirm that you want to proceed with this card creation. Once created, this card can be funded and used to perform
          transactions.
        </div>
      ),
      content: renderConfirmation(),
      secondButtonText: 'Proceed',
      firstButtonText: 'Back',
      completedDescription: <span style={{ color: 'hsla(212, 19%, 31%, 1)' }}>You have successfully created a reserved virtual card</span>,
      completedActionText: 'Dismiss'
    }
  };

  const modalProps = {
    ...modalPropsOptions.SHARED,
    ...modalPropsOptions[stage]
  };

  return <Modal {...(modalProps as IModalProps)} />;
};

export default CardCreationModal;
