import { useQuery } from 'react-query';

import { getExistingFiltersFromQuery } from '+containers/Dashboard/Shared/FilterModal';
import { useFeedbackHandler } from '+hooks';
import { Currency } from '+types';
import { filteredOutObjectProperty, logError, queryParams } from '+utils';
import useStore from '+store';

import { publicApi } from '../constants';
import { balanceHistoryFilterFields, cardsFilterFields, issuedCardChargebacksFilterField, transactionsFilterFields } from '../data';
import { AllTableResponsesType, IError, TableKeyType } from '../types';
import { getIssuingPermissions } from '../utils';

interface IUseFetchIssuanceTables {
  currency?: Currency;
  tab: TableKeyType;
  limit: string;
  page: string;
  queryValue: any;
}

interface IQueryFunction {
  currency?: Currency;
  limit: string;
  page: string;
}

export default function useFetchIssuanceTables({ currency, tab, limit, page, queryValue }: IUseFetchIssuanceTables) {
  const { merchantEnv: MERCHANT_ENV } = useStore.getState();
  const { feedbackInit } = useFeedbackHandler();

  const sortingParams = {
    ...filteredOutObjectProperty(queryValue, [
      queryParams.currency,
      queryParams.page,
      queryParams.limit,
      queryParams.tab,
      queryParams.subtab,
      queryParams.sorterType
    ])
  };

  const { data: tableData, isFetching, refetch } = useQuery<AllTableResponsesType, IError>(
    [QueryKeys[tab], limit, page, sortingParams, MERCHANT_ENV],
    () => QueryFns[tab]({ limit, page, currency }),
    {
      onError: e => {
        logError(e);
        const message = e.response?.data?.message;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: refetch,
            name: 'Try again'
          }
        });
      },
      enabled: switchPermission(tab)
    }
  );

  return {
    tableData,
    isFetching,
    refetch
  };
}

const QueryFns: Record<TableKeyType, (args: IQueryFunction) => Promise<AllTableResponsesType>> = {
  issuing_balance_history: ({ currency, limit, page }) =>
    publicApi.fetchIssuingBalanceHistory({
      currency,
      limit,
      page,
      ...getExistingFiltersFromQuery(balanceHistoryFilterFields)
    }),
  funding_deposits: ({ limit, page }) =>
    publicApi.fetchFundingDeposits({
      limit,
      page,
      ...getExistingFiltersFromQuery(balanceHistoryFilterFields)
    }),
  customer_cards: ({ limit, page }) =>
    publicApi.fetchIssuedCards({
      cardCategory: 'customer',
      limit,
      page,
      ...getExistingFiltersFromQuery(cardsFilterFields)
    }),
  reserved_cards: ({ limit, page }) =>
    publicApi.fetchIssuedCards({
      cardCategory: 'reserved',
      limit,
      page,
      ...getExistingFiltersFromQuery(cardsFilterFields)
    }),
  card_transactions: ({ limit, page }) =>
    publicApi.fetchAllIssuanceTransactions({
      limit,
      page,
      ...getExistingFiltersFromQuery(transactionsFilterFields)
    }),
  chargebacks: ({ limit, page }) =>
    publicApi.fetchAllIssuedCardChargebacks({
      page,
      limit,
      ...getExistingFiltersFromQuery(issuedCardChargebacksFilterField)
    })
};

const QueryKeys = {
  issuing_balance_history: 'ISSUANCE_BAL_HIST',
  funding_deposits: 'ISSUANCE_FUNDING_DEPOSITS',
  card_transactions: 'ISSUANCE_CARD_TRXNS',
  customer_cards: 'ISSUANCE_CUSTOMER_CARDS',
  reserved_cards: 'ISSUANCE_RESERVED_CARDS',
  chargebacks: 'ISSUANCE_CHARGEBACKS'
};

const switchPermission = (tab: TableKeyType) => {
  const { canViewCardList, canViewChargebacks, canViewWalletHistory, canViewCardTrxns } = getIssuingPermissions();

  switch (tab) {
    case 'issuing_balance_history':
      return canViewWalletHistory;
    case 'customer_cards':
      return canViewCardList;
    case 'reserved_cards':
      return canViewCardList;
    case 'card_transactions':
      return canViewCardTrxns;
    case 'chargebacks':
      return canViewChargebacks;
    default:
      return true;
  }
};
