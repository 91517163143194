import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import Modal from '+containers/Dashboard/Shared/Modal';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { capitalizeFirst, cleanInput, logError, twoFAInputValidation } from '+utils';

import '../index.scss';

const api = new APIRequest();

export default function webhook() {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [state, setState] = useState({
    open: false,
    loading: false,
    callback: '',
    feedBackVisible: false,
    feedBackType: '',
    feedBackMessage: ''
  });
  const [authData, setAuthData] = useState({
    code: '',
    identifier: '',
    two_factor_type: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [twoFactorType, setTwoFactorType] = useState('otp');
  const [timeLeft, setTimeLeft] = useState(60);
  const intervalRef = useRef();
  const [modalType, setModalType] = useState('otp');

  const onError = (error, timeout = true) => {
    const data = error?.response?.data;
    logError(data);
    feedbackInit({
      isActive: true,
      message: data?.message || 'We are sorry, something went wrong. Please try again.',
      type: 'danger',
      componentLevel: true
    });
    if (timeout) {
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
  };

  const defaultMerchant = useStore(store => store.defaultMerchant);

  const { data, refetch: refetchNotificationUrl } = useQuery('GET_NOTIFICATION_URL', () => api.getNotificationUrls(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 1000 * 60 * 60,
    onSuccess: () => {
      setState(prevState => ({ ...prevState, callback: data?.callbacks?.[0]?.url || '' }));
    },
    onError: e => {
      const error = e.response?.data;
      logError(error);
      feedbackInit({
        message: 'There has been an error getting your notification URLs. Please try again.',
        type: 'danger',
        action: {
          action: () => refetchNotificationUrl(),
          name: 'Try again'
        }
      });
    }
  });

  const initiateOTP = useMutation(() => api.initiateOTPToken({ action: 'webhook_notification_url_management' }), {
    onSuccess: responseData => {
      const { data: initiateOTPData } = responseData;
      setAuthData(details => ({
        ...details,
        identifier: initiateOTPData?.identifier
      }));
      setTwoFactorType(initiateOTPData?.type);
    },
    onError: error => onError(error)
  });

  const resendOTP = useMutation((payload: { identifier: string }) => api.resendOTPAction(payload), {
    onError: error => onError(error)
  });

  const callbackUrl = data?.callbacks?.[0]?.url || '';

  const initiateToken = () => {
    initiateOTP.mutate();
    setTimeLeft(60);
  };

  const onResendToken = () => {
    resendOTP.mutate({
      identifier: authData.identifier
    });
    setTimeLeft(60);
  };

  useEffect(() => {
    setState(prevState => ({ ...prevState, callback: callbackUrl }));
  }, [callbackUrl]);

  const setNotificationUrl = useMutation(urlData => api.setNotificationUrls(urlData), {
    onSuccess: async () => {
      await refetchNotificationUrl();
    }
  });

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimeLeft(t => t - 1);
    }, 1000);
    if (timeLeft <= 0) {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [timeLeft]);

  const close = () => {
    setShowModal(false);
    setAuthData({
      two_factor_type: '',
      code: '',
      identifier: ''
    });
  };

  const updateUrls = async () => {
    try {
      setState({ ...state, loading: true });
      const urlData = {
        webhook_url: state.callback,
        authorization: {
          code: authData?.code,
          identifier: authData?.identifier,
          two_factor_type: twoFactorType
        }
      };
      await setNotificationUrl.mutateAsync(urlData);
      setState(prevState => ({ ...prevState, open: false, loading: false }));
      feedbackInit({
        message: 'Notification URLs updated successfully.',
        type: 'success'
      });
      return window.scrollTo(0, 0);
    } catch (e) {
      const error = e?.response?.data;
      const errorMessage = error.message;
      logError(e);
      setState(prevState => ({ ...prevState, loading: false }));
      if (errorMessage === 'invalid request data') {
        feedbackInit({
          message: 'Invalid URL format. Please try again.',
          type: 'danger',
          componentLevel: false
        });
        close();
      } else if (error?.data?.otp_invalid && error?.data?.attempts_left > 0) {
        feedbackInit({
          isActive: true,
          message: capitalizeFirst(errorMessage),
          type: 'danger',
          componentLevel: true
        });
      } else {
        feedbackInit({
          message: 'There has been an error updating your notification URLs. Please try again.',
          type: 'danger',
          componentLevel: false
        });
        close();
        window.scrollTo(0, 0);
      }
      return Promise.reject(e);
    }
  };

  const getOtpInfo = () => {
    let email = defaultMerchant?.userEmail;
    if (email?.includes('@')) {
      email = email.split('@');
      email = `${email[0].slice(0, 3)}*******@${email[1]}`;
    }
    return (
      <div className="ip-otp-info --no-color">
        {twoFactorType === 'otp' && (
          <p>
            To verify your identity, enter the seven-digit security code sent to your email <b>{email}</b>
          </p>
        )}
        {twoFactorType === 'totp' && (
          <p>
            To update Webhook URLs kindly <b>six-digit</b> code from your <span className="colored-text">authenticator app</span> in the
            space provided below.
          </p>
        )}
      </div>
    );
  };

  const otpContent = () => {
    let inputLabel = 'One Time PIN (OTP)';
    let inputPlaceholder = '7-digit code';
    if (twoFactorType === 'totp') {
      inputLabel = 'Authentication Code';
      inputPlaceholder = 'Enter authentication code';
    } else if (twoFactorType === 'totp_recovery_code') {
      inputLabel = 'Recovery Code';
      inputPlaceholder = 'Enter recovery code';
    }
    return (
      <div className="bankForm-container">
        {twoFactorType === 'totp_recovery_code' ? (
          <div className="ip-otp-info --no-color">
            <p>Can&apos;t use your authenticator app? Enter one of your previously saved recovery codes to validate this transaction.</p>
          </div>
        ) : (
          getOtpInfo()
        )}

        <label htmlFor="pin">{inputLabel}</label>
        <input
          type="number"
          name="pin"
          maxLength={7}
          placeholder={inputPlaceholder}
          autoComplete="one-time-code"
          value={authData?.code}
          onChange={e => {
            const formattedInput = cleanInput(e.target.value);
            setAuthData(details => ({ ...details, code: formattedInput }));
          }}
        />
        {twoFactorType === 'totp' && (
          <div className="recovery-code">
            Can&apos;t access authenticator app?{' '}
            <button type="button" onClick={() => setTwoFactorType('totp_recovery_code')} className="recovery-code-btn" role="link">
              Confirm using recovery codes
            </button>
          </div>
        )}
        {twoFactorType === 'otp' && (
          <div className="resend">
            <span>Didn’t receive any email?</span>
            {!timeLeft ? (
              <button type="button" onClick={onResendToken} className="link-text">
                Resend
              </button>
            ) : (
              <span> Wait {timeLeft} seconds...</span>
            )}
          </div>
        )}
      </div>
    );
  };

  const switchWebHookModal = () => {
    if (modalType === 'discard')
      return {
        modalStage: 'init',
        heading: 'Discard changes?',
        description: 'Are you sure you want to cancel, keep in mind that any unsaved work will be discarded automatically.',
        size: 'sm',
        maxHeight: 'sm',
        firstButtonText: 'Back',
        secondButtonText: 'Yes, Discard',
        close: () => setShowModal(false),
        secondButtonAction: () => {
          setState({ ...state, open: false });
          setShowModal(false);
        },
        secondButtonActionIsTerminal: false
      };

    return {
      close,
      size: 'md',
      secondButtonActionIsTerminal: true,
      secondButtonDisable: !twoFAInputValidation(twoFactorType, authData?.code),
      firstButtonDisable: false,
      completedHeading: 'Webhook URLs Updated!',
      completedDescription: 'You have successfully changed your webhook notification URLs',
      completedActionText: '',
      heading: 'Confirm Your Identity',
      description: '',
      content: otpContent(),
      secondButtonText: 'Confirm',
      firstButtonText: 'Back',
      secondButtonAction: updateUrls,
      secondButtonColor: '#F32345',
      firstButtonAction: () => {
        if (twoFactorType === 'totp_recovery_code') {
          return setTwoFactorType('totp');
        }
        return close();
      }
    };
  };

  return (
    <div className="element-box ac-element-box px-0">
      <h5 className="form-header">Notification URLs</h5>
      {!state.open ? (
        <>
          <div className="form-desc ac-subtitle">
            bvnkng uses webhooks to notify your application when certain events occur in your account.{' '}
            <a href="https://developers.bvnkng.com/docs/webhooks" target="_blank" rel="noopener noreferrer">
              See our documentation
            </a>{' '}
            to learn more about how to use webhooks.
          </div>
          <div className="header-container ac-content--header --right">
            <button type="button" className="btn ac-button" onClick={() => setState({ ...state, open: true })} hidden={state.open}>
              Update URLs
            </button>
          </div>
          <div>
            <div className="key__row">
              <h6>Webhook URL</h6>
              <p>{callbackUrl || <em>Not available</em>}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="form-desc ac-subtitle">
            Set up your Webhook and Redirect URLs. Learn more about how they work in our documentation{' '}
            <a href="https://developers.bvnkng.com/docs/webhooks" target="_blank" rel="noopener noreferrer">
              {' '}
              here.
            </a>
          </div>
          <div className="col-sm-8">
            <div className="form-group row ac-form-group">
              <label htmlFor="callback-url">
                <h6>Webhook URL</h6>
              </label>
              <input
                name="callback-url"
                className="form-control ac-form-control"
                placeholder="https://"
                maxLength="150"
                value={state.callback || ''}
                onChange={e => setState({ ...state, callback: cleanInput(e.target.value) })}
              />
            </div>
          </div>
          <div className="form-buttons-w button-row">
            <button
              className="btn btn-success"
              type="button"
              onClick={() => {
                initiateToken();
                setModalType('otp');
                setShowModal(true);
              }}
            >
              {state.loading ? (
                <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
              ) : (
                <span>Save</span>
              )}
            </button>

            <button
              className="btn"
              type="submit"
              onClick={() => {
                setModalType('discard');
                setShowModal(true);
              }}
            >
              Cancel
            </button>
          </div>
        </>
      )}
      <Modal visible={showModal} {...switchWebHookModal()} />
    </div>
  );
}
