import React from 'react';
import TabSwitch from './TabSwitch';
import './index.scss';

interface ICurrencyPickerProps {
  options?: string[];
  onChange: (currency: string) => void;
  activeCurrency: string;
  id?: string;
  className?: string;
  label?: React.ReactNode | string;
}

const CurrencyPicker = ({
  options = [],
  onChange,
  activeCurrency,
  id = '',
  className,
  label = null,
}: ICurrencyPickerProps) => {
  return (
    <div className={className}>
      <div>
        {label}
        <TabSwitch options={options} activeTab={activeCurrency} setTab={onChange} id={id} className="ml-3" maxVisibleOptions={3} />
      </div>
    </div>
  );
};

export default CurrencyPicker;
