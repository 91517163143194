/* eslint-disable camelcase */
import React, { useState } from 'react';
import IconRefreshSvg from '+assets/img/external/refresh-arrow.svg';
import BankReplacementModal from './BankReplacementModal';
import Modal from '../../../Shared/Modal';

const BankReplacement = () => {
  const [bankReplacement, setBankReplacement] = useState({
    modalVisible: false
  });
  const [isRequestSent, setIsRequestSent] = useState(false);

  const close = () => setBankReplacement(prevState => ({ ...prevState, modalVisible: false }));

  const showRequestSentModal = () => {
    setIsRequestSent(true);
  };
  return (
    <>
      <div className="settlement-bank-details-link">
        <button
          className="btn rba-button"
          type="button"
          onClick={() => setBankReplacement({ ...bankReplacement, modalVisible: true, modalType: '' })}
        >
          <img src={IconRefreshSvg} srcSet={IconRefreshSvg} alt="Replace bank account" style={{ height: '14px' }} />
          <span>Replace Bank Account</span>
        </button>
      </div>
      {bankReplacement.modalVisible && <BankReplacementModal close={close} showRequestSentModal={showRequestSentModal} />}
      {isRequestSent && (
        <Modal
          close={() => setIsRequestSent(false)}
          visible={isRequestSent}
          size="sm"
          heading="Request Sent"
          description="Your request has been succesfully submitted. Note that until your new account is verified, your withdrawals will be sent to your current bank"
          showButtons={false}
          secondButtonActionIsTerminal
        />
      )}
    </>
  );
};

export default BankReplacement;
