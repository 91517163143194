import './index.scss';

interface IFeedbackProps {
  message?: string;
  type: string;
}

const Feedback = ({ message, type }: IFeedbackProps) => {
  return (
    <div data-testid="feedback" className={`feedback-reg ${type}`} role="alert">
      {message}
    </div>
  );
};

export default Feedback;
