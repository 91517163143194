import { getExistingFiltersFromQuery } from '+containers/Dashboard/Shared/FilterModal';
import APIRequest from '+services/api-services';

import { balanceHistoryFilterFields, cardsFilterFields, issuedCardChargebacksFilterField, transactionsFilterFields } from '../data';
import { TableKeyType } from '../types';

const publicApi = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const exportActionOptions: Partial<Record<TableKeyType, (args?: any) => Promise<{ [x: string]: any }>>> = {
  issuing_balance_history: ({ fieldsToExport, format, currency }) =>
    publicApi.fetchIssuingBalanceHistory({
      toExport: true,
      currency,
      format,
      fieldsToExport,
      ...getExistingFiltersFromQuery(balanceHistoryFilterFields)
    }),
  customer_cards: ({ fieldsToExport, format }) =>
    publicApi.fetchIssuedCards({
      cardCategory: 'customer',
      toExport: true,
      exportFormat: format,
      exportFields: fieldsToExport,
      ...getExistingFiltersFromQuery(cardsFilterFields)
    }),
  reserved_cards: ({ fieldsToExport, format }) =>
    publicApi.fetchIssuedCards({
      cardCategory: 'reserved',
      toExport: true,
      exportFormat: format,
      exportFields: fieldsToExport,
      ...getExistingFiltersFromQuery(cardsFilterFields)
    }),
  card_transactions: ({ fieldsToExport, format }) =>
    publicApi.fetchAllIssuanceTransactions({
      toExport: true,
      exportFormat: format,
      exportFields: fieldsToExport,
      ...getExistingFiltersFromQuery(transactionsFilterFields)
    }),
  chargebacks: ({ fieldsToExport, format }) =>
    publicApi.fetchAllIssuedCardChargebacks({
      toExport: true,
      exportFormat: format,
      exportFields: fieldsToExport,
      ...getExistingFiltersFromQuery(issuedCardChargebacksFilterField)
    })
};

export default exportActionOptions;
