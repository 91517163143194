import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useCopyToClipboard } from 'react-use';

import ToolTip from '+containers/Dashboard/Shared/Tooltip';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import { capitalizeRemovedash, getDate, getTime, logError, switchStatus } from '+utils';
import renderStyledJson from '+utils/render-json';

import Modal from '../../../Shared/Modal';

import copyIcon from '+assets/img/copy-new.svg';
import InfoIcon from '+assets/img/dashboard/information-button.svg';

import './index.scss';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

interface IWebhooksProps {
  reference: string;
  paymentReference: string;
  uniqueReference: string;
  channel: string;
  transactionStatus: string;
  transactionType: string;
}

const switchResponseCode = (code: number) => {
  switch (code) {
    case 200:
      return {
        color: '#24B314',
        backgroundColor: '#24B31426'
      };
    case 404:
      return {
        color: '#F32345',
        backgroundColor: '#FFD2DA'
      };
    default:
      return {
        color: '#F32345',
        backgroundColor: '#FFD2DA'
      };
  }
};

const switchResponseMessage = (status: string) => {
  switch (status) {
    case 'delivered':
      return 'Webhook notification successfully sent to;';
    case 'processing':
      return 'Sending webhook notification to';
    case 'pending':
      return 'Sending webhook notification to';
    default:
      return 'Webhook notification failed';
  }
};

export default function Webhooks({
  paymentReference,
  reference,
  uniqueReference,
  channel,
  transactionStatus,
  transactionType
}: IWebhooksProps) {
  const { feedbackInit } = useFeedbackHandler();
  const [copied, setCopied] = useState(false);
  const [result, setResult] = useState(true);
  const [confirmModal, setConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, copyToClipboard] = useCopyToClipboard();

  const { data: webhookData, refetch } = useQuery(
    'WEBHOOK_NOTIFICATION',
    () => api.getWebhookNotification((transactionType === 'payouts' && reference) || ('pay-ins' && paymentReference)),
    {
      onSuccess: () => {
        setResult(true);
      },
      retry: false,
      onError: () => {
        setResult(false);
      }
    }
  );

  const resendWebhooks = useMutation(
    (data: { notification_type: string; transaction_reference: string }) => api.resendWebhookNotification(data),
    {
      onSuccess: () => {
        setLoading(false);
        refetch();
      },
      onError: error => {
        setLoading(false);
        logError(error);
        feedbackInit({
          message: error.response?.data?.message || 'We are sorry, There was an issue resending webhooks.',
          type: 'danger'
        });
      }
    }
  );

  const showResendButton = () => {
    if (channel == 'api' && ['success', 'failed'].includes(transactionStatus) && !['pending', 'processing'].includes(webhookData?.status)) {
      if (transactionType === 'pay-ins' || transactionType === 'payouts') {
        return true;
      } else return false;
    } else if (channel == 'modal' && transactionStatus === 'success' && !['pending', 'processing'].includes(webhookData?.status)) {
      if (transactionType === 'pay-ins') return true;
      else return false;
    }
  };

  return (
    <>
      <section>
        <div
          className="section-heading"
          id="section-title"
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          Webhook / Metadata
          {showResendButton() && (
            <div>
              <ToolTip
                image={InfoIcon}
                type="webhooks-info"
                message={
                  <em>
                    <strong>Note</strong>: Resending this webhook notification may result in double charges if not handled properly in your
                    codebase.
                  </em>
                }
              />
              <button aria-label="resend-webhook" className="btn btn-webhook ml-3" type="button" onClick={() => setConfirmModal(true)}>
                Resend Webhook
                <i className="os-icon os-icon-arrow-up-right ml-2" />
              </button>
            </div>
          )}
        </div>
        {result ? (
          <div className="webhooks-section">
            <div className="webhook-details">
              <p className="webhook-attempt" aria-label="last-attempt">
                Last Attempt
              </p>
              <div>
                {webhookData?.status === 'pending' ? null : (
                  <span
                    className="response-span"
                    style={{
                      color: switchResponseCode(webhookData?.response_code)?.color,
                      backgroundColor: switchResponseCode(webhookData?.response_code)?.backgroundColor
                    }}
                  >
                    {webhookData?.response_code}
                  </span>
                )}

                <span className={`status-pill status-margin smaller ${switchStatus(webhookData?.status)}`} />
                <span className="status-text">{capitalizeRemovedash(webhookData?.status || 'Not Available')}</span>
                <span className="status-pill status-margin status-smaller" />
                <span className="notification">{switchResponseMessage(webhookData?.status)}</span>
              </div>
              {['delivered', 'processing', 'pending'].includes(webhookData?.status) && (
                <div className="webhook-url" aria-label="webhook-url">
                  {' '}
                  {webhookData?.webhook_url || 'N/A'}{' '}
                </div>
              )}
              <p className="webhook-date">
                {webhookData?.webhook_date && `${getDate(webhookData?.webhook_date)}, ${getTime(webhookData?.webhook_date)}`}
              </p>

              <div className="webhook-metadata">
                <p>Summary</p>
                <p>
                  <span>Triggered At</span>
                  <span>
                    {webhookData?.webhook_date ? `${getDate(webhookData?.webhook_date)} | ${getTime(webhookData?.webhook_date)}` : 'N/A'}
                  </span>
                </p>

                <p>
                  <span>Response Code</span>
                  <span>{webhookData?.status === 'pending' ? '---' : webhookData?.response_code}</span>
                </p>
                <p>
                  <span>Retries Attempted</span>
                  <span>{webhookData?.attempts}</span>
                </p>
                <p>
                  <span>Last Attempt</span>
                  <span>
                    {webhookData?.last_attempt ? `${getDate(webhookData?.last_attempt)} | ${getTime(webhookData?.last_attempt)}` : 'N/A'}
                  </span>
                </p>
                <p>
                  <span>Type</span>
                  <span>{capitalizeRemovedash(webhookData?.type || 'N/A')}</span>
                </p>
              </div>
            </div>
            <div className="webhook-payload">
              <p className="webhook-attempt" aria-label="payload">
                Payload
              </p>
              <div className="payload-codeblock">
                <div className="payload-codeblock-heading">
                  <div className="d-flex payload-codeblock-header justify-content-between">
                    <p className="m-0">JSON</p>
                    <div
                      className="d-flex payload-copy-section align-items-center"
                      onClick={() => {
                        copyToClipboard(JSON.stringify(webhookData?.request_payload));
                        (function _() {
                          setCopied(true);
                          setTimeout(() => {
                            setCopied(false);
                          }, 800);
                        })();
                      }}
                    >
                      {copied ? (
                        <p className="m-0">Copied!</p>
                      ) : (
                        <>
                          <img id="copy-icon" src={copyIcon} alt="" />
                          <p className="m-0">Copy Code</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="code-block">{webhookData?.request_payload && renderStyledJson(webhookData?.request_payload)}</div>
              </div>
            </div>
          </div>
        ) : (
          <p className="no-refund justify-content-center">
            <i>No Webhook generated for this transaction</i>
          </p>
        )}
      </section>
      {confirmModal && (
        <Modal
          size="md"
          close={() => setConfirmModal(false)}
          heading="Confirm Request"
          description={
            <p style={{ color: '#414F5F', fontWeight: 400, display: 'block' }}>
              Are you sure you want to resend webhook for{' '}
              <strong>
                {(transactionType === 'pay-ins' && paymentReference?.toUpperCase()) || ('payout' && reference?.toUpperCase())}
              </strong>
              ?
            </p>
          }
          justify="space-between"
          firstButtonText="Cancel"
          firstButtonAction={() => setConfirmModal(false)}
          secondButtonText="Confirm"
          secondButtonAction={async () => {
            setLoading(true);
            const webhookDetails = {
              notification_type: (transactionType === 'pay-ins' && 'payin') || 'payout',
              transaction_reference: (transactionType === 'pay-ins' && reference) || ('payout' && uniqueReference),
              notification_reference: (transactionType === 'payouts' && reference) || ('pay-ins' && paymentReference)
            };
            await resendWebhooks.mutateAsync(webhookDetails);
          }}
          secondButtonDisable={loading}
          completedHeading="Success!"
          completedDescription={`We've received your ‘webhook resend’ request and it's currently being processed.`}
        />
      )}
    </>
  );
}
