import { FieldsListType, FieldType } from '+dashboard/Shared/FilterModal';
import { ChargebackStatusType } from '+types/transaction-types';

const holderNameValidation = (name = '') => {
  const namesCount = name.trim().split(' ')?.length;
  if (namesCount < 2) {
    return 'Please enter both first name and last name';
  }
  if (namesCount > 2) {
    return 'Please enter only first name and last name';
  }
  return '';
};

const cardNumEndingValidation = num => {
  if (`${num}`.length < 4) {
    return 'Please enter a 4-digit number';
  }
  if (!`${num}`.match(/^[0-9]+$/)) {
    return 'Please enter a valid 4-digit number';
  }
};

export const chargebackTableStatusConfig = {
  pending: { className: 'warn', title: 'Pending' },
  processing: { className: 'processed', title: 'Processing' },
  accepted: { className: 'success', title: 'Accepted' },
  partially_accepted: { className: 'partial-success', title: 'Accepted (Partially)' },
  invalid: { className: 'invalid', title: 'Invalid' },
  declined: { className: 'failure', title: 'Declined' },
  pending_pre_arbitration: { className: 'pending', title: 'Pending (Pre-Arb)' },
  processing_pre_arbitration: { className: 'processed', title: 'Processing (Pre-Arb)' },
  accepted_pre_arbitration: { className: 'success', title: 'Accepted (Pre-Arb)' },
  partially_accepted_pre_arbitration: { className: 'partial-success', title: 'Accepted (Partially Pre-Arb)' },
  invalid_pre_arbitration: { className: 'invalid', title: 'Invalid (Pre-Arb)' },
  declined_pre_arbitration: { className: 'failure', title: 'Declined (Pre-Arb)' },
  delayed_pre_arbitration: { className: 'processed', title: 'Delayed (Pre-Arb)' },
  accepted_arbitration: { className: 'success', title: 'Accepted (Arb)' },
  declined_arbitration: { className: 'failure', title: 'Declined (Arb)' }
};

const commonFilterFields: Record<string, FieldType> = {
  cardHolderName: {
    label: "Cardholder's Name",
    key: 'cardHolderName',
    type: 'text-input',
    inputProps: {
      placeholder: 'FirstName LastName',
      maxLength: 50
    },
    validate: holderNameValidation,
    infoText: 'For names with prefixes, use the Keywords filter'
  },
  dateCreated: {
    label: 'Created At',
    key: 'dateCreated',
    type: 'date-range',
    options: [
      {
        value: 'dateCreatedFrom',
        label: 'From'
      },
      {
        value: 'dateCreatedTo',
        label: 'To'
      }
    ],
    applyMaxDate: true,
    applyMinDate: true
  }
};

export const cardsFilterFields: FieldsListType = [
  {
    label: 'Status',
    key: 'status',
    type: 'checkboxes',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Suspended', value: 'suspended' },
      { label: 'Expired', value: 'expired' },
      { label: 'Terminated', value: 'terminated' }
    ]
  },
  {
    label: 'Card ID',
    key: 'cardId',
    type: 'text-input',
    inputProps: {
      placeholder: 'E.g, kpw_p0g8uS...',
      maxLength: 50
    }
  },
  {
    label: 'Card Brand',
    key: 'cardBrand',
    type: 'select',
    options: [
      { label: '--Select card type--', value: '' },
      { label: 'Mastercard', value: 'mastercard' },
      { label: 'Visacard', value: 'visa' }
    ]
  },
  commonFilterFields.cardHolderName,
  commonFilterFields.dateCreated,
  {
    label: 'Card Expiry Date',
    key: 'cardExpiryDate',
    type: 'date-range',
    options: [
      {
        value: 'cardExpiryDateFrom',
        label: 'From'
      },
      {
        value: 'cardExpiryDateTo',
        label: 'To'
      }
    ],
    datePickerProps: {
      showMonthYearPicker: true,
      dateFormat: 'MM-yyyy'
    }
  },
  {
    label: 'Keyword(s)',
    key: 'keyword',
    type: 'text-input',
    inputProps: { placeholder: 'Search anything...' },
    infoText: 'Keywords can include transaction description, Balance...'
  }
];

export const transactionsFilterFields: FieldsListType = [
  {
    label: 'Status',
    key: 'status',
    type: 'checkboxes',
    options: [
      { label: 'Success', value: 'success' },
      { label: 'Processing', value: 'processing' },
      { label: 'Failed', value: 'failed' },
      { label: 'Pending', value: 'pending' },
      { label: 'Declined', value: 'declined' },
      { label: 'Reversed', value: 'reversed' }
    ]
  },
  {
    label: 'Transaction ID',
    key: 'transactionId',
    type: 'text-input',
    inputProps: {
      placeholder: 'E.g, kpw_p0g8uS...',
      maxLength: 50
    }
  },
  commonFilterFields.cardHolderName,
  {
    label: 'Card Number Ending',
    key: 'cardNumberEnding',
    type: 'text-input',
    inputProps: {
      placeholder: 'E.g, 1101',
      maxLength: 4,
      type: 'number'
    },
    validate: cardNumEndingValidation
  },
  {
    label: 'Transaction Type',
    key: 'type',
    type: 'select',
    options: [
      { label: '--Select transaction type--', value: '' },
      { label: 'Card Creation', value: 'card_creation' },
      { label: 'Card Funding', value: 'card_funding' },
      { label: 'Card Transaction', value: 'card_transaction' },
      { label: 'Chargeback', value: 'chargeback' },
      { label: 'Refund', value: 'refund' }
    ]
  },
  commonFilterFields.dateCreated,
  {
    label: 'Amount (USD)',
    key: 'amount',
    type: 'selection-based-input',
    selectionKey: 'amountFilterOption',
    options: [
      { value: 'equalTo', label: 'Exactly' },
      { value: 'greaterThan', label: 'Greater than' },
      { value: 'lessThan', label: 'Less than' }
    ],
    inputProps: { placeholder: '00.00', type: 'number' },
    applyAmountFormat: true
  },
  {
    label: 'Keyword(s)',
    key: 'keyword',
    type: 'text-input',
    inputProps: { placeholder: 'Search anything...', maxLength: 50 },
    infoText: 'Keywords can include transaction description, Balance...'
  }
];

export const singleCardTxFilterFields: FieldsListType = [
  {
    label: 'Status',
    key: 'status',
    type: 'checkboxes',
    options: [
      { label: 'Success', value: 'success' },
      { label: 'Processing', value: 'processing' },
      { label: 'Failed', value: 'failed' },
      { label: 'Pending', value: 'pending' },
      { label: 'Declined', value: 'declined' },
      { label: 'Reversed', value: 'reversed' }
    ]
  },
  {
    label: 'Transaction ID',
    key: 'transactionId',
    type: 'text-input',
    inputProps: {
      placeholder: 'E.g, kpw_p0g8uS...',
      maxLength: 50
    }
  },
  {
    label: 'Transaction Type',
    key: 'type',
    type: 'select',
    options: [
      { label: '--Select transaction type--', value: '' },
      { label: 'Card Creation', value: 'card_creation' },
      { label: 'Card Funding', value: 'card_funding' },
      { label: 'Card Transaction', value: 'card_transaction' },
      { label: 'Chargeback', value: 'chargeback' },
      { label: 'Refund', value: 'refund' }
    ]
  },
  commonFilterFields.dateCreated,
  {
    label: 'Amount (USD)',
    key: 'amount',
    type: 'selection-based-input',
    selectionKey: 'amountFilterOption',
    options: [
      { value: 'equalTo', label: 'Exactly' },
      { value: 'greaterThan', label: 'Greater than' },
      { value: 'lessThan', label: 'Less than' }
    ],
    inputProps: { placeholder: '00.00', type: 'number' },
    applyAmountFormat: true
  },
  {
    label: 'Keyword(s)',
    key: 'keyword',
    type: 'text-input',
    inputProps: { placeholder: 'Search anything...', maxLength: 50 },
    infoText: 'Keywords can include transaction description, Balance...'
  }
];

export const balanceHistoryFilterFields: FieldsListType = [
  {
    label: 'Unique Reference (Transaction ID)',
    key: 'transaction_reference',
    type: 'text-input',
    inputProps: {
      placeholder: 'E.g, kpw_p0g8uS...',
      maxLength: 50
    }
  },
  {
    label: 'Created At',
    key: 'dateCreated',
    type: 'date-range',
    options: [
      {
        value: 'date_from',
        label: 'From'
      },
      {
        value: 'date_to',
        label: 'To'
      }
    ],
    applyMaxDate: true,
    applyMinDate: true
  },
  {
    label: 'Amount (USD)',
    key: 'amount',
    type: 'selection-based-input',
    selectionKey: 'amountSubfilter',
    options: [
      { value: 'is_equal_to', label: 'Exactly' },
      { value: 'is_greater_than', label: 'Greater than' },
      { value: 'is_less_than', label: 'Less than' }
    ],
    inputProps: { placeholder: '00.00', type: 'number' },
    applyAmountFormat: true
  },
  {
    label: 'Keyword(s)',
    key: 'keyword',
    type: 'text-input',
    inputProps: { placeholder: 'Search anything...', maxLength: 50 }
  }
];

export const issuedCardChargebacksFilterField: FieldsListType = [
  {
    label: 'Status',
    key: 'status',
    type: 'checkboxes',
    options: [
      { label: 'Accepted', value: 'accepted' },
      { label: 'Declined', value: 'declined' },
      { label: 'Processing', value: 'processing' },
      { label: 'Pending', value: 'pending' },
      { label: 'Invalid', value: 'invalid' },
      { label: 'Pre-Arb Pending', value: 'pending_pre_arbitration' },
      { label: 'Pre-Arb Processing', value: 'processing_pre_arbitration' },
      { label: 'Pre-Arb Invalid', value: 'invalid_pre_arbitration' },
      { label: 'Pre-Arb Accepted', value: 'accepted_pre_arbitration' },
      { label: 'Pre-Arb Declined', value: 'declined_pre_arbitration' }
    ] as Array<{ label: string; value: ChargebackStatusType }>,
    infoText: '*Pre-Arb means Pre-Arbitration'
  },
  {
    label: 'Chargeback ID',
    key: 'chargebackId',
    type: 'text-input',
    inputProps: {
      placeholder: 'E.g, kpy-chb-4pu...',
      maxLength: 50
    }
  },
  {
    label: 'Amount (USD)',
    key: 'amount',
    type: 'selection-based-input',
    selectionKey: 'amountFilterOption',
    options: [
      { value: 'equalTo', label: 'Exactly' },
      { value: 'greaterThan', label: 'Greater than' },
      { value: 'lessThan', label: 'Less than' }
    ],
    inputProps: { placeholder: '00.00', type: 'number' },
    applyAmountFormat: true
  },
  {
    label: 'Escalated At',
    key: 'escalationDate',
    type: 'date-range',
    options: [
      {
        value: 'dateCreatedFrom',
        label: 'From'
      },
      {
        value: 'dateCreatedTo',
        label: 'To'
      }
    ],
    applyMaxDate: true,
    applyMinDate: true
  },
  commonFilterFields.cardHolderName,
  {
    label: "Purchasing Merchant's Name",
    key: 'cardAcceptorName',
    type: 'text-input',
    inputProps: {
      placeholder: 'First Name, Last Name',
      maxLength: 50
    },
    validate: holderNameValidation,
    infoText: 'For names with prefixes, use the Keywords filter'
  },
  {
    label: 'Card Number Ending',
    key: 'cardNumberEnding',
    type: 'text-input',
    inputProps: {
      placeholder: 'E.g, 1101',
      maxLength: 4,
      type: 'number'
    },
    validate: cardNumEndingValidation
  },
  {
    label: 'Transaction Type',
    key: 'transactionType',
    type: 'select',
    options: [
      { label: '--Select transaction type--', value: '' },
      { label: 'Card Transaction', value: 'card_transaction' }
    ]
  },
  {
    label: 'Keyword(s)',
    key: 'keyword',
    type: 'text-input',
    inputProps: { placeholder: 'Search anything...' },
    infoText: 'Keywords can include transaction description, Balance...'
  }
];

export const defaultPagination = {
  page: 1,
  limit: 10,
  sorterType: null,
  sortingParams: {}
};

export const chargebacksTrxnMessage = {
  pending: { name: 'Chargeback Pending', color: '#FA9500', backgroundColor: '#FFF8E1' },
  processing: { name: 'Chargeback Processing', color: '#FA9500', backgroundColor: '#FFF8E1' },
  accepted: { name: 'Chargeback Accepted', color: '#24B314', backgroundColor: '#E4FFF1' },
  partially_accepted: { name: 'Chargeback Accepted (Partially)', color: '#48CEB0', backgroundColor: '#E4FFF1' },
  invalid: { name: 'Chargeback Invalid', color: '#94A7B7', backgroundColor: '#F3F4F8' },
  declined: { name: 'Chargeback Declined', color: '#F32345', backgroundColor: '#FFD2DA' },
  pending_pre_arbitration: { name: 'Pre-arbitration Pending', color: '#FFB447', backgroundColor: '#FFF8E1' },
  processing_pre_arbitration: { name: 'Pre-arbitration Processing ', color: '#FA9500', backgroundColor: '#FFF8E1' },
  accepted_pre_arbitration: { name: 'Pre-arbitration Accepted', color: '#24B314', backgroundColor: '#E4FFF1' },
  partially_accepted_pre_arbitration: { name: 'Pre-arbitration Accepted (Partially)', color: '#24B314', backgroundColor: '#E4FFF1' },
  delayed_pre_arbitration: { name: 'Pre-arbitration Delayed', color: '#F32345', backgroundColor: '#FFD2DA' },
  invalid_pre_arbitration: { name: 'Pre-arbitration Invalid', color: '#94A7B7', backgroundColor: '#F3F4F8' },
  declined_pre_arbitration: { name: 'Pre-arbitration Declined', color: '#F32345', backgroundColor: '#FFD2DA' },
  accepted_arbitration: { name: 'Arbitration Accepted', color: '#F32345', backgroundColor: '#FFD2DA' },
  declined_arbitration: { name: 'Arbitration Declined', color: '#F32345', backgroundColor: '#FFD2DA' }
};

export const mapCards = {
  customerCards: 'customer',
  reservedCards: 'reserved'
};
