export const balanceSubtabsTableProps = {
  issuing_balance_history: {
    headings: [{ value: 'Date / Time' }, { value: 'Details' }, { value: 'Amount (USD)' }, { value: 'Balance After (USD)' }],
    emptyStateHeading: 'No transactions found',
    emptyStateMessage: 'It seems there are no transactions here.',
    tableClassName: 'balance-history-table'
  },
  funding_deposits: {
    headings: [{ value: 'Status' }, { value: 'Details' }, { value: 'Date / Time' }, { value: 'Amount (USD)' }],
    emptyStateHeading: 'No transactions found',
    emptyStateMessage: 'It seems there are no transactions here.',
    tableClassName: 'balance-history-table'
  }
};

export const customerCardsTableProps = {
  headings: [
    { value: 'Status' },
    { value: 'Unique Card ID' },
    { value: 'Cardholder' },
    { value: 'Card Number' },
    { value: 'Created at' },
    { value: 'Currency' }
  ],
  emptyStateHeading: 'No cards found',
  emptyStateMessage: 'It seems there are no cards issued.',
  tableClassName: 'issued-cards-table'
};

export const reservedCardsTableProps = {
  headings: [
    { value: 'Status' },
    { value: 'Unique Card ID' },
    { value: 'Cardholder' },
    { value: 'Card label' },
    { value: 'Card number' },
    { value: 'Date created' }
  ],
  emptyStateHeading: 'No reserved cards',
  emptyStateMessage: 'It seems there are no reserved virtual cards.',
  tableClassName: 'cards-tx-table',
  tableActions: ['export']
};

export const cardTransactionsTableProps = {
  headings: [
    { value: 'Status' },
    { value: 'Transaction ID' },
    { value: 'Type' },
    { value: 'Cardholder' },
    { value: 'Date / Time' },
    { value: 'Amount' }
  ],
  emptyStateHeading: 'No transactions found',
  emptyStateMessage: 'It seems there are no transactions.',
  tableClassName: 'cards-tx-table'
};

export const chargebacksTableProps = {
  headings: [
    { value: 'Status' },
    { value: 'Chargeback ID' },
    { value: 'Vendor' },
    { value: 'Cardholder' },
    { value: 'Date Escalated' },
    { value: 'Chargeback Amount' }
  ],
  emptyStateHeading: 'No transactions found',
  emptyStateMessage: 'It seems there are no transactions.',
  tableClassName: 'issuance-chgbk-table'
};
