import React, { useState } from 'react';
import { useMutation } from 'react-query';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import useFeedbackHandler from '+hooks/feedbackHandler';
import useMerchantAuth from '+hooks/useMerchantAuth';
import APIRequest from '+services/api-services';
import { history, logError } from '+utils';

import DownloadRecoveryCodes from './DownloadRecoveryCodes';

interface IDataShape {
  key: string;
  qrImage: string;
}

interface INewTwoFactorComponentProps {
  data: {
    data: IDataShape;
  };
  onInitiate: () => void;
  setCanCopyRecoveryCode: React.Dispatch<React.SetStateAction<boolean>>;
}

const authBaseURL = process.env.REACT_APP_AUTH_API_BASE || 'http://localhost:3000';
const authAPIRequest = new APIRequest(authBaseURL);

const NewTwoFactorComponent = ({ data, onInitiate, setCanCopyRecoveryCode }: INewTwoFactorComponentProps) => {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasDownload, setHasDownload] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState(null);
  const { bootstrap, getProductAccess } = useMerchantAuth();

  const fetchTwoFAHistory = async () => {
    try {
      history.push('/dashboard/settings/security');
    } catch (error) {
      logError(error);
    }
  };

  const activatetwoFA = useMutation(() => authAPIRequest.activatetwoFA({ code }), {
    onSuccess: async codes => {
      setCanCopyRecoveryCode(true);
      await bootstrap();
      await getProductAccess();
      setRecoveryCodes(codes?.data?.recovery_codes);
      setCode('');
      setLoading(false);
      feedbackInit({
        message: `You have successfully setup two-factor authentication for your account. Kindly download your recovery codes`,
        type: 'success'
      });
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    },
    onError: err => {
      const error = err?.response?.data;
      setCode('');
      logError(err);
      setLoading(false);
      if (error?.message && error.message.includes('authorization code is invalid')) {
        return feedbackInit({
          message: 'Invalid authentication code. Please try again',
          type: 'danger'
        });
      }
      return feedbackInit({
        message: 'There has been an error setting up two-factor authentication for your account',
        type: 'danger'
      });
    }
  });

  return !recoveryCodes ? (
    <>
      <h5 className="form-header">Two-Factor Authentication</h5>
      <div className="form-desc" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1 )' }}>
        Two-factor authentication (2FA) provides an extra layer of security that reconfirms your identity <br /> on bvnkng. Every time you
        sign in or perform critical actions on your dashboard, you will be <br /> required to enter a unique single-use authentication code
        to confirm your identity.
      </div>
      {data ? (
        <>
          <div className="mb-4">
            <div className="col-sm-6">
              <div className="logo-section">
                <div>
                  <div className="element-box-tp">
                    <div className="profile-tile qr__container">
                      <div className="profile-tile-box">
                        <div className="pt-avatar-w">
                          <img alt="QrCode" className="qr-img" src={data?.data?.qrImage} />
                        </div>
                      </div>
                      <div className="profile-tile-meta col-sm-6 col-md-10">
                        <ul className="two-factor-content">
                          <li>
                            Scan this QR code with the <br /> authentication app on your trusted <br /> device.
                          </li>
                          <li className="mt-2">Or copy this code into the app:</li>
                          <li className="mt-2" style={{ fontWeight: '500' }}>
                            <Copyable text={data?.data?.key} buttonClassName="copy-section" showCopyText />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <fieldset className="form-group">
                <div className="form-group">
                  <label htmlFor="twofa-code" style={{ fontSize: '1rem' }}>
                    Verification Code
                  </label>
                  <input
                    name="twofa-code"
                    className="form-control"
                    placeholder="Enter code generated by your authenticator app..."
                    type="tel"
                    maxLength="6"
                    onChange={e => setCode(e.target.value)}
                  />
                </div>
              </fieldset>
            </div>
          </div>

          <div className="button-row">
            <button
              className="btn"
              type="button"
              disabled={!code || code.length < 6 || loading}
              style={{ borderColor: '#7447fd', color: 'white', backgroundColor: '#7447fd', width: 'auto' }}
              onClick={() => {
                setLoading(true);
                activatetwoFA.mutate({ code });
              }}
            >
              {loading ? (
                <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
              ) : null}
              {loading ? 'Authorizing...' : 'Authorize'}
            </button>

            <button className="btn btn-white" type="button" onClick={() => history.push('/dashboard/settings/security')}>
              Cancel
            </button>
          </div>
        </>
      ) : (
        <div className="twofa-initiate-section">
          <h5 className="form-header">Set up 2FA via an authentication app</h5>
          <div className="form-desc">
            You will need an authentication app to generate a <br /> passcode to complete this process.
          </div>

          <div className="button-row">
            <button
              className="btn"
              type="button"
              style={{ borderColor: '#24B314', color: 'white', backgroundColor: '#24B314', width: 'auto' }}
              onClick={onInitiate}
            >
              Start
            </button>
          </div>
        </div>
      )}
    </>
  ) : (
    <DownloadRecoveryCodes
      recoveryCodes={recoveryCodes}
      setHasDownload={setHasDownload}
      hasDownload={hasDownload}
      fetchTwoFAHistory={fetchTwoFAHistory}
    />
  );
};

export default React.memo(NewTwoFactorComponent);
