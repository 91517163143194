import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';

import { queryClient, storeExportLink } from '+utils';

import SignInForm from './components';
import Confirm2FAForm from './components/Confirm2FAForm';

import ImgbvnkngLogo from '+assets/img/logos/logo-bvnkng-ent.png';
import ImgbvnkngLogoSvg from '+assets/img/logos/logo-bvnkng-ent.svg';

function SigninComponent() {
  const [twoFARequired, setTwoFARequired] = useState(false);
  const [twoFAParams, setTwoFAParams] = useState({});
  const [email, setEmail] = useState('');
  const location = useLocation();

  const handleTwoFactorAuthRequired = (params, emailAddress) => {
    setEmail(emailAddress);
    setTwoFARequired(true);
    setTwoFAParams(params);
  };

  const resetTwoFARequiredAct = () => {
    setTwoFARequired(false);
  };

  useEffect(() => {
    queryClient.setQueryData('isWebBanner', true);
  });

  useEffect(() => {
    storeExportLink(location);
  }, []);

  return (
    <>
      <Helmet title="Login" />
      {twoFARequired ? (
        <main className="auth__main">
          <div className="auth__header">
            {/* <div className="logo-container">
              <Link to="/">
                <span className="sr-only">Logo</span>
                <img alt="bvnkng Logo" src={ImgbvnkngLogo} srcSet={ImgbvnkngLogoSvg} />
              </Link>
            </div> */}
          </div>
          <div className="auth__content">
            <Confirm2FAForm twoFAParams={twoFAParams} resetTwoFARequired={resetTwoFARequiredAct} email={email} />
          </div>
        </main>
      ) : (
        <main className="auth__main">
          <div className="auth__header">
            <div className="logo-container">
              {/* <Link to="/">
                <img alt="bvnkng Logo" src={ImgbvnkngLogo} />
              </Link> */}
            </div>
          </div>
          <div className="headings">
            <h2>Log in to your account</h2>
          </div>
          <div className="auth__content">
            <SignInForm initIfTwoFactorRequired={handleTwoFactorAuthRequired} />
          </div>
        </main>
      )}
    </>
  );
}

export default SigninComponent;
