import { IRoleDescription } from '+types';

const roleDescriptions: Array<IRoleDescription> = [
  {
    role: 'Admin',
    info: 'This role is best for team members that need similar access to the owner. They can view and manage everything on the account.',
    permissions: [
      { page: 'Balances', action: 'View and Manage' },
      { page: 'Payment Links', action: 'View and Manage' },
      { page: 'Virtual Accounts', action: 'View Only' },
      { page: 'Pay-ins', action: 'View and Export' },
      { page: 'Payouts', action: 'View and Export' },
      { page: 'Settlements', action: 'View and Manage' },
      { page: 'Disputes', action: 'View and Manage' },
      { page: 'Audit Logs', action: 'View Only' },
      { page: 'Compliance Settings', action: 'View and Manage' },
      { page: 'Team Settings', action: 'View and Manage' },
      { page: 'Settlement Settings', action: 'View and Manage' },
      { page: 'API Configurations', action: 'View and Manage' },
      { page: 'Security Settings', action: 'View and Manage' },
      { page: 'Card Issuance', action: 'View and Manage' }
    ]
  },
  {
    role: 'Operations',
    info:
      'This role is best for team members who need access to reports, information and functionalities necessary for them to carry out reconciliation, settlement, and cash flow monitoring activities.',
    permissions: [
      { page: 'Balances', action: 'View and Export' },
      { page: 'Payment Links', action: 'No Access' },
      { page: 'Virtual Accounts', action: 'View Only' },
      { page: 'Pay-ins', action: 'View and Export' },
      { page: 'Payouts', action: 'View and Export' },
      { page: 'Settlements', action: 'View and Export' },
      { page: 'Disputes', action: 'View and Manage' },
      { page: 'Audit Logs', action: 'No Access' },
      { page: 'Compliance Settings', action: 'No Access' },
      { page: 'Team Settings', action: 'View Only' },
      { page: 'Settlement Settings', action: 'No Access' },
      { page: 'API Configurations', action: 'No Access' },
      { page: 'Security Settings', action: 'View and Manage' },
      { page: 'Card Issuance', action: 'View and Manage' }
    ]
  },
  {
    role: 'Developer',
    info: 'This role is best for developers to get access to the API keys, and testing integrations.',
    permissions: [
      { page: 'Balances', action: 'No Access' },
      { page: 'Payment Links', action: 'No Access' },
      { page: 'Virtual Accounts', action: 'View Only' },
      { page: 'Pay-ins', action: 'View Only' },
      { page: 'Payouts', action: 'View Only' },
      { page: 'Settlements', action: 'No Access' },
      { page: 'Disputes', action: 'No Access' },
      { page: 'Audit Logs', action: 'No Access' },
      { page: 'Compliance Settings', action: 'No Access' },
      { page: 'Team Settings', action: 'View Only' },
      { page: 'Settlement Settings', action: 'No Access' },
      { page: 'API Configurations', action: 'View and Manage' },
      { page: 'Security Settings', action: 'View and Manage' },
      { page: 'Card Issuance', action: 'View Only' }
    ]
  },
  {
    role: 'Support',
    info: 'This role is best for support team members to perform functions around issue resolution and to get transaction insights.',
    permissions: [
      { page: 'Balances', action: 'No Access' },
      { page: 'Payment Links', action: 'No Access' },
      { page: 'Virtual Accounts', action: 'View Only' },
      { page: 'Pay-ins', action: 'View and Export' },
      { page: 'Payouts', action: 'View and Export' },
      { page: 'Settlements', action: 'View and Export' },
      { page: 'Disputes', action: 'View Only' },
      { page: 'Audit Logs', action: 'No Access' },
      { page: 'Compliance Settings', action: 'No Access' },
      { page: 'Team Settings', action: 'View Only' },
      { page: 'Settlement Settings', action: 'No Access' },
      { page: 'API Configurations', action: 'No Access' },
      { page: 'Security Settings', action: 'View and Manage' },
      { page: 'Card Issuance', action: 'View and Export' }
    ]
  }
];

export default roleDescriptions;
