import React from 'react';
import Modal from '+containers/Dashboard/Shared/Modal';
import useStore from '+store';

function EmailConfigurationModal() {
  const state = useStore(state => state);

  return state.showEmailConfigModal ? (
    <Modal
      visible={state.showEmailConfigModal}
      modalStage={state.modalStage}
      heading="You Have Unsaved Changes"
      completedHeading="Success!"
      description="Would you like to save your edits before performing another task? Unsaved progress will be lost."
      completedDescription="Your email notification settings have been updated successfully."
      showImage
      size="sm"
      maxHeight="sm"
      showButtons={state.modalStage === 'init'}
      headerClassName="onsave-title"
      firstButtonText="Discard"
      secondButtonText="Save Changes"
      hideCompletedAction
      close={() => state.setShowEmailConfigModal(false)}
      secondButtonAction={state.saveFn}
      secondButtonActionIsTerminal
    />
  ) : null;
}

export default EmailConfigurationModal;
