/* eslint-disable react/forbid-prop-types */
import { capitalize, capitalizeRemovedash, formatAmount, getDate, getTime, history, logBreadCrumb, switchStatus } from '+utils';
import { breadCrumbEvents } from '+utils/bugsnag-events';

import Table from './Table';
import ToolTip from './Tooltip';

import InfoIcon from '+assets/img/dashboard/information-button.svg';
import OverpaymentSVG from '+assets/img/dashboard/overpayment.svg';
import UnderpaymentSVG from '+assets/img/dashboard/underpayment.svg';

import './HistoryTable.scss';

interface IHistoryTableComponentProps {
  type: string;
  data: any[];
  paging: Record<string, any>;
  pagingAction: () => void;
  limitAction: () => void;
  savedState: any;
  isFetching: boolean;
  emptyStateMessage: string;
  emptyStateHeading: string;
}

const HistoryTableComponent = ({
  type,
  data,
  paging,
  pagingAction,
  limitAction,
  savedState,
  isFetching,
  emptyStateMessage,
  emptyStateHeading
}: IHistoryTableComponentProps) => {
  const refundSwitch = {
    1: 'a refund',
    2: 'a chargeback',
    3: 'a refund and a chargeback',
    4: 'a reversal',
    5: 'a refund and a reversal',
    6: 'a chargeback and a reversal',
    7: 'a refund, a chargeback and a reversal',
    8: 'an underpayment'
  };
  const allowed = [1, 2, 3, 4, 5, 6, 7, 8];

  const LinkType = type => (type === 'pay-in' ? 'pay-ins' : 'payouts');

  const Transactions = () => {
    return (
      <div>
        {data?.map(each => (
          <div
            key={`transaction_${each.reference}`}
            className="div-table --history-table --row"
            onClick={() => {
              history.push(`/dashboard/${LinkType(type)}/${each.reference}`, null, {
                type: 'history',
                data: { ...savedState }
              });
              logBreadCrumb({
                event: breadCrumbEvents.paymentLinkClicked.tableClicked(LinkType(type)),
                data: { reference: each?.reference }
              });
            }}
            onKeyDown={() => {
              history.push(`/dashboard/${LinkType(type)}/${each.reference}`, null, {
                type: 'history',
                data: { ...savedState }
              });
              logBreadCrumb({
                event: breadCrumbEvents.paymentLinkClicked.tableClicked(LinkType(type)),
                data: { reference: each?.reference }
              });
            }}
            role="button"
            tabIndex={0}
          >
            <div className="--txn-status" style={{ display: 'flex', alignItems: 'center' }}>
              <span className="body-row-header">Status:</span>
              <span
                className={`status-pill smaller ${switchStatus(each.status === 'requires_auth' ? 'pending' : each.status)}`}
                style={{ marginTop: '6px' }}
              />
              <span>{capitalizeRemovedash(each.status === 'requires_auth' ? 'pending' : each.status)}</span>
              {each.meta?.payment_event_action?.event && (
                <span style={{ marginLeft: '10px' }}>
                  <img src={each?.meta?.payment_event_action?.event === 'overpayment' ? OverpaymentSVG : UnderpaymentSVG} alt="" />
                </span>
              )}
              {allowed.includes(each.payment_reversals_type) && (
                <ToolTip
                  classname="payins-table"
                  type="refunds_and_cashbacks"
                  image={InfoIcon}
                  message={<p>This transaction has {refundSwitch[each.payment_reversals_type]}</p>}
                />
              )}
            </div>
            <div>
              <span className="body-row-header">Transaction ID:</span>
              <span style={{ color: '#7447fd', fontWeight: '500', textTransform: 'uppercase' }}>
                {type === 'pay-in' ? each.payment.reference : each.reference}
              </span>
            </div>
            <div>
              <span className="body-row-header">Customer:</span>
              {!each.payment?.customer?.name ? (
                <span>{each.payment?.customer?.email}</span>
              ) : (
                <span>{capitalize(each.payment?.customer?.name)}</span>
              )}
            </div>
            <div>
              <span className="body-row-header">Date/Time:</span>
              <span>
                {getDate(each.transaction_date)}
                <span className="annotation" style={{ marginLeft: '5px' }}>
                  {getTime(each.transaction_date)}
                </span>
              </span>
            </div>
            <div>
              <span className="body-row-header">{type === 'pay-in' ? 'Amount Charged:' : 'Amount'}</span>
              <span style={{ fontWeight: '500' }}>
                {type === 'pay-in' ? <strong>{formatAmount(each.amount_charged)}</strong> : <strong>{formatAmount(each.amount)}</strong>}
                <span className="annotation" style={{ marginLeft: '5px' }}>
                  {each.currency}
                </span>
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <section className="transaction_table_comp">
      <Table
        tableClassName="--history-table"
        headings={[
          {
            value: 'Status'
          },
          {
            value: 'Transaction ID'
          },
          {
            value: 'Customer'
          },
          {
            value: 'Date/Time'
          },
          {
            value: type === 'pay-in' ? 'Amount Charged' : 'Amount'
          }
        ]}
        hasPagination
        loading={isFetching}
        current={paging?.current}
        pagingTotalItems={paging?.total_items}
        limitAction={limitAction}
        pageSize={paging?.page_size}
        actionFn={pagingAction}
        totalItems={paging?.total_items}
        emptyStateHeading={emptyStateHeading}
        emptyStateMessage={emptyStateMessage}
        children={Transactions()}
      />
    </section>
  );
};

export default HistoryTableComponent;
