import Modal from '../../../Shared/Modal';
import DetailsAccordion from './DetailsAccordion';
import './index.scss';

interface IDetailsModalProps {
  showModal?: boolean;
  close: () => void;
}

const DetailsModal = ({
  showModal = false,
  close
}: IDetailsModalProps) => {
  const modalContent = () => <DetailsAccordion />;

  return (
    <Modal
      size="lg"
      close={close}
      visible={showModal}
      heading="Roles and Permissions"
      description="Each role has access to its own unique set of permissions neccesary for the role."
      content={modalContent()}
      showButtons={false}
      largeModal={showModal}
    />
  );
};

export default DetailsModal;
