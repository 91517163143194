import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import Icon from '+containers/Dashboard/Shared/Icons';
import { useReducerState } from '+hooks';
import { cleanInput, filterOutEmptyValues } from '+utils';

import { parseQueryParams } from '../../Shared/FilterModal';

import calendar from '+assets/img/dashboard/calendar.svg';

import './index.scss';
import 'react-datepicker/dist/react-datepicker.css';

const eventsStatusOptions = {
  found: 'Found',
  not_found: 'Not found',
  pending: 'Pending',
  failed: 'Failed'
};

const billingTypeOptions = {
  kyb: 'KYB',
  kyc: 'KYC'
};

export function IdentityFilter({
  type = '',
  handleFilterQuery = () => {},
  setNonFilterParams
}: {
  type?: string;
  handleFilterQuery?: () => void;
  setNonFilterParams: any;
}) {
  const location = useLocation();

  const [filterItems, setFilterItems] = useReducerState({
    status: [],
    verificationType: [],
    keyword: '',
    startDate: '',
    endDate: ''
  });

  useEffect(() => {
    const { status = [], type = [], keyword = '', startDate = '', endDate = '' } = parseQueryParams();

    const values = {
      status,
      type,
      keyword,
      startDate,
      endDate
    };
    setFilterItems(values);
  }, [location.search]);

  const extractNonFilterParams = () => {
    const queryParams = parseQueryParams();
    const nonFilterParam = 'tab';
    const { [nonFilterParam]: nonFilterValue, ...others } = queryParams;
    setNonFilterParams({ [nonFilterParam]: nonFilterValue });
  };

  useEffect(() => {
    extractNonFilterParams();
  }, [window.location.search]);

  switch (type) {
    case 'verification-events':
      return (
        <section className="settlement-filter">
          <div className="settlement-filter__search-w filter-section">
            <div className="element-search-content filter-body w-100">
              <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                <select
                  name="type"
                  className="form-control"
                  onChange={e => setFilterItems({ status: e.target.value ? [e.target.value] : [] })}
                  value={filterItems?.status?.[0] || ''}
                  style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                >
                  <option value="">Result</option>
                  {Object.keys(eventsStatusOptions || {}).map(value => (
                    <option key={value} value={value}>
                      {eventsStatusOptions[value as keyof typeof eventsStatusOptions]}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
                <Icon name="search" width={16} height={16} />
                <input
                  type="search"
                  className="form-control"
                  placeholder="Enter keyword(s)..."
                  value={filterItems.keyword}
                  onChange={e => setFilterItems({ keyword: cleanInput(e.target.value) })}
                  style={{ border: '1.5px solid #EAF2FE' }}
                />
              </div>

              <div
                className="form-group filter-object d-flex filter-object-sm w-auto"
                style={{
                  '--calendar-image': `url(${calendar})`,
                  minWidth: '300px'
                }}
              >
                <DatePicker
                  selected={filterItems.startDate && new Date(filterItems.startDate)}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  className="form-control date-select pl-4 date-picker"
                  onChange={date => setFilterItems({ startDate: dayjs(date).format('YYYY-MM-DD') })}
                  placeholderText="From"
                  calendarClassName="custom-datepicker"
                />
                <DatePicker
                  selected={filterItems.endDate && new Date(filterItems.endDate)}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  className="form-control date-select pl-4 date-picker"
                  minDate={filterItems.startDate && new Date(filterItems.startDate)}
                  onChange={date => setFilterItems({ endDate: dayjs(date).format('YYYY-MM-DD') })}
                  placeholderText="To"
                  calendarClassName="custom-datepicker"
                />
              </div>

              <button
                aria-label="filter transactions"
                type="button"
                className="settlement-filter__filter-button"
                onClick={() => handleFilterQuery(filterOutEmptyValues(filterItems))}
                aria-controls="handleFilter"
              >
                <Icon name="arrowRight" width={16} height={16} />
              </button>
            </div>
          </div>
        </section>
      );
    case 'billing-history':
      return (
        <section className="settlement-filter">
          <div className="settlement-filter__search-w filter-section">
            <div className="element-search-content filter-body w-100">
              <div className="form-group filter-object filter-object-ssm w-auto --no-max-width">
                <select
                  name="type"
                  className="form-control"
                  onChange={e => setFilterItems({ verificationType: e.target.value ? [e.target.value] : [] })}
                  value={filterItems?.verificationType?.[0] || ''}
                  style={{ border: '1.5px solid #EAF2FE', width: '150px' }}
                >
                  <option value="">Type</option>
                  {Object.keys(billingTypeOptions || {}).map(value => (
                    <option key={value} value={value}>
                      {billingTypeOptions[value as keyof typeof billingTypeOptions]}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group position-relative filter-object filter-object-xl flex-grow-1 w-auto --no-max-width --search-container">
                <Icon name="search" width={16} height={16} />
                <input
                  type="search"
                  className="form-control"
                  placeholder="Enter keyword(s)..."
                  value={filterItems.keyword}
                  onChange={e => setFilterItems({ keyword: cleanInput(e.target.value) })}
                  style={{ border: '1.5px solid #EAF2FE' }}
                />
              </div>

              <div
                className="form-group filter-object d-flex filter-object-sm w-auto"
                style={{
                  '--calendar-image': `url(${calendar})`,
                  minWidth: '300px'
                }}
              >
                <DatePicker
                  selected={filterItems.startDate && new Date(filterItems.startDate)}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  className="form-control date-select pl-4 date-picker"
                  onChange={date => setFilterItems({ startDate: dayjs(date).format('YYYY-MM-DD') })}
                  placeholderText="From"
                  calendarClassName="custom-datepicker"
                />
                <DatePicker
                  selected={filterItems.endDate && new Date(filterItems.endDate)}
                  dateFormat="dd-MM-yyyy"
                  popperPlacement="bottom-end"
                  className="form-control date-select pl-4 date-picker"
                  minDate={filterItems.startDate && new Date(filterItems.startDate)}
                  onChange={date => setFilterItems({ endDate: dayjs(date).format('YYYY-MM-DD') })}
                  placeholderText="To"
                  calendarClassName="custom-datepicker"
                />
              </div>

              <button
                aria-label="filter transactions"
                type="button"
                className="settlement-filter__filter-button"
                onClick={() => handleFilterQuery(filterOutEmptyValues(filterItems))}
                aria-controls="handleFilter"
              >
                <Icon name="arrowRight" width={16} height={16} />
              </button>
            </div>
          </div>
        </section>
      );
    default:
      return null;
  }
}
