/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import React, { useRef, useState } from 'react';
import clsx from 'clsx';

import useFocusTrap from '+dashboard/Shared/hooks/useFocusTrap';

import FeedbackHandler from './FeedbackHandler';

import completed from '+assets/img/dashboard/completed-tick.svg';

export interface IModalProps {
  close: () => void;
  visible?: boolean;
  hideCloseButton?: boolean;
  hideSecondButton?: boolean;
  themeColor?: string;
  heading: React.ReactNode;
  description?: React.ReactNode;
  content: unknown;
  showButtons?: boolean;
  firstButtonText?: React.ReactNode;
  secondButtonText?: React.ReactNode;
  closeAction?: () => void;
  secondButtonColor?: string;
  firstButtonAction?: () => void;
  secondButtonAction?: () => void;
  firstButtonDisable?: boolean;
  secondButtonDisable?: boolean;
  completedHeading?: string;
  completedDescription?: string | React.ReactNode;
  completedImage?: React.ReactNode;
  showImage?: boolean;
  completedAction?: () => void;
  completedActionText?: string;
  size?: 'lg' | 'md' | 'sm';
  maxHeight?: string;
  secondButtonActionIsTerminal?: boolean;
  noContentPadding?: boolean;
  modalStage?: 'init' | 'complete';
  headerClassName?: string;
  hideFirstButton?: boolean;
  secondButtonExtraStyle?: string;
  renderFooter?: (...args: any[]) => JSX.Element;
  modalClassName?: string;
}

const Modal = ({
  visible = true,
  hideCloseButton,
  hideSecondButton,
  close,
  heading,
  description,
  content,
  themeColor,
  showButtons = true,
  firstButtonText = 'Cancel',
  secondButtonText = 'Continue',
  closeAction,
  secondButtonColor,
  firstButtonAction,
  secondButtonAction,
  secondButtonActionIsTerminal = true,
  firstButtonDisable,
  secondButtonDisable,
  completedHeading = 'Success!',
  completedImage,
  completedDescription,
  completedAction,
  completedActionText = 'Dismiss',
  size = 'md',
  maxHeight,
  showImage = true,
  noContentPadding,
  secondButtonExtraStyle,
  headerClassName,
  modalStage = 'init',
  hideFirstButton,
  renderFooter,
  modalClassName = ''
}: IModalProps) => {
  const [modalState, setModalState] = useState({ loading: false, stage: modalStage || 'init' });
  const modalContentRef = useRef(null);
  useFocusTrap(modalContentRef, visible, close);
  let actionColor: string;
  if (secondButtonColor) {
    actionColor = secondButtonColor;
  } else {
    actionColor = themeColor || '#7447fd';
  }
  const handleMainAction = async (e: React.FormEvent) => {
    e?.preventDefault?.();
    if (secondButtonDisable) {
      return;
    }
    try {
      setModalState({ ...modalState, loading: true });
      await secondButtonAction?.();
      setModalState({ ...modalState, loading: false });
      if (secondButtonActionIsTerminal) setModalState({ ...modalState, stage: 'complete', loading: false });
    } catch (error) {
      setModalState({ ...modalState, loading: false });
    }
  };

  const getFooterProps = (className: string = '') => ({
    className: `modal-footer ${className}`
  });

  const getFirstButtonProps = (className: string = '') => ({
    'data-testid': 'first-button',
    className: `btn border-0 ${className}`,
    'data-dismiss': 'modal',
    type: 'button',
    disabled: firstButtonDisable,
    onClick: () => {
      if (!firstButtonAction) {
        setModalState({ ...modalState, stage: 'init' });
        return close();
      }
      return firstButtonAction();
    }
  });

  const getSecondButtonProps = (style: React.CSSProperties = {}) => ({
    'data-testid': 'second-button',
    type: 'button',
    className: `btn ${secondButtonExtraStyle}`,
    disabled: secondButtonDisable,
    style: {
      color: 'white',
      backgroundColor: actionColor,
      outline: actionColor,
      ...style
    },
    onClick: handleMainAction
  });

  const Header = () => (
    <div className="px-4 py-3 pb-3 border-bottom">
      <h4 className={`onboarding-title ${headerClassName || ''}`} style={{ color: themeColor || '#292b2c' }}>
        {heading}
      </h4>
      <div className="onboarding-text">{description}</div>
    </div>
  );

  const ModalFooter = () => (
    <div className="px-4 pb-3" style={{ background: '#F9FBFD', borderRadius: '6px' }}>
      {renderFooter ? (
        renderFooter({ getFirstButtonProps, getFooterProps, getSecondButtonProps, firstButtonText, secondButtonText })
      ) : (
        <div {...getFooterProps()}>
          {!hideFirstButton && (
            <button {...getFirstButtonProps()} onMouseDown={e => e.preventDefault()}>
              {firstButtonText}
            </button>
          )}
          {!hideSecondButton && (
            <button {...getSecondButtonProps()} onMouseDown={e => e.preventDefault()}>
              {modalState.loading ? (
                <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
              ) : (
                <span>{secondButtonText}</span>
              )}
            </button>
          )}
        </div>
      )}
    </div>
  );

  return visible ? (
    <div
      aria-hidden="true"
      className={`onboarding-modal modal ${visible ? 'show' : ''} fade ${modalClassName}`}
      role="dialog"
      tabIndex={-1}
      style={{ display: `${visible ? 'block' : 'none'}` }}
    >
      <div
        className={`modal-dialog modal-${size} modal-dialog-centered modal-centered ${modalState.stage === 'complete' ? 'modal-sm' : ''}`}
        role="document"
      >
        <div className="modal-content" style={{ maxHeight }} ref={modalContentRef}>
          {!hideCloseButton && (
            <button
              data-testid="close-button"
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setModalState({ ...modalState, stage: 'init' });
                if (closeAction) closeAction();
                close();
              }}
            >
              <span className="os-icon os-icon-close" />
            </button>
          )}

          {modalState.stage === 'init' && (
            <>
              {heading && <Header />}
              <FeedbackHandler component />
              {content && (
                <div className={clsx('modal-body border-bottom', { 'px-0 py-0': noContentPadding })}>
                  <form noValidate onSubmit={handleMainAction}>
                    <div className={clsx('form-center', { 'px-2 py-1': !noContentPadding })}>{content}</div>
                  </form>
                </div>
              )}
              {showButtons && <ModalFooter />}
            </>
          )}
          {modalState.stage === 'complete' && (
            <div className="modal-body p-4 onboarding-content text-center">
              <section className="modal-complete py-2">
                {showImage && <img src={(completedImage as string) || completed} alt="complete" />}
                <h4 className="mt-2 mb-3">{completedHeading}</h4>
                <p>{completedDescription}</p>
                <button type="button" className="btn btn-link" onClick={() => (completedAction ? completedAction() : close())}>
                  {completedActionText}
                </button>
              </section>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
