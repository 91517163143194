import { TIcon, TIconNames, TRenderSvg } from './IconNames';

//  Note: Icons should remain in alphabetical order

const icons: Record<TIconNames, TRenderSvg> = {
  arrowRight: ({ width = 24, stroke = 'white', height = 24, strokeWidth = 2, fill = 'none', name }) => (
    <svg data-testid={name} width={width} height={height} fill={fill} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 12H20" stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14 6L20 12L14 18" stroke="white" stroke-width={strokeWidth} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  ),
  calendar: ({ width = 16, stroke = '#DDE2EC', height = 17, strokeWidth = 2, fill = 'none', name }) => (
    <svg data-testid={name} width={width} height={height} viewBox="0 0 16 17" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_9084_11081)">
        <path
          d="M12.6667 3.1665H3.33333C2.59695 3.1665 2 3.76346 2 4.49984V13.8332C2 14.5696 2.59695 15.1665 3.33333 15.1665H12.6667C13.403 15.1665 14 14.5696 14 13.8332V4.49984C14 3.76346 13.403 3.1665 12.6667 3.1665Z"
          stroke={stroke}
          stroke-width={strokeWidth}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path d="M10.6667 1.8335V4.50016" stroke={stroke} stroke-width={strokeWidth} stroke-linecap="round" stroke-linejoin="round" />
        <path d="M5.33334 1.8335V4.50016" stroke={stroke} stroke-width={strokeWidth} stroke-linecap="round" stroke-linejoin="round" />
        <path d="M2 7.1665H14" stroke={stroke} stroke-width={strokeWidth} stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_9084_11081">
          <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  ),
  checkRounded: ({ fill = '#24B314', height = 20, width = 20, name }) => (
    <svg data-testid={name} width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433284 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17316C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM15.676 8.237L9.676 13.737C9.48604 13.9112 9.23615 14.0053 8.97848 13.9997C8.7208 13.9941 8.47524 13.8893 8.293 13.707L5.293 10.707C5.19749 10.6148 5.12131 10.5044 5.0689 10.3824C5.01649 10.2604 4.98891 10.1292 4.98775 9.9964C4.9866 9.86362 5.0119 9.73194 5.06218 9.60905C5.11246 9.48615 5.18672 9.3745 5.28061 9.2806C5.3745 9.18671 5.48615 9.11246 5.60905 9.06218C5.73195 9.0119 5.86363 8.98659 5.99641 8.98775C6.12918 8.9889 6.2604 9.01649 6.38241 9.0689C6.50441 9.12131 6.61476 9.19749 6.707 9.293L9.03 11.616L14.324 6.763C14.5195 6.58371 14.7781 6.48942 15.0431 6.50086C15.3081 6.5123 15.5577 6.62853 15.737 6.824C15.9163 7.01946 16.0106 7.27814 15.9991 7.54313C15.9877 7.80812 15.8715 8.05771 15.676 8.237Z"
        fill={fill}
      />
    </svg>
  ),
  circledCheck: ({ height = 18, width = 19, name, fill = '#24B314' }) => (
    <svg width={width} height={height} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1.30078C7.31886 1.30078 5.67547 1.7993 4.27766 2.73329C2.87984 3.66728 1.79037 4.9948 1.14703 6.54797C0.503683 8.10114 0.335355 9.81021 0.663329 11.459C0.991303 13.1079 1.80085 14.6224 2.9896 15.8112C4.17834 16.9999 5.6929 17.8095 7.34173 18.1375C8.99057 18.4654 10.6996 18.2971 12.2528 17.6538C13.806 17.0104 15.1335 15.9209 16.0675 14.5231C17.0015 13.1253 17.5 11.4819 17.5 9.80078C17.4976 7.54717 16.6013 5.38654 15.0078 3.793C13.4142 2.19946 11.2536 1.30316 9 1.30078ZM12.7318 8.30184L8.15491 12.8788C8.09418 12.9396 8.02207 12.9878 7.94269 13.0207C7.86332 13.0536 7.77824 13.0705 7.69231 13.0705C7.60638 13.0705 7.5213 13.0536 7.44193 13.0207C7.36255 12.9878 7.29044 12.9396 7.22971 12.8788L5.26818 10.9172C5.14549 10.7945 5.07656 10.6281 5.07656 10.4546C5.07656 10.2811 5.14549 10.1147 5.26818 9.99203C5.39086 9.86934 5.55726 9.80042 5.73077 9.80042C5.90428 9.80042 6.07068 9.86934 6.19337 9.99203L7.69231 11.4918L11.8066 7.37665C11.8674 7.3159 11.9395 7.26771 12.0189 7.23483C12.0982 7.20195 12.1833 7.18503 12.2692 7.18503C12.3551 7.18503 12.4402 7.20195 12.5196 7.23483C12.599 7.26771 12.6711 7.3159 12.7318 7.37665C12.7926 7.43739 12.8408 7.50951 12.8736 7.58889C12.9065 7.66826 12.9234 7.75333 12.9234 7.83924C12.9234 7.92515 12.9065 8.01022 12.8736 8.0896C12.8408 8.16897 12.7926 8.24109 12.7318 8.30184Z"
        fill={fill}
      />
    </svg>
  ),
  circledClose: ({ height = 18, width = 19, name, fill = '#F32345' }) => (
    <svg width={width} height={height} viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1.30078C7.31886 1.30078 5.67547 1.7993 4.27766 2.73329C2.87984 3.66728 1.79037 4.9948 1.14703 6.54797C0.503683 8.10114 0.335355 9.81021 0.663329 11.459C0.991303 13.1079 1.80085 14.6224 2.9896 15.8112C4.17834 16.9999 5.6929 17.8095 7.34173 18.1375C8.99057 18.4654 10.6996 18.2971 12.2528 17.6538C13.806 17.0104 15.1335 15.9209 16.0675 14.5231C17.0015 13.1253 17.5 11.4819 17.5 9.80078C17.4976 7.54717 16.6013 5.38654 15.0078 3.793C13.4142 2.19946 11.2536 1.30316 9 1.30078ZM12.078 11.9536C12.1387 12.0143 12.1869 12.0864 12.2198 12.1658C12.2527 12.2452 12.2696 12.3303 12.2696 12.4162C12.2696 12.5021 12.2527 12.5871 12.2198 12.6665C12.1869 12.7459 12.1387 12.818 12.078 12.8788C12.0172 12.9395 11.9451 12.9877 11.8657 13.0206C11.7864 13.0535 11.7013 13.0704 11.6154 13.0704C11.5295 13.0704 11.4444 13.0535 11.365 13.0206C11.2857 12.9877 11.2135 12.9395 11.1528 12.8788L9 10.7252L6.84721 12.8788C6.78647 12.9395 6.71435 12.9877 6.63497 13.0206C6.5556 13.0535 6.47053 13.0704 6.38462 13.0704C6.29871 13.0704 6.21363 13.0535 6.13426 13.0206C6.05489 12.9877 5.98277 12.9395 5.92202 12.8788C5.86127 12.818 5.81308 12.7459 5.78021 12.6665C5.74733 12.5871 5.73041 12.5021 5.73041 12.4162C5.73041 12.3303 5.74733 12.2452 5.78021 12.1658C5.81308 12.0864 5.86127 12.0143 5.92202 11.9536L8.07563 9.80078L5.92202 7.64799C5.79933 7.5253 5.73041 7.3589 5.73041 7.1854C5.73041 7.01189 5.79933 6.84549 5.92202 6.7228C6.04471 6.60011 6.21111 6.53119 6.38462 6.53119C6.55813 6.53119 6.72453 6.60011 6.84721 6.7228L9 8.8764L11.1528 6.7228C11.2135 6.66205 11.2857 6.61386 11.365 6.58098C11.4444 6.54811 11.5295 6.53119 11.6154 6.53119C11.7013 6.53119 11.7864 6.54811 11.8657 6.58098C11.9451 6.61386 12.0172 6.66205 12.078 6.7228C12.1387 6.78355 12.1869 6.85567 12.2198 6.93504C12.2527 7.01441 12.2696 7.09948 12.2696 7.1854C12.2696 7.27131 12.2527 7.35638 12.2198 7.43575C12.1869 7.51512 12.1387 7.58724 12.078 7.64799L9.92438 9.80078L12.078 11.9536Z"
        fill={fill}
      />
    </svg>
  ),
  copy: ({ width = 24, stroke = '#7447fd', height = 24, strokeWidth = 2, fill = 'none', name }) => (
    <svg data-testid={name} width={width} height={height} viewBox="0 0 14 14" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5835 1.1665H3.50016C3.19074 1.1665 2.894 1.28942 2.6752 1.50821C2.45641 1.72701 2.3335 2.02375 2.3335 2.33317V11.6665C2.3335 11.9759 2.45641 12.2727 2.6752 12.4915C2.894 12.7103 3.19074 12.8332 3.50016 12.8332H10.5002C10.8096 12.8332 11.1063 12.7103 11.3251 12.4915C11.5439 12.2727 11.6668 11.9759 11.6668 11.6665V5.24984L7.5835 1.1665Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M7.5835 1.1665V5.24984H11.6668" stroke={stroke} stroke-width={stroke} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  ),
  caution: ({ stroke = '#FA9500', fill = 'none', height = 18, width = 18, strokeWidth = 2, name }) => (
    <svg data-testid={name} width={width} height={height} viewBox="0 0 18 18" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_16774_1909)">
        <path
          d="M17.8467 15.1832L9.97174 1.68311C9.77015 1.33759 9.40017 1.125 8.99998 1.125C8.5998 1.125 8.22985 1.33759 8.02823 1.68311L0.15323 15.1832C-0.0497265 15.5312 -0.0511328 15.961 0.149398 16.3104C0.350176 16.6598 0.722059 16.8751 1.12498 16.8751H16.875C17.2779 16.8751 17.6498 16.6598 17.8506 16.3104C18.0511 15.961 18.0497 15.5312 17.8467 15.1832ZM8.99998 14.625C8.37927 14.625 7.87498 14.1212 7.87498 13.5C7.87498 12.8787 8.37927 12.375 8.99998 12.375C9.62183 12.375 10.125 12.8787 10.125 13.5C10.125 14.1212 9.62183 14.625 8.99998 14.625ZM10.125 10.125C10.125 10.7463 9.62127 11.25 8.99998 11.25C8.3787 11.25 7.87498 10.7463 7.87498 10.125V6.75004C7.87498 6.12875 8.3787 5.62504 8.99998 5.62504C9.62127 5.62504 10.125 6.12875 10.125 6.75004V10.125Z"
          fill={stroke}
        />
      </g>
      <defs>
        <clipPath id="clip0_16774_1909">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  search: ({ width = 24, stroke = '#AABDCE', height = 24, strokeWidth = 2, fill = 'none', name }) => (
    <svg data-testid={name} width={width} height={height} fill={fill} viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5 18.5C14.6421 18.5 18 15.1421 18 11C18 6.85786 14.6421 3.5 10.5 3.5C6.35786 3.5 3 6.85786 3 11C3 15.1421 6.35786 18.5 10.5 18.5Z"
        stroke={stroke}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M21 21.4998L15.8 16.2998" stroke={stroke} stroke-width={strokeWidth} stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
};

const Icon = (props: TIcon & { name: TIconNames }) => icons[props.name]?.(props) ?? null;
export default Icon;
