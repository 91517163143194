import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import createAuthSlice from './setAuth';
import createMerchantSlice from './setMerchantData';
import createUserAccessSlice from './setUsersPermission';
import createEmailConfigurationSlice from './setEmailConfigurationModal';
import createTipsAndGuideSlice from './setTipsAndGuide';
import storage from './storage';

const useStore = create()(
  devtools(
    persist(
      (set, get, api) => {
        return {
          ...createAuthSlice(set, get, api),
          ...createEmailConfigurationSlice(set, get, api),
          ...createMerchantSlice(set, get, api),
          ...createUserAccessSlice(set, get, api),
          ...createTipsAndGuideSlice(set, get, api)
        };
      },
      {
        name: 'bvnkng-app-data',
        storage: createJSONStorage(() => storage)
      }
    ),
    { enabled: process.env.NODE_ENV !== 'production' }
  )
);

export default useStore;
