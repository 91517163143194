import React from 'react';
import { history } from '+utils';
import Expired from '+assets/img/dashboard/expired.svg';
import './ExpiredExport.scss';
import { Storage } from '+services/storage-services';

export default function ExpiredExport() {
  return (
    <div className="expired-export">
      <img src={Expired} alt="Expired export page" />
      <h3>Invalid Link!</h3>
      <p>
        Sorry, this link can no longer be used because it has
        <br />
        expired or was revoked. Please try again.
      </p>
      <button
        type="button"
        onClick={() => {
          history.push('/dashboard/home');
          Storage.removeItem('EXPORT_LINK');
        }}
        className="btn btn--link"
      >
        <span>Proceed to Dashboard</span>
      </button>
    </div>
  );
}
