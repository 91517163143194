import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import useStore from '+store';
import { history } from '+utils';

import { IdentityFilter } from '../../../Identity/components/IdentityFilter';
import AdvanceExportModal from '../../../Shared/AdvanceExportModal';
import ExportFilterModal from '../../../Shared/ExportFilterModal';
import FilterModal, { getExistingFiltersFromQuery, updateQueryParams } from '../../../Shared/FilterModal';
import { IFilter } from '../../types';
import { IssuanceFilter } from '../IssuanceFilter';

import './index.scss';

export default function Filter({
  actions = [],
  totalItems,
  filterModalHeading,
  filterModalDescription,
  filterFields,
  onHandleFilter,
  quickFilterType,
  title,
  isFiltered,
  onChangeIsFiltered,
  exportAction,
  exportType,
  exportHeading,
  exportDescription,
  exportAllowColumnSelection
}: IFilter) {
  const location = useLocation();
  const { profile } = useStore();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isExportVisible, setIsExportVisible] = useState(false);
  const [showLargeExportModal, setLargeExportModal] = useState(false);
  const nonFilterParams = useRef({});

  useEffect(() => {
    const params = getExistingFiltersFromQuery(filterFields);
    const filtered = !!Object.keys(params || {}).length;
    onChangeIsFiltered?.(filtered);
  }, [location.search]);

  const setNonFilterParams = (data = {}) => {
    nonFilterParams.current = data;
  };

  const handleFilterQuery = (obj = {}) => {
    if (!Object.keys(obj).length) {
      return;
    }
    updateQueryParams({ ...(nonFilterParams.current || {}), ...obj });
    onHandleFilter?.();
  };

  function clearFilter() {
    const items = nonFilterParams.current || {};
    const newQuery = qs.stringify({ ...items }, { arrayFormat: 'comma', commaRoundTrip: true, encodeValuesOnly: true });
    history.push(`?${newQuery}`);
    onHandleFilter?.();
  }

  useEffect(() => {
    clearFilter();
  }, [nonFilterParams.current.tab]);

  async function handleExport(...args) {
    const res = await exportAction(...args, () => setIsExportVisible(false));
    if (res?.handleLargeExports) {
      setIsExportVisible(false);
      setLargeExportModal(true);
    }
  }

  return (
    <div className="mb-3 filter">
      <div className="table-heading mb-0">
        <div className="title-container">
          <p className="mb-0">{title}</p>

          {isFiltered ? (
            <>
              <span className="divider-sm" />
              <button type="button" className="icon-btn d-flex align-items-center" onClick={() => clearFilter()}>
                {' '}
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20" style={{ width: '1rem' }}>
                  <path
                    fill="#AABDCE"
                    d="M3.426 2.926c3.902-3.9 10.247-3.9 14.149 0 3.9 3.901 3.9 10.248 0 14.15A9.976 9.976 0 0110.5 20a9.975 9.975 0 01-7.074-2.924c-3.901-3.902-3.901-10.249 0-14.15zM6.374 12.95a.833.833 0 101.179 1.178L10.5 11.18l2.946 2.948a.835.835 0 001.18-1.18l-2.947-2.946 2.947-2.948a.833.833 0 10-1.179-1.179L10.5 8.822 7.553 5.874a.833.833 0 10-1.18 1.18L9.322 10l-2.947 2.948z"
                  />
                </svg>
                &nbsp;
                <span>Clear</span>
              </button>
            </>
          ) : null}
        </div>

        {actions.length ? (
          <div className="action-btns">
            {actions?.map?.(action => {
              if (action === 'export') {
                return (
                  <div className="btn-container" key={action}>
                    <button
                      type="button"
                      className="btn btn-secondary link-btn"
                      hidden={!totalItems}
                      onClick={() => setIsExportVisible(true)}
                    >
                      <i className="os-icon os-icon-arrow-up-right" />
                      <span>Export</span>
                    </button>

                    {isExportVisible && (
                      <AdvanceExportModal
                        setOpenExport={setIsExportVisible}
                        exportAction={handleExport}
                        type={exportType}
                        showSuccessModal={false}
                        heading={exportHeading}
                        description={exportDescription}
                        allowColumnSelection={exportAllowColumnSelection}
                      />
                    )}
                  </div>
                );
              }
              if (action === 'filter') {
                return (
                  <div className="btn-container" key={action}>
                    <button className="btn btn-secondary link-btn" type="button" onClick={() => setIsFilterVisible(!isFilterVisible)}>
                      <i className="os-icon os-icon-sliders" />
                      <span>Advanced Filter</span>
                    </button>

                    <FilterModal
                      visible={isFilterVisible}
                      heading={filterModalHeading}
                      description={filterModalDescription}
                      close={() => setIsFilterVisible(false)}
                      fields={filterFields}
                      onHandleFilter={onHandleFilter}
                      setNonFilterParams={setNonFilterParams}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        ) : null}
      </div>

      {quickFilterType && (
        <div className="mb-4 mt-3">
          {quickFilterType === 'verification-events' || quickFilterType === 'billing-history' ? (
            <IdentityFilter setNonFilterParams={setNonFilterParams} handleFilterQuery={handleFilterQuery} type={quickFilterType} />
          ) : (
            <IssuanceFilter setNonFilterParams={setNonFilterParams} handleFilterQuery={handleFilterQuery} type={quickFilterType} />
          )}
        </div>
      )}

      <ExportFilterModal close={() => setLargeExportModal(false)} email={profile.email} visible={showLargeExportModal} />
    </div>
  );
}
