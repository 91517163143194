import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { breadCrumbEvents } from '+/utils/bugsnag-events';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import { capitalize, capitalizeRemovedash, formatAmount, getDate, getTime, history, logBreadCrumb, switchStatus } from '+utils';

import Copyable from '../../Shared/Copyable';
import LoadingPlaceholder from '../../Shared/LoadingPlaceholder';
import SecondaryDetails from '../../Shared/SecondaryDetails';
import Table from '../../Shared/Table';

import VBAIcon from '+assets/img/dashboard/vba-icon.svg';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

export default function VirtualBankAccountDetails() {
  const { feedbackInit } = useFeedbackHandler();
  const { id } = useParams();
  const [state, setState] = useState({ paginationPage: 1, limit: 10 });

  const { data: detailsData, isLoading: isFetchingDetails } = useQuery(
    `${id?.toUpperCase()}_DETAILS`,
    () => api.getVirtualBankAccount(id),
    {
      onError: () => {
        feedbackInit({
          message: "There has been an error this account's information",
          type: 'danger'
        });
      }
    }
  );

  const { data: vbaTrxns, isFetching } = useQuery(
    [`${id?.toUpperCase()}_TRANSACTIONS`, state.paginationPage, state.limit],
    () => api.getVirtualBankAccountTransactions(id, state.paginationPage, state.limit),
    {
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          message: "There has been an error getting this account's transactions",
          type: 'danger'
        });
      }
    }
  );

  const paging = vbaTrxns?.paging || {};
  const transactions = vbaTrxns?.data || [];
  const data = detailsData?.data || {};

  const vbaDetails = [
    {
      'Account Number': data.account_number || 'Not Available',
      'Account Name': data.account_name || 'Not Available',
      Bank: `${capitalizeRemovedash(data?.bank_name || 'Not Available')}`,
      'Account Status': (
        <>
          <span className={`status-pill smaller ${switchStatus(data.account_status)}`} />
          {capitalize(data.account_status)}
        </>
      ),
      'Account Reference': <Copyable text={data.account_reference} showOnHover />
    },
    {
      'Customer Name': data.customer?.name || 'Not Available',
      'Customer Email': data.customer?.email || 'Not Available',
      'Created At': `${getDate(data.created_at)}`,
      'Unique ID': <Copyable text={data.unique_id} showOnHover textModifier={x => x?.toUpperCase()} />,
      'Total Amount Received': `${formatAmount(data.total_transacted_amount || 0)} ${data.currency || 'NGN'}`
    }
  ];

  const accountTransactions = () => {
    return (
      <>
        {transactions.map(each => {
          return (
            <div
              key={`settlement_${each.reference}`}
              className="div-table --column-5 --vba-trxns --row"
              onClick={() => {
                history.push(`/dashboard/pay-ins/${each.payment?.reference}`);
                logBreadCrumb({
                  event: breadCrumbEvents.virtualAccounts.accountTableClicked,
                  data: { reference: each.payment?.reference }
                });
              }}
              onKeyDown={() => {
                history.push(`/dashboard/pay-ins/${each.payment?.reference}`);
                logBreadCrumb({
                  event: breadCrumbEvents.virtualAccounts.accountTableClicked,
                  data: { reference: each.payment?.reference }
                });
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <span className="body-row-header">Transaction Date:</span>
                <span>{getDate(each.created_at)}</span>
                <span className="annotation" style={{ marginLeft: '5px' }}>
                  {getTime(each.created_at)}
                </span>
              </div>
              <div>
                <span className="body-row-header">Transaction ID:</span>
                <span style={{ color: '#7447fd', fontWeight: '500', textTransform: 'uppercase' }}>{each.payment?.reference}</span>
              </div>
              <div>
                <span className="body-row-header">Payer:</span>
                <span>{capitalize(each?.payer_bank_account?.account_name || 'Not Available')}</span>
              </div>
              <div>
                <span className="body-row-header">Amount:</span>
                <span>
                  <span style={{ fontWeight: '600' }}>{formatAmount(each.amount)} </span>
                  {each?.currency || 'NGN'}
                </span>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className="row">
        <button type="button" className="btn btn-link" onClick={() => history.push('/dashboard/virtual-accounts')}>
          <i className="os-icon os-icon-arrow-left7" />
          <span style={{ fontSize: '13px', fontWeight: '500' }}>Back to Pay-ins</span>
        </button>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="element-wrapper">
            <div className="content-details-box">
              <div className="content-details-head" style={{ display: 'flex', flexDirection: 'column', border: '0' }}>
                <div className="content-details-title">
                  <div>
                    <img src={VBAIcon} alt="" style={{ width: '3.2rem', paddingTop: '0.3rem' }} />
                  </div>
                  <div className="cd-title ml-3">
                    <h5 className="cd-maintitle" aria-label="Account Number">
                      {data?.account_number?.toUpperCase()}
                    </h5>
                    <p className="cd-subtitle">{capitalizeRemovedash(data?.bank_name || 'Not Available')}</p>
                  </div>
                </div>
                <div className="virtual-account-summary">
                  {data.account_status === 'suspended' && <section className="vba-banner">This account has been suspended!</section>}
                  {data.account_status === 'deleted' && (
                    <section className="vba-banner deleted">This account has been deleted and is no longer in use!</section>
                  )}
                  {isFetchingDetails ? (
                    <LoadingPlaceholder type="text" content={4} />
                  ) : (
                    <SecondaryDetails title="Account Summary" data={vbaDetails} />
                  )}
                </div>
              </div>
              <div className="content-details-body mt-4" style={{ boder: '0' }}>
                <div className="box-header mb-3">{`Transactions (${transactions.length})`}</div>
                <Table
                  tableClassName=" --column-5 --vba-trxns"
                  headings={[
                    {
                      value: 'Transaction Date'
                    },
                    {
                      value: 'Transaction ID'
                    },
                    {
                      value: 'Payer'
                    },
                    {
                      value: 'Amount'
                    }
                  ]}
                  hasPagination
                  loading={isFetching}
                  current={state.paginationPage}
                  totalItems={paging?.total_items || 0}
                  pageSize={paging?.page_size || 0}
                  actionFn={current => setState({ paginationPage: current })}
                  emptyStateMessage={<p style={{ maxWidth: '200px', marginTop: '-20px' }}>There are no transactions for this account.</p>}
                  children={accountTransactions()}
                  background="#f5f6f6"
                  tableWrapperClassName={'vba-container sub'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
