import DatePicker from '+containers/Shared/DatePicker';
import { allowTextandSpace, cleanInput, stripNonNumeric } from '+utils';

import { IKycForm } from '../types';
import { MAX_BVN_LEN } from './accessRequestModalData';

const KycForm = ({ formikBag, merchantName }: IKycForm) => {
  const { values, touched, errors, handleBlur, setFieldValue } = formikBag;

  return (
    <div className="stack-lg fade-in">
      <div className="stack-md fade-in">
        <div id="name-on-card" className="rvc-label reset-margin stack-sm" data-label-props="lg normal">
          Name
        </div>
        <div data-layout="switcher">
          <div>
            <label htmlFor="first-name" className="rvc-label sr-only">
              First Name
            </label>
            <input
              id="first-name"
              placeholder="Enter first name"
              className="form-control"
              name="bvn_firstName"
              value={values?.bvn_firstName}
              onChange={e => setFieldValue?.('bvn_firstName', allowTextandSpace(e.target.value).trim())}
              onBlur={handleBlur}
              aria-errormessage="first-name-error"
              aria-invalid={`${touched?.bvn_firstName && errors?.bvn_firstName ? 'true' : 'false'}`}
            />
            <span id="first-name-error" data-feedback="invalid" aria-live="assertive">
              {touched?.bvn_firstName && errors?.bvn_firstName}
            </span>
          </div>

          <div>
            <label htmlFor="last-name" className="rvc-label sr-only">
              Last Name
            </label>
            <input
              id="last-name"
              placeholder="Enter last name"
              className="form-control"
              name="bvn_lastName"
              value={values?.bvn_lastName}
              onChange={e => setFieldValue?.('bvn_lastName', allowTextandSpace(e.target.value).trim())}
              onBlur={handleBlur}
              aria-errormessage="last-name-error"
              aria-invalid={touched?.bvn_lastName && errors?.bvn_lastName ? 'true' : 'false'}
            />
            <span id="last-name-error" data-feedback="invalid" aria-live="assertive">
              {touched?.bvn_lastName && errors?.bvn_lastName}
            </span>
          </div>
        </div>
      </div>

      <div className="request-access-form-group reset-margin stack-sm">
        <label className="rvc-label" htmlFor="dob" data-label-props="lg bolder">
          Date of birth
        </label>
        <DatePicker
          placeholder="DD-MM-YYYY"
          selected={(values?.dob as unknown) as Date}
          dateFormat="dd-MM-yyyy"
          className={`${touched?.dob && errors?.dob ? 'invalid-input' : ''}`}
          onChange={date => setFieldValue?.('dob', date)}
          autoComplete="off"
          onBlur={handleBlur}
          name="dob"
          id="dob"
          aria-errormessage="dob-error"
        />
        <span id="dob-error" data-feedback="invalid" aria-live="assertive">
          {touched?.dob && errors?.dob}
        </span>
      </div>

      <div className="request-access-form-group reset-margin stack-sm">
        <label className="rvc-label" htmlFor="business_name">
          Business name
        </label>
        <input
          className="form-control"
          id="business_name"
          placeholder="Year"
          name="business_name"
          value={merchantName}
          aria-errormessage="year-error"
          readOnly
        />
      </div>

      <div className="request-access-form-group reset-margin stack-md">
        <label htmlFor="bvn" className="rvc-label">
          Bank verification number (BVN)
        </label>
        <p className="block subtext">
          This information is required for identity verification to ensure a safe and reliable experience on bvnkng. Please ensure that the
          BVN belongs to the name provided above.
        </p>
        <div>
          <input
            type="text"
            className="form-control"
            id="bvn"
            placeholder="BVN"
            name="bvn"
            value={values?.bvn}
            onChange={e => setFieldValue?.('bvn', stripNonNumeric(cleanInput(e.target.value)))}
            onBlur={handleBlur}
            maxLength={MAX_BVN_LEN}
            aria-invalid={touched?.bvn && errors?.bvn ? 'true' : 'false'}
            aria-errormessage="bvn-error"
            style={{ flexGrow: 3 }}
          />
        </div>
        <span id="bvn-error" data-feedback="invalid" aria-live="assertive">
          {touched?.bvn && errors?.bvn}
        </span>
      </div>
    </div>
  );
};

export default KycForm;
