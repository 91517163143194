import { Suspense } from 'react';

import './index.scss';

import loader from '+assets/img/loader.gif';

interface ISuspenseLoaderProps {
  message?: string;
}

export const SuspenseLoader = ({ message = '' }: ISuspenseLoaderProps) => {
  return (
    <section className="suspense-loader">
      <div className="suspense-image--loader">
        <img src={loader} alt="bvnkng logo" />
        <p>{message}</p>
      </div>
    </section>
  );
};

export default function LazyComponent(Component) {
  return props => (
    <Suspense
      fallback={
        <section className="suspense-loader">
          <div className="suspense-image--loader">
            <img src="https://bvnkngblobstorage.blob.core.windows.net/modal-bucket/kpy-k-standalome.svg" alt="bvnkng logo" />
          </div>
        </section>
      }
    >
      <Component {...props} />
    </Suspense>
  );
}
