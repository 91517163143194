import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '+containers/Shared/Tabs';
import { useSearchQuery } from '+hooks';
import { Currency } from '+types';
import { filteredOutObjectProperty, history } from '+utils';
import useStore from '+store';

import Table from '../../Shared/Table';
import Filter from '../components/Filter/Filter';
import { useFetchIssuedCardTables, useFetchIssuingExport } from '../hooks';
import { getIssuingPermissions } from '../utils';
import DetailsSection from './components/DetailsSection';
import { cardDetailsFilterProps, cardDetailsTableProps, computeTableTitle, exportActionOptions } from './constants';

import './index.scss';

type TabLabel = 'Transactions' | 'Balance History' | 'Chargebacks' | 'Events';
type TabKey = 'transactions' | 'balance_history' | 'chargebacks' | 'events';
type TabListType = { label: TabLabel; key: TabKey; badge?: string }[];

const tabs: TabListType = [
  {
    label: 'Transactions',
    key: 'transactions'
  },
  {
    label: 'Balance History',
    key: 'balance_history'
  },
  {
    label: 'Chargebacks',
    key: 'chargebacks'
  },
  {
    label: 'Events',
    key: 'events'
  }
];

export default function IssuedCardDetails() {
  const MERCHANT_ENV = useStore(state => state.merchantEnv);
  const { ref } = useParams();
  const { pathname } = useLocation();
  const cardCategory = pathname?.split('/').slice(-2)[0];
  const { setQuery, value: searchQueryValue } = useSearchQuery();
  const { canViewChargebacks, canExportChargebacks, canExportCardTrxns } = getIssuingPermissions();
  const [isFiltered, setIsFiltered] = useState(false);

  const activeTab = searchQueryValue.tab ?? (tabs[0].key as TabKey);
  const activeCurrency = (searchQueryValue?.currency as Currency) ?? 'USD';
  const limit = searchQueryValue.limit ?? '10';
  const page = searchQueryValue.page ?? '1';

  const sortingParams = {
    ...filteredOutObjectProperty((searchQueryValue as unknown) as Record<string, string[]>, ['currency', 'page', 'limit', 'sorterType'])
  };

  const exportAction = useFetchIssuingExport({
    cardId: ref,
    resourceType: `single-card-${activeTab}`,
    exportFn: exportActionOptions[activeTab]
  });

  const { tableData, isFetching, refetch } = useFetchIssuedCardTables({
    tab: activeTab,
    cardId: ref,
    limit,
    page,
    merchantEnv: MERCHANT_ENV,
    sortingParams
  });

  const computePermissions = () => {
    if (canViewChargebacks && activeTab === 'chargebacks') return false;
    return true;
  };
  const cardTitle = cardCategory === 'customer_cards' ? 'Issued' : 'Reserved';
  const paging = tableData?.data?.paging;

  return (
    <div className="issued-cards-details__comp">
      <div>
        <button type="button" className="btn btn-link mb-3 pl-0" onClick={() => history.push(`/dashboard/issuing?tab=${cardCategory}`)}>
          <i className="os-icon os-icon-arrow-left7" />
          <span>{`Back to ${cardTitle} Cards`}</span>
        </button>
      </div>

      <DetailsSection cardId={ref} cardCategory={cardCategory} currency={activeCurrency} />

      <Tabs defaultValue={activeTab} onChange={subtabValue => setQuery({ tab: subtabValue }, true)}>
        <div>
          <TabList>
            {tabs.map(({ label, key, badge }) => {
              return (
                <Tab key={key} value={key}>
                  {label}
                  {badge ? <p className="badge badge-danger">{badge}</p> : null}
                </Tab>
              );
            })}
          </TabList>
          <div className="divide-y" />
        </div>

        <TabPanels>
          {tabs.map(({ key }) => {
            const filterProps = cardDetailsFilterProps[key];
            const tableProps = cardDetailsTableProps({
              canExportCardTrxns: true,
              canExportEvents: canExportCardTrxns,
              canExportChargebacks,
              currency: activeCurrency
            })[key];

            return (
              <TabPanel value={key} key={key} className="transaction_table_comp table-container">
                <Filter
                  totalItems={paging?.total_items}
                  title={computeTableTitle({
                    title: tableProps.tableTitle ?? '',
                    filtered: isFiltered,
                    activeTab,
                    pageItemCount: paging?.total_items
                  })}
                  actions={tableProps.tableActions}
                  isFiltered={isFiltered}
                  onChangeIsFiltered={setIsFiltered}
                  onHandleFilter={() => refetch()}
                  filterModalHeading={filterProps.filterModalHeading ?? ''}
                  filterModalDescription={filterProps.filterModalDescription ?? ''}
                  filterFields={filterProps.filterFields ?? {}}
                  exportType={filterProps.exportType}
                  quickFilterType={activeTab}
                  exportHeading={filterProps.exportHeading}
                  exportDescription={filterProps.exportDescription}
                  exportAction={exportAction}
                />

                <Table
                  tableClassName={`--history-table ${tableProps.tableClassName}`}
                  headings={tableProps.headings}
                  hasPagination
                  borderedTable
                  loading={isFetching || false}
                  current={paging?.current}
                  limitAction={value => setQuery({ limit: String(value) })}
                  pageSize={paging?.page_size}
                  actionFn={value => setQuery({ page: String(value) })}
                  totalItems={paging?.total_items || 0}
                  emptyStateHeading={tableProps.emptyStateHeading}
                  emptyStateMessage={
                    <>
                      <span>{tableProps.emptyStateMessage}</span>
                      {computePermissions() && (
                        <button type="button" className="refetch-button" onClick={() => refetch()}>
                          <i className="os-icon os-icon-rotate-ccw mr-1" />
                          Refresh
                        </button>
                      )}
                    </>
                  }
                >
                  {tableProps.renderChildren(tableData?.data?.data)}
                </Table>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </div>
  );
}
