const createEmailConfigurationSlice = set => ({
  updateInProgress: false,
  showEmailConfigModal: false,
  modalStage: 'init',
  saveFn: () => null,
  setUpdateInProgress: value => set(state => ({ ...state, updateInProgress: value })),
  setModalStage: value => set(state => ({ ...state, modalStage: value })),
  setShowEmailConfigModal: value => set(state => ({ ...state, showEmailConfigModal: value })),
  setSaveFn: value => set(state => ({ ...state, saveFn: value }))
});

export default createEmailConfigurationSlice;
