import { Currency } from '+types';
import { formatAmount, getDate, getTime } from '+utils';

import { AllBalanceHistoryResponseType } from '../../types';

const IssuingHistoryRow = ({ rowData, currency }: { rowData: Array<AllBalanceHistoryResponseType>; currency: Currency }) => {
  return (
    <div>
      {rowData?.map?.(tx => {
        const isCredit = tx.direction === 'credit';
        return (
          <div
            key={`transaction_${tx.reference}_${tx.balance_after}`}
            className="div-table --history-table --row balance-history-row"
            role="button"
            tabIndex={0}
          >
            <div className="--txn-status --column d-flex">
              <span className="body-row-header">Date:</span>
              <span className="grey-text ml-1">
                {getDate(tx.history_date)} {getTime(tx.history_date)}
              </span>
            </div>
            <div className="--column">
              <span className="body-row-header">Details:</span>
              <span className="value">
                {tx.description}
                {tx.reference ? (
                  <span className="font-weight-500 text-uppercase" style={{ color: '#7447fd' }}>
                    {' '}
                    - {tx.reference}
                  </span>
                ) : null}
              </span>
            </div>
            <div className="--column primary">
              <span className="body-row-header">Amount ({currency}):</span>
              <span className={isCredit ? 'success-text' : 'error-text'}>{(isCredit ? '+ ' : '- ') + formatAmount(tx.amount)}</span>
            </div>
            <div className="--column">
              <span className="body-row-header">Balance After ({currency}):</span>
              <span>{tx.balance_after === undefined ? '--' : formatAmount(tx.balance_after)}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default IssuingHistoryRow;
