/* eslint-disable prefer-template */
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

// DayJS is a much smaller, faster version of momentjs (which is no longer being maintained)
// Documentation can be found here: https://day.js.org/en/
dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc);

export const getTime = (date: string | number | Date) => dayjs(date).format('LT');

export const specialisedDate = (date: string | number | Date, format: string | undefined) => dayjs(date).format(format);

export const getDate = (date: string | number | Date) => dayjs(date).format('D MMM YYYY');

export const getTwoDigitDate = (date: string | number | Date) => dayjs.utc(date).format('MM / YY');

export const getTwoDigitYear = (date: string | number | Date) => dayjs(date).format('YY');

export const getDateAndTime = (date: string | number | Date) => dayjs(date).format('D MMM YYYY, LT');

export const getHumanTime = (date: string | number | Date) => dayjs(date).fromNow();

export const daysToSeconds = (days: number) => dayjs.duration(days, 'days').asSeconds();

export const createDateObject = (date: string | number | Date | undefined = undefined) => dayjs(date);

export const addDaysToDate = (date: string | number | Date, days: number) => {
  const newDateObj = dayjs(date);
  return newDateObj.add(days, 'days');
};

export const getDifferenceInDays = (earlierDate: string | number | Date, laterDate: string | number | Date) => {
  const earlierTime = createDateObject(earlierDate);
  const laterTime = createDateObject(laterDate);
  return earlierTime.diff(laterTime, 'day');
};

export const durationToday = () => {
  const currentDay = dayjs().format('YYYY-MM-DD');
  return [currentDay, currentDay];
};

export const durationWeek = () => {
  const initDate = dayjs().format('YYYY-MM-DD');
  const prevDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
  return [prevDate, initDate];
};

export const durationMonth = (value: number) => {
  const initDate = dayjs().format('YYYY-MM-DD');
  const prevDate = dayjs().subtract(value, 'month').format('YYYY-MM-DD');
  return [prevDate, initDate];
};

export const durationYear = () => {
  const initDate = dayjs().format('YYYY-MM-DD');
  const prevDate = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
  return [prevDate, initDate];
};

export const daysfromToday = (date: string | number | Date) => {
  const today = new Date();
  const actualDate = new Date(date);
  // If dates are the same
  if (dayjs(today).format('MMMM D YYYY') === dayjs(actualDate).format('MMMM D YYYY')) {
    return 'Today';
  }
  // Reset to the beginning of the day
  today.setHours(0);
  if (actualDate > today) {
    return dayjs(date).from(dayjs(today));
  }
  const daysToValue = dayjs(today).to(dayjs(date));
  if (daysToValue.startsWith('a')) {
    return `1 ${daysToValue.slice(2)}`;
  }
  return daysToValue;
};

export const getTimeFromSeconds = (inputSeconds: number) => {
  const Days = Math.floor(inputSeconds / (60 * 60 * 24));
  const Hour = Math.floor((inputSeconds % (60 * 60 * 24)) / (60 * 60));
  const Minutes = Math.floor(((inputSeconds % (60 * 60 * 24)) % (60 * 60)) / 60);
  const Seconds = Math.floor(((inputSeconds % (60 * 60 * 24)) % (60 * 60)) % 60);
  let ddhhmmss = '';
  if (Days > 0) {
    if (Days === 1) {
      ddhhmmss += Days + ' Day ';
    } else {
      ddhhmmss += Days + ' Days ';
    }
  }
  if (Hour > 0) {
    if (Hour === 1) {
      ddhhmmss += Hour + ' Hour ';
    } else {
      ddhhmmss += Hour + ' Hours ';
    }
  }

  if (Minutes > 0) {
    if (Minutes === 1) {
      ddhhmmss += Minutes + ' Minute ';
    } else {
      ddhhmmss += Minutes + ' Minutes ';
    }
  }

  if (Seconds > 0) {
    if (Seconds === 1) {
      ddhhmmss += Seconds + ' Second ';
    } else {
      ddhhmmss += Seconds + ' Seconds ';
    }
  }
  return ddhhmmss;
};

export const cleanInput = (value: string | null) => `${value}`.replace(/[!<>%$?!&^()"'{}[|`~#%*=+;\]\n\r\\]/gi, '');

export const stripNonNumeric = (value: string) => `${value}`.replace(/\D/g, '');

export const formatAmount = (value: unknown) => {
  const unformattedValue = value?.toString().replace(/,/g, '');
  const formattedValue = unformattedValue ? parseFloat(unformattedValue).toFixed(2) : '';
  return `${formattedValue}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const backwardAmountInput = (value: string) => {
  const formatted = `${value}`.replace(/[A-Z.]/gi, '');
  if (formatted === '') return '';
  const first = formatted.substring(0, formatted.length - 2);
  const second = formatted.substring(formatted.length - 2);
  if (!second) {
    const number = parseInt(first, 10);
    if (!Number.isNaN(formatted)) return number.toFixed(2);
    return false;
  }
  return first + '.' + second;
};

export const handleKeyPress = (event: { key: string; preventDefault: () => void }) => {
  if (event.key === 'e' || event.key === 'E' || event.key === '+') {
    event.preventDefault();
  }
};

export const capitalize = (text: string) => {
  if (!text) return '';
  return text
    .toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const capitalizeFirst = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const capitalizeFirstText = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const camelCaseToSentenceCase = (text: string) => {
  return text
    .split(/(?=[A-Z])/)
    .map((x: string) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ');
};

export const capitalizeRemovedash = (text: string) => {
  return text
    .split('_')
    .flatMap((x: string) => x.split('-'))
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const lowercaseRemovedash = (text: string) => {
  return text.toLowerCase().split('_').join(' ');
};

export const masklast4digits = (text: string) => text.replace(/\d(?=\d{4})/g, '*');
export const maskAfterSetValue = (text: string, value: any) => text.slice(0, value) + text.slice(value).replace(/[A-Za-z0-9]/g, '*');
export const maskBetweenSetRange = (text: any, startValue: number | undefined, endValue: number | undefined) =>
  `${text}`.slice(0, startValue) + `${text}`.slice(startValue, endValue).replace(/[A-Za-z0-9]/g, '*') + `${text}`.slice(endValue);

export const formatDocStatus = (status: any) => {
  switch (status) {
    case 'under_review':
      return 'Under review';
    case 'approved':
      return 'Approved';
    case 'rejected':
      return 'Rejected';
    default:
      return status;
  }
};

export const formatPhoneNumber = (value: string) => {
  if (!value) return '';
  return parsePhoneNumberFromString(value) ? parsePhoneNumberFromString(value)?.number : value.replace(/\s/g, '');
};

export const switchCurrency = {
  NGN: 'Naira (NGN)',
  USD: 'Dollar (USD)',
  EUR: 'Euro (EUR)',
  KES: 'Kenyan Shilling (KES)',
  GBP: 'Pound (GBP)',
  GHS: 'Ghana Cedi (GHS)',
  ZAR: 'South African Rand (ZAR)',
  XAF: 'Central African CFA (XAF)',
  XOF: 'West African CFA (XOF)'
};

export const currencyToCountryCode: Record<string, string> = { KES: 'KE', NGN: 'NG', GHS: 'GH', ZAR: 'ZA', XAF: 'CM', XOF: 'CI' };

export const countryMobileCode = { KES: '254', NGN: '234', GHS: '233', ZAR: '27', XAF: '237', XOF: '225' };

export const formatFileName = (docName: string, type: string) => {
  if (docName.length > 10) {
    const fileName = docName.substring(0, 10);
    return `${fileName} ...${type}`;
  }
  return `${docName}`;
};

export const colours = [
  '#48CEB0',
  '#FFFFFF',
  '#FF2929',
  '#FFC107',
  '#7535FD',
  '#FF8282',
  '#647E9E',
  '#F79E1B',
  '#D5D8DB',
  '#7447fd',
  '#47515F'
];

export const formatMerchantName = (merchantName: string) => {
  if (merchantName === '') {
    throw new Error('Merchant name cannot be empty');
  }

  let lastName;

  const [firstName, ...lastNameArray] = merchantName.split(/[ -]/);

  if (lastNameArray?.length) {
    lastName = lastNameArray.join('-');
  }

  return [firstName, lastName];
};

export const allowOnlyText = (string: string) => {
  return string.replace(/[^a-zA-Z]/gi, '');
};

export const allowTextandSpace = (string: string) => {
  return string.replace(/[^A-Za-z ]/gi, '');
};

export const formatCreditCardNumber = (creditCardNumber: string) => {
  const cleanedNumber = creditCardNumber.replace(/\D/g, '');
  const formattedNumber = cleanedNumber.replace(/(\d{4})(?=\d)/g, '$1 ');
  return formattedNumber;
};

export const filterUserInput = (input: string) => {
  const regex = /[^a-zA-Z0-9,.'() ]/g;
  return input.replace(regex, '');
};
