/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { usePDF, Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import Filesaver from 'file-saver';
import Modal from './Modal';
import AvertaNormal from '+assets/fonts/Averta/AvertaPE-Regular.otf';
import AvertaBold from '+assets/fonts/Averta/AvertaPE-Extrabold.otf';
import AvertaSemiBold from '+assets/fonts/Averta/AvertaPE-Semibold.otf';
import padlockImg from '+assets/img/dashboard/padlock.png';
import briefcaseImg from '+assets/img/dashboard/briefcase.png';
import bvnkngLogo from '+assets/img/dashboard/kora-logo.png';

export interface IReceiptData {
  id: string;
  txType: 'payouts' | 'pay-ins';
  isInternalTransaction: boolean;
  currency: string;
  amount: string | number;
  customerInfo: string;
  summaryInfo: string;
  summaryList: {
    label: string;
    value: string | number;
  }[];
}

interface IReceiptModal {
  close: () => void;
  data: IReceiptData;
}

Font.register({
  family: 'Averta PE',
  fonts: [{ src: AvertaNormal }, { src: AvertaBold, fontWeight: 700 }, { src: AvertaSemiBold, fontWeight: 500 }]
});

function ReceiptTemplate(props: IReceiptData) {
  const { txType, currency, amount, customerInfo, summaryInfo, summaryList = [] } = props || {};

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          {txType === 'payouts' ? (
            <>
              <img src={bvnkngLogo} alt="" style={styles.bvnkngLogo} />
              <Image src={bvnkngLogo} style={styles.bvnkngLogo} />
            </>
          ) : (
            <View style={styles.briefcaseContainer}>
              <img src={briefcaseImg} alt="" style={styles.briefcaseImg} />
              <Image src={briefcaseImg} style={styles.briefcaseImg} />
            </View>
          )}
          <Text style={styles.narration}>{customerInfo}</Text>
          <Text style={styles.amount}>
            {currency} {amount}
          </Text>
          <Text style={styles.summaryInfo}>{summaryInfo}</Text>
        </View>
        <View style={styles.summarySec}>
          <Text style={styles.sectionTitle}>Transaction Summary</Text>
          {summaryList.map(item => (
            <View style={styles.rowItem} key={item.label}>
              <Text style={styles.rowLabel}>{item.label}</Text>
              <Text style={styles.rowValue}>{item.value || '--'}</Text>
            </View>
          ))}

          <View style={styles.watermarkContainer}>
            {/* Image is displayed on the generated pdf but not on the web */}
            <Image src={padlockImg} style={styles.watermarkIcon} />
            <img src={padlockImg} alt="" style={styles.watermarkIcon} />

            <Text style={styles.watermarkText}>
              Powered by <Text style={{ fontWeight: 500 }}>bvnkng</Text>
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default function ReceiptModal({ close, data }: IReceiptModal) {
  const receipt = <ReceiptTemplate {...data} />;

  const [docInstance] = usePDF({ document: receipt });
  const blob = docInstance.blob || '';
  const fileName = `receipt-${data?.id}`;

  return (
    <Modal
      heading="View Receipt"
      close={close}
      firstButtonText="Close"
      firstButtonAction={close}
      secondButtonText="Download"
      secondButtonAction={() => Filesaver.saveAs(blob, fileName)}
      secondButtonActionIsTerminal={false}
      content={receipt}
      noContentPadding
    />
  );
}

const styles = StyleSheet.create({
  page: {
    fontSize: '16px',
    fontFamily: 'Averta PE'
  },
  bvnkngLogo: {
    width: '80px',
    marginBottom: 5
  },
  briefcaseContainer: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    backgroundColor: '#DDE2EC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
    flexDirection: 'row'
  },
  briefcaseImg: {
    height: 20
  },
  header: {
    marginTop: 30,
    padding: 10,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  narration: {
    fontWeight: 500,
    fontSize: '15px',
    color: '#3E4B5B'
  },
  amount: {
    fontWeight: 700,
    fontSize: 30,
    textAlign: 'center',
    display: 'block',
    marginTop: 4,
    marginBottom: 4,
    color: '#3E4B5B',
    letterSpacing: '-0.4px'
  },
  summaryInfo: {
    color: '#94A7B7',
    fontSize: '14px',
    display: 'block',
    maxWidth: '400px',
    margin: 'auto',
    marginBottom: '30px'
  },
  summarySec: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#F9FBFD',
    borderTop: '1px solid #DDE2EC',
    padding: 25,
    paddingBottom: 70,
    position: 'relative'
  },
  sectionTitle: {
    borderBottom: '1px solid #AABDCE',
    display: 'block',
    paddingBottom: 10,
    marginBottom: 5,
    fontWeight: 500
  },
  rowItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: '14px'
  },
  rowLabel: {
    color: '#9FA8B6'
  },
  rowValue: {
    fontWeight: 500
  },
  watermarkContainer: {
    marginTop: 25,
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    right: 0,
    left: 0,
    flexDirection: 'row',
    position: 'absolute',
    bottom: 20
  },
  watermarkIcon: {
    height: '15px',
    marginRight: '5px'
  },
  watermarkText: {
    color: 'grey',
    fontSize: '12px',
    textAlign: 'center',
    display: 'block'
  }
});
