import { useQueryClient } from 'react-query';

import useMerchantAuth from '+hooks/useMerchantAuth';
import useStore from '+store';
import { actions } from '+utils';

import Modal from './Modal';

const SessionExpiredModal = () => {
  const queryClient = useQueryClient();
  const sessionActive = useStore(state => state.sessionActive);
  const { handleLogout } = useMerchantAuth();
  const modalContent = () => (
    <div>
      <button
        className="btn"
        data-dismiss="modal"
        type="button"
        style={{
          padding: '12px 15px',
          width: '100%',
          backgroundColor: '#0370e2',
          borderColor: '#0370e2',
          outline: '#0370e2',
          color: '#fff',
          fontWeight: '600'
        }}
        onClick={() => {
          queryClient.setQueryData('SAVED_LOCATION', window.location.pathname);
          handleLogout('/auth/login');
          actions.track('user session expired');
          delete window.visitedbvnkng;
        }}
      >
        Continue to Sign in
      </button>
    </div>
  );

  return (
    <section className="session-expired-modal__comp">
      {!sessionActive && (
        <Modal
          size="sm"
          heading="Your session has expired."
          description="To keep your account safe, we expire long or idle sessions. Please sign in again to continue."
          content={modalContent()}
          hideCloseButton
          showButtons={false}
          secondButtonAction={null}
        />
      )}
    </section>
  );
};

export default SessionExpiredModal;
