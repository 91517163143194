import './init';

import React from 'react';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { createRoot } from 'react-dom/client';
import Bugsnag from '@bugsnag/js';
import { datadogRum } from '@datadog/browser-rum';

import ErrorPage from '+shared/ErrorPage';
import useStore from '+store';

import packageConfig from '../package.json';
import App from './App';

const appVersion = packageConfig.version;

// datadogRum.init({
//   applicationId: process.env.REACT_APP_DARKHORSE_BASE ?? '',
//   clientToken: process.env.REACT_APP_DARKHORSE_CLIENT ?? '',
//   site: process.env.REACT_APP_DARKHORSE_URL ?? 'us5.datadoghq.com',
//   service: 'merchant-dashboard',
//   env: process.env.REACT_APP_DARKHORSE_AREA ?? 'staging',
//   version: appVersion,
//   sessionSampleRate: 100,
//   sessionReplaySampleRate: 100,
//   trackUserInteractions: true,
//   trackResources: true,
//   trackLongTasks: true,
//   defaultPrivacyLevel: 'mask-user-input'
// });

// datadogRum.startSessionReplayRecording();

// Bugsnag.start({
//   apiKey: process.env.REACT_APP_HERMES_WINGS ?? '',
//   releaseStage: process.env.REACT_APP_ENV,
//   plugins: [new BugsnagPluginReact()],
//   onError(event) {
//     const { authDetails } = useStore.getState() as { authDetails: Record<string, any> };
//     event.addMetadata('application', {
//       name: 'bvnkng Merchant Dashboard'
//     });
//     event.setUser(
//       authDetails?.account?.id,
//       authDetails?.account?.email,
//       `${authDetails?.account?.firstname} ${authDetails?.account?.lastname}`
//     );
//   },
//   enabledBreadcrumbTypes: ['error', 'log', 'navigation'],
//   appVersion,
//   redactedKeys: [/^authorization$/i, 'confirm_password', 'password', 'code']
// });

// const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <App />
  // <ErrorBoundary FallbackComponent={ErrorPage}>
  //   <App />
  // </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
